<div class="card bg-white p-3 border-0">
    <div class="card-doc-title ml-3 row" [ngClass]="{'cover': !allowed_to_update_affaire}">
        <i class="fa-solid fa-circle-info mr-2 fa-lg mt-2"></i>
        {{'languages.affaire.infoAvancee' | translate}}
    </div>
   
    <div class="col-12 mt-3" *ngIf="isSurvey">
        <div class="survey-container">
            <survey [model]="surveyModel"></survey>
        </div>
        <ng-container *ngIf="loading">
            <div class="row mb-3 mt-3">
                <div class="text-center m-auto ">
                    <mat-spinner [diameter]="30"></mat-spinner>
                </div>
            </div>
        </ng-container>
        <!-- <ng-container *ngIf="fieldsspecif.length>0  && !loading">
            <form [formGroup]="form" [ngClass]="{'cover': !allowed_to_update_affaire}">
                <div class="row">
                    <div class="col-12">
                        <formly-form (click)="submitInfoSpecifique()" [model]="modelspecif" [fields]="fieldsspecif" [options]="options" [form]="formSpecifique"></formly-form>
                    </div>
                </div>
            </form>
        </ng-container>
        <ng-container *ngIf="loading">
            <div class="row mb-3 mt-3">
                <div class="text-center m-auto ">
                    <mat-spinner [diameter]="30"></mat-spinner>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="fieldsspecif.length<1  && !loading">
            <p class="text-center mt-2">{{'languages.opportunite.details.Noinformations' | translate}}</p>
        </ng-container> -->

    </div>
<!-- 
    <div class="col-12 mt-3 " *ngIf="!isSurvey">
        <div class="row">
          <div class="col-6 mb-3 d-flex flex-column" *ngFor="let item of infoEligibiliteEtrangers; let i = index">
            <label [for]="'input-' + i" class="form-label text-truncate">{{ item.label }}</label>
            <input
              type="text"
              [id]="'input-' + i"
              [value]="item.value"
              class="form-control custom-input"
              readonly
            />
          </div>
        </div>
      </div> -->

      
      <div class="col-12 mt-3 container" *ngIf="!isSurvey">
        <ng-container *ngIf="loading">
          <div class="row mb-3 mt-3">
              <div class="text-center m-auto ">
                  <mat-spinner [diameter]="30"></mat-spinner>
              </div>
          </div>
      </ng-container>
        <div class="row">
          <div class="col-md-6 col-12 mb-3 d-flex flex-column" *ngFor="let item of infoEligibiliteEtrangers; let i = index">
            <label [for]="'input-' + i" class="form-label text-wrap">{{ item.label }}</label>
      
            <!-- If type is text -->
            <div *ngIf="item.type === 'text'" class="input-container">
              <input
                type="text"
                [id]="'input-' + i"
                [value]="item.value"
                class="form-control custom-input"
                readonly
              />
            </div>
      
            <!-- If type is radio, show both "Oui" and "Non" options -->
            <div *ngIf="item.type === 'radio'" class="d-flex flex-wrap align-items-center">
              <div class="form-check me-3">
                <input
                  type="radio"
                  [id]="'radio-oui-' + i"
                  [checked]="item.value === 1"
                  class="form-check-input"
                  disabled
                />
                <label class="form-check-label" [for]="'radio-oui-' + i">Oui</label>
              </div>
              <div class="form-check">
                <input
                  type="radio"
                  [id]="'radio-non-' + i"
                  [checked]="item.value === 0"
                  class="form-check-input"
                  disabled
                />
                <label class="form-check-label" [for]="'radio-non-' + i">Non</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      
      
</div>
