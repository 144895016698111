<div class="col-12">
  <legend *ngIf="to.label">{{ to.label }}</legend>
  <p *ngIf="to.description">{{ to.description }}</p>

  <div class="alert alert-danger" role="alert" *ngIf="showError && formControl.errors">
    <formly-validation-message [field]="field"></formly-validation-message>
  </div>

  <div style="margin-bottom: 20px" class="d-flex flex-row-reverse">
    <button type="button" class="btn-dt-add" data-title="Enfant" (click)="add()">
      <i class="fa-solid fa-plus fa-lg"></i>
    </button>
  </div>

  <ng-container>
    <mat-accordion>
      <mat-expansion-panel *ngFor="let field of field.fieldGroup;let i = index;">
        <mat-expansion-panel-header>
          <mat-panel-title style="display: block">
            {{i+1}}
            <span style="float: right;" (click)="remove(i)" (keydown.enter)="remove(i)" >
              <img src="assets/icons/trash-icon-red.svg" alt="trashIcon">
            </span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <formly-field class="col-10 sub-object" [field]="field"></formly-field>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-container>


</div>