<form (keyup.enter)="submitFilterEnter($event)">
  <div class="filter-bubble">
    <div class="row">
      <div class="col">
        <div class="filter-bubble-icon" (keydown.enter)="toggleFilterCorp()" (click)="toggleFilterCorp()">
          <i class="fa-light fa-filter fa-lg mt-2" *ngIf="collapseFilterBox"></i>
          <i class="fa-light fa-xmark fa-lg mt-2" *ngIf="!collapseFilterBox"></i>        </div>
      </div>
    </div>
  </div>
  <div class="filter-corp" [ngClass]="{'collapse_filter_corp':collapseFilterBox}">
    <div class="row m-0">

      <button type="button" class="filtrerBtnHeader mb-2 w-100">
        <!-- <img (click)="toggleFilterCorp()" class="fa fa-lg float-left ml-2"
          src="assets/icons/filter-circle-xmark-solid.svg" />&nbsp; -->
        {{'languages.buttons.filterSearch' | translate}} 
      </button>
    </div>
   
    <div class="ml-1">

      <div class="row">
        <div class="col-6 align-self-center">
          <div class="row">
            <button type="button" class="btn-load" type="button" (click)="resetForm()">
               <i class="fa-solid fa-rotate-right fa-lg"></i>

            </button>
             <app-list-filter-saved [entity]="'O'" (filterOpportunite)="getFilterFromCollection($event)" (reloadParentEvent)="closefilter($event)"></app-list-filter-saved>
          
          </div>
        </div>
        <div class="col-6">
          <div class="row align-self-center float-right">


            <app-save-filtre [filterOpportunite]="filterOpportunite" [entity]="'O'"></app-save-filtre>
            <button type="submit" class="ml-2 btn-dt-save-Filtre" (click)="submitFilter()">
              <i class="fa-regular fa-magnifying-glass fa-lg"></i>
              {{'languages.buttons.startseach' | translate}}
            </button>
          </div>
        </div>
      </div>
      <hr>


      <div class="scrolling" id="style-1">
        <div class="row mb-2">
          <div class="col-12">
            <mat-accordion>
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="row w-100 d-flex justify-content-center">
                      <div class="row filtre-spn">
<i class="fa-solid fa-bullhorn fa-lg mr-2 custem-margin"></i>
                        <span>  {{"languages.opportunite.opportunites" | translate}}</span>
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row mt-3">
                  <app-filtres-opportunites #FiltresOpportunitesComponent class="w-100" (returnToparent)="getResetOpp($event)" [Reset]="onResetOpp"
                    (onPushFilterOpportunite)="EmitFilterOpportunite($event)"
                    [onRefreshFilterOpportunite]="filterOpportunite.filtre_opp">
                  </app-filtres-opportunites>
                </div>
              </mat-expansion-panel>

            </mat-accordion>
          </div>
        </div>



        <div class="row mb-2">
          <div class="col-12">
            <mat-accordion>
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="row w-100 d-flex justify-content-center">
                      <div class="row filtre-spn">
<i class="fa-solid fa-user-tie fa-lg mr-2 custem-margin"></i>
                        <span>  {{"languages.opportunite.prospects" | translate}}</span>
                      </div>
                    </div>

                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row mt-3">
                  <app-filtres-prospects #FiltresProspectsComponent class="w-100" (returnToparent)="getResetPros($event)" [Reset]="onResetPros"
                    (onPushFilterProspec)="EmitFilterProspec($event)"
                    [onRefreshFilterProspec]="filterOpportunite.prospect"></app-filtres-prospects>
                </div>
              </mat-expansion-panel>

            </mat-accordion>
          </div>
        </div>



        <div class="row mb-2">
          <div class="col-12">
            <mat-accordion>
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="row w-100 d-flex justify-content-center">
                      <div class="row filtre-spn">
<i class="fa-solid fa-user fa-lg mr-2 custem-margin"></i>
                        <span>{{"languages.opportunite.utilisateurs" | translate}}</span>
                      </div>

                    </div>


                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row mt-3">
                  <app-filtres-utilisateurs #FiltresUtilisateursComponent class="w-100" (returnToparent)="getResetUtil($event)" [Reset]="onResetUtil"
                    (onPushFilterUtilisateur)="EmitFilterUtilisateur($event)">
                  </app-filtres-utilisateurs>
                </div>
              </mat-expansion-panel>

            </mat-accordion>
          </div>
        </div>


        <!-- <div class="row mb-2">
          <div class="col-12">
            <mat-accordion>
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="row w-100 d-flex justify-content-center" >
                      <div class="row filtre-spn" >
                            <fa-icon class="fa-lg mr-2" [icon]="fa['faFileContract']"></fa-icon>
                            <span> {{"languages.opportunite.ancienContrat" | translate}}</span>
                      </div>
                    </div>

                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row mt-3" >
                  <app-filtres-info-ancien-contrat class="w-100" (returnToparent)="getResetAncienContrat($event)"
                    [Reset]="onResetAncienContrat" (onPushFilterAncienContart)="EmitFilterAncienContart($event)">
                  </app-filtres-info-ancien-contrat>
                </div>
              </mat-expansion-panel>

            </mat-accordion>
          </div>
        </div> -->


        <!-- <div class="row mb-2" style="display:none ;">
          <div class="col-12">
            <mat-accordion>
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="row w-100 d-flex justify-content-center" >
                      <div class="row filtre-spn" >
                            <fa-icon class="fa-lg mr-2" [icon]="fa['faPhone']"></fa-icon>
                            <span>Téléphonie</span>
                      </div>
                    </div>

                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row mt-3" >
                  <app-filtres-telephonie class="w-100" (returnToparent)="getResetTelephonie($event)"
                    [Reset]="onResetTelephonie" (onPushFilterTelephonie)="EmitFilterTelephonie($event)">
                  </app-filtres-telephonie>
                </div>
              </mat-expansion-panel>

            </mat-accordion>
          </div>
        </div> -->


        <div class="row mb-md-2">
          <div class="col-12">
            <mat-accordion>
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="row w-100 d-flex justify-content-center">
                      <div class="row filtre-spn">
<i class="fa-solid fa-lightbulb fa-lg mr-2 custem-margin"></i>
                        <span>{{"languages.opportunite.statusActuel" | translate}}</span>
                      </div>
                    </div>

                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row mt-3">
                  <app-filtres-status-actuel #FiltresStatusActuelComponent (returnToparent)="getResetStatut($event)" [Reset]="onResetStatut"
                    (onPushFilterStatusActuel)="EmitFilterStatusActuel($event)">
                  </app-filtres-status-actuel>
                </div>
              </mat-expansion-panel>

            </mat-accordion>
          </div>
        </div>


      </div>

    </div>


  </div>
</form>
