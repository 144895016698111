import { ApiUsersService } from 'src/app/services/ApiUsers/api-users.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from './../../../../../shared/NotificationsService/notifications.service';
import { RendezVousCalendar } from './../../../../../entity/RendezVousCalendar';
import frLocale from '@fullcalendar/core/locales/fr';
import esLocale from '@fullcalendar/core/locales/es';

import { CalendarOptions } from '@fullcalendar/core';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  AgendaService,
  DayService,
  EventSettingsModel,
  MonthService,
  WeekService,
  WorkWeekService,
} from '@syncfusion/ej2-angular-schedule';
import { ListTypeRendezVous } from '../../../../../entity/Opportunity/ListTypeRendezVous';
import { ApiOpportunitService } from '../../../../../services/ApiOpportunite/api-opportunite.service';
import { DatePipe } from '@angular/common';
import { SharedMenuObserverService } from '../../../../../services/SharedMenuObserver/shared-menu-observer.service';
import { ApiRdvService } from '../../../../../services/ApiRdv/api-rdv.service';
import { Store } from '@ngrx/store';
import { DetailsOpportuniteStore } from '../../store/details-opportunite.reducers';
import * as selectors from '../../store';
import * as actions from '../../store/details-opportunite.actions';
import { Observable, Subscription } from 'rxjs';
import { GetOpportuniteDetails } from '../../../../../entity/Opportunity/GetOpportuniteDetails';
import { FormControl, Validators } from '@angular/forms';
import { map, take } from 'rxjs/operators';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { ThemePalette } from '@angular/material/core';
import * as moment from 'moment';
import { UserList } from 'src/app/entity/User/UserList';
import swal from 'sweetalert2';
import { element } from 'protractor';
import { OpportunitiesDetailsComponent } from '../../opportunities-details.component';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-planification',
  templateUrl: './planification.component.html',
  styleUrls: ['./planification.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [DayService, WeekService, WorkWeekService, MonthService, AgendaService],
})
export class PlanificationComponent implements OnInit, OnDestroy {
   
  loading: boolean = true;
  updateListRdv: boolean = true;
  RendezVous: any[] = [];
  RendezVousFullInfo: any[] = [];
  listAffect: UserList[] = [];
  listTypeRendezVous: ListTypeRendezVous[] = [];
  listeOfUnsubscribeWebService: Subscription[] = [];
  opportuniteDetails$: Observable<GetOpportuniteDetails> | null = null;
  type_rdv: any = 4;
  affecterAFormControl: FormControl = new FormControl(null, [Validators.required]);
  listCommerciauxFilter: Observable<UserList[]> = null;

  startRdv: any | null = null;
  endRdv: any | null = null;
  firstOpenCalendar: boolean = true;

  selected_rdv: RendezVousCalendar = null;
  affecte: string = '';

  @ViewChild('planification') calendarComponent: FullCalendarComponent;
  @ViewChild('OppUpdateRdvModal') OppUpdateRdvModal: ElementRef;
  @ViewChild('OppDetailsRdvModal') OppDetailsRdvModal: ElementRef;
  allowed_to_update: any;
  buttons: any;
  alerts: any;
  currentLanguage: string;
  navbarTxt: string = '';
  constructor(
    private readonly sharedMenuObserverService: SharedMenuObserverService,
    public NotificationsService: NotificationsService,
    private readonly modalService: NgbModal,
    private apiOpportuniteService: ApiOpportunitService,
    private apiUsersService: ApiUsersService,
    private RdvService: ApiRdvService,
    public datepipe: DatePipe,
    private store$: Store<DetailsOpportuniteStore>,
    private opportunitiesDetails: OpportunitiesDetailsComponent,
    private readonly apiOpportunitService: ApiOpportunitService,
    private translate: TranslateService
  ) {
    this.currentLanguage = this.translate.currentLang;
  }

  ngAfterViewInit(): void {
    this.apiOpportunitService.onChangeAccesOpportunite().subscribe((value: boolean) => {
      if (value) this.allowed_to_update = value;
    });
  }

  ngOnDestroy(): void {
    this.listeOfUnsubscribeWebService.forEach((element) => {
      element?.unsubscribe();
    });
  }

  private opportunite_id: string = null;
  private dateDebut: any = null;
  private dateEnd: any = null;

  public eventSettings: EventSettingsModel = {
    fields: {
      id: 'Id',
      subject: { name: 'EventName' },
      isAllDay: { name: 'IsAllDay' },
      startTime: { name: 'StartTime' },
      endTime: { name: 'EndTime' },
    },
  };

  calendarOptions: CalendarOptions = {
    initialView: 'timeGridWeek',
    timeZone: 'local',
    locale: 'fr',
    locales: [frLocale],
    dateClick: this.handleDateClick.bind(this),
    eventClick: this.handleEventClick.bind(this),
    select: this.handleDateSelect.bind(this),
    datesSet: this.visibleRangeHandler.bind(this),
    eventResize: this.handleEventUpdate.bind(this),
    eventDrop: this.handleEventUpdate.bind(this),
    customButtons: {
      dateRdv: {
        text: 'Date du rendez-vous',
        click: this.gotToRdvDateHandler.bind(this),
      },
    },
    events: this.RendezVous,
    headerToolbar: {
      center: 'dayGridMonth timeGridWeek',
      end: 'dateRdv today prevYear,prev,next,nextYear', // will normally be on the right. if RTL, will be on the left
    },
    nowIndicator: true,
    editable: true,
    selectable: true,
  };

  ngOnInit(): void {
    this.allowed_to_update = this.opportunitiesDetails.allowed_to_update;

    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.navbarTxt =
          object.navbar.Opportunites + ' ➜ ' + object.navbar.Détails + ' ➜ ' + object.navbar.OppDetailsPlanification;
        this.sharedMenuObserverService.updateMenu(this.navbarTxt);
        this.alerts = object.alerts;
        this.buttons = object.buttons;
        this.currentLanguage = this.translate.currentLang;
        this.changeCalendar();
      });
    });

    this.translate.get('languages').subscribe((object: any) => {
      this.navbarTxt =
        object.navbar.Opportunites + ' ➜ ' + object.navbar.Détails + ' ➜ ' + object.navbar.OppDetailsPlanification;
      this.sharedMenuObserverService.updateMenu(this.navbarTxt);
      this.alerts = object.alerts;
      this.buttons = object.buttons;
      this.changeCalendar();
    });

    this.loading = true;
    this.store$.select(selectors.getOpportuniteTypeRdv).subscribe((data) => {
      if (data !== undefined && data !== null) {
        this.type_rdv = data.type_opportunite;
        if (data.startRdv !== null) this.startRdv = this.formatDateToLocal(data.startRdv);
        if (data.endRdv !== null) this.endRdv = this.formatDateToLocal(data.endRdv);
        this.dateDebutControl.setValue(this.startRdv);
        this.dateFinControl.setValue(this.endRdv);
        this.affecte = data.affecte;
        this.RechargerListeRdv();
      }
    });
    this.store$.select(selectors.getOpportuniteId).subscribe((data) => {
      if (data !== undefined && data !== null) {
        this.opportunite_id = data;
      }
    });

    this.listCommerciauxFilter = this.affecterAFormControl.valueChanges.pipe(
      map((value) => (typeof value === 'string' ? value : value.nom + ' ' + value.prenom)),
      map((value) => {
        return this._affecterAfilter(value);
      })
    );

    this.getTypeRendezvous();
    this.listeAffect();
  }

  changeCalendar() {
    this.calendarOptions.customButtons.dateRdv.text = this.buttons.meetingDate;
    if (this.currentLanguage === 'en') {
      this.calendarOptions.locale = this.currentLanguage;
      this.calendarOptions.locales = [esLocale];
    } else {
      this.calendarOptions.locale = this.currentLanguage;
      this.calendarOptions.locales = [frLocale];
    }
  }

  formatDateToLocal(DBdate: any) {
    return moment(DBdate).format('YYYY-MM-DD HH:mm:SS.SSS').toString();
  }

  gotToRdvDateHandler(event) {
    if (this.startRdv) {
      this.calendarComponent.getApi().gotoDate(new Date(this.startRdv));
    } else {
      this.calendarComponent.getApi().gotoDate(new Date());
    }
  }

  visibleRangeHandler(data) {
    const startDateList = data.startStr.split('T');
    const endDateList = data.endStr.split('T');
    if (startDateList[0] !== this.dateDebut && endDateList[0] !== this.dateEnd) {
      this.dateDebut = startDateList[0];
      this.dateEnd = endDateList[0];
      this.RechargerListeRdv();
    }
  }

  handleEventClick(info) {
    info.jsEvent.preventDefault();
    if (info && info.event.id) {
      this.selected_rdv = this.RendezVous.filter((elem) => elem.id === info.event.id).pop();
      if (!this.selected_rdv) {
        return;
      }
      if (this.opportunite_id === this.selected_rdv.id) {
        this.dateDebutControl.setValue(info.event.start);
        this.dateFinControl.setValue(info.event.end);
        this.modalService.open(this.OppUpdateRdvModal, { ariaLabelledBy: 'modal-basic-title' });
      } else {
        window.open(info.event.url, '_blank');
      }
    }
  }

  handleEventUpdate(event) {
    this.dateDebutControl.setValue(event.event.start);
    this.dateFinControl.setValue(event.event.end);
    const dateDebut: Date = new Date(this.dateDebutControl.value);
    const dateFin: Date = new Date(this.dateFinControl.value);
    this.store$.dispatch(
      actions.UpdateDateRdv({
        payload: {
          start: dateDebut.toISOString(),
          end: dateFin.toISOString(),
          id: this.opportunite_id,
          type: this.type_rdv.toString(),
        },
      })
    );
  }

  handleDateClick(arg) {
    if (this.startRdv) {
      if (this.calendarComponent.getApi().view.type === 'dayGridMonth') {
        this.calendarComponent.getApi().changeView('timeGridWeek', arg?.date?.toISOString());
      }
      // if (new Date(arg.date) <= new Date()) {
      //   let notificationMessage = new NotificationMessage();
      //   notificationMessage.type = NotificationType.warning;
      //   notificationMessage.message = 'Rendez-vous doit être au future';
      //   this.NotificationsService.sendMessage(notificationMessage);
      // }
      else {
        this.dateDebutControl.setValue(arg.dateStr);
        this.modalService.open(this.OppUpdateRdvModal, { ariaLabelledBy: 'modal-basic-title' });
      }
    } else {
      this.dateDebutControl.setValue(arg.dateStr);
      this.modalService.open(this.OppUpdateRdvModal, { ariaLabelledBy: 'modal-basic-title' });
    }
  }

  handleDateSelect(event) {
    if (!this.startRdv || !this.endRdv) {
      this.dateDebutControl.setValue(event.start);
      this.dateFinControl.setValue(event.end);
      this.modalService.open(this.OppUpdateRdvModal, { ariaLabelledBy: 'modal-basic-title' });
    }
  }

  RechargerListeRdv() {
    if (this.type_rdv && this.type_rdv === 4) {
      return;
    }
    this.updateListRdv = false;
    if (this.affecte && this.dateDebut !== null && this.dateEnd !== null) {
      const unsubscribeGetListeRdv = this.RdvService.getListeRdv(this.affecte, this.dateDebut, this.dateEnd)
        .pipe(take(1))
        .subscribe((Response: RendezVousCalendar[]) => {
          this.RendezVousFullInfo = Response;
          this.buildCalendarRdv(Response);
        });
      this.listeOfUnsubscribeWebService.push(unsubscribeGetListeRdv);
    }
  }

  parse(value: string): any {
    const parts = value.split(',');
    const dateparts = parts[0].split('/');
    const timeparts = parts[1].split(':');
    const date = new Date(+dateparts[2], +dateparts[1] - 1, +dateparts[0], +timeparts[0], +timeparts[1], +timeparts[2]);

    return date;
  }

  changerdatedebut(event) {
    const date = this.parse(event.targetElement.value);
    this.dateDebutControl.setValue(date);
  }

  changerdatefin(event) {
    const date = this.parse(event.targetElement.value);
    this.dateFinControl.setValue(date);
  }

  errorAlert(data) {
    swal.fire({
      title: data,
      text: this.alerts.failedOperation,
      icon: 'error',
      showConfirmButton: true,
    });
  }

  updateRdvDetails() {
    const dateDebut: Date = new Date(this.dateDebutControl.value);
    const dateFin: Date = new Date(this.dateFinControl.value);
    if (dateDebut === null || dateFin === null) {
      this.errorAlert(this.alerts.wrongDate);
      return;
    }
    if (dateFin < dateDebut) {
      this.errorAlert(this.alerts.dateSupp);
      return;
    }

    this.store$.dispatch(
      actions.UpdateDateRdv({
        payload: {
          start: dateDebut.toISOString(),
          end: dateFin.toISOString(),
          id: this.opportunite_id,
          type: this.type_rdv.toString(),
        },
      })
    );
    this.modalService.dismissAll();
  }

  private buildCalendarRdv(rendezVousCalendar: RendezVousCalendar[]) {
    this.RendezVous = [];
    rendezVousCalendar?.forEach((element) => {
      if (element.id_opp_md5 !== this.opportunite_id) {
        const rdv = new RendezVousCalendar();
        rdv.title = element.title;
        rdv.id = element.id_opp_md5;
        rdv.backgroundColor = '#8ad7ff';
        rdv.url = './opportunities/details/' + element.id_opp_md5;
        rdv.start = element.start;
        rdv.end = element.end;
        this.RendezVous.push(rdv);
      }
    });
    if (!this.startRdv || !this.endRdv) {
      const rdv = new RendezVousCalendar();
      rdv.title = 'Opportunité courante';
      rdv.id = this.opportunite_id;
      rdv.url = './opportunities/details/' + this.opportunite_id;
      rdv.backgroundColor = '#b3ffb4';
      rdv.editable = true;
      rdv.startEditable = true;
      rdv.durationEditable = true;
      rdv.start = this.startRdv;
      rdv.end = this.endRdv;
      this.RendezVous.push(rdv);
    }
    this.updateListRdv = true;
    this.calendarComponent.getApi().setOption('events', this.RendezVous);
    this.calendarComponent.getApi().setOption('visibleRange', {
      start: this.dateDebut,
      end: this.dateEnd,
    });
    if (this.firstOpenCalendar) {
      this.gotToRdvDateHandler(null);
      this.firstOpenCalendar = false;
    }
  }

  private _affecterAfilter(value: string): UserList[] {
    const filterValue = value.toLowerCase();
    return this.listAffect.filter(
      (option) => (option.nom + ' ' + option.prenom).toLowerCase().indexOf(filterValue) > -1
    );
  }

  listeAffect() {
    const unsubscribeGetAllUsers = this.apiUsersService.getAllUsers().subscribe(
      (data) => {
        this.listAffect = data;
        const affected_to_user: UserList = this.listAffect.filter((elem) => elem.id === this.affecte).pop();
        if (affected_to_user !== undefined)
          this.affecterAFormControl.setValue(affected_to_user.nom + ' ' + affected_to_user.prenom);
        this.loading = false;
      },
      (err) => {
        console.error(err);
      }
    );
    this.listeOfUnsubscribeWebService.push(unsubscribeGetAllUsers);
  }

  getTypeRendezvous() {
    const unsubscribeGetListRDV = this.apiOpportuniteService.getListRDV().subscribe((data: ListTypeRendezVous[]) => {
      this.listTypeRendezVous = data;
    });
    this.listeOfUnsubscribeWebService.push(unsubscribeGetListRDV);
  }

  onChangeTypeOpp() {
    if (this.type_rdv === 4) {
      this.firstOpenCalendar = true;
    }
    this.store$.dispatch(actions.UpdateTypeRdv({ payload: { type_rdv_id: this.type_rdv } }));
  }

  changeAffectedTo() {
    this.store$.dispatch(actions.UpdateAffectedTo({ payload: { commerciaux_id: this.affecte } }));
  }

  selectCommercieaux(selectedCommercial) {
    this.affecte = selectedCommercial.id;
    this.changeAffectedTo();
    this.affecterAFormControl.setValue(selectedCommercial.nom + ' ' + selectedCommercial.prenom);
    this.RechargerListeRdv();
  }

  /*
  DateTime Pciker
  */

  public date: moment.Moment;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  public dateDebutControl = new FormControl();
  public dateFinControl = new FormControl();

  /* mat-calendar */
  selectedDateMatCalendar: any;

  onSelect(event) {
    this.calendarComponent.getApi().gotoDate(event.toISOString());
  }
}
