import { AfterViewInit, ChangeDetectorRef, Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
 
import * as $ from 'jquery';
import { Subject, Subscription } from 'rxjs';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import { ApiGarantieService } from 'src/app/services/ApiGarantie/api-garantie.service';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-list-garanties',
  templateUrl: './list-garanties.component.html',
  styleUrls: ['./list-garanties.component.css'],
})
export class ListGarantiesComponent implements OnInit, AfterViewInit ,OnDestroy {
  garantie: []=[];
   
  id: string = '';
  dataTable: any;
  unsubscribeGetListGarantie: Subscription;
  miniListing: any;
  currentLanguage: string;
  limit = 15;
  page = 1;
  totalPages = 0;
  totalLength: any;
  intervale = 1;
  search: string="";
  inputSubject = new Subject<string>();
  constructor(
    private routes: Router,
    private apiGarantieService: ApiGarantieService,
    private readonly sharedMenuObserverService: SharedMenuObserverService,
    private chRef: ChangeDetectorRef,
    private translate: TranslateService
  ) {
    this.inputSubject.pipe(
      debounceTime(1000) // Adjust debounce time as needed
    ).subscribe((inputValue: string) => {
      this.filter(inputValue);
    });
  }

  onInputChange(value: string): void {
    this.inputSubject.next(value);
  }

  ngAfterViewInit(): void {
        this.chRef.detectChanges();
  }

  ngOnInit(): void {
     this.showloader();
     this.translate.get('languages').subscribe((object: any) => {
      this.sharedMenuObserverService.updateMenu(object.navbar.gestionGarantie);
      this.miniListing = object.miniListing;
    });
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.sharedMenuObserverService.updateMenu(object.navbar.gestionGarantie);
        this.miniListing = object.miniListing;
      
      });
    });

    this.listGarantie();
  }

  listGarantie() {
    this.showloader();
    if(localStorage.getItem("page")) this.page = parseInt (localStorage.getItem("page"));
    if(localStorage.getItem("search")) this.search = localStorage.getItem("search");
    this.unsubscribeGetListGarantie = this.apiGarantieService.getListGarantieV1(this.limit, this.page,this.search).subscribe((data: any) => {
      this.garantie = data.Data;
      this.totalLength = data.totalCount;
      this.calculate_nb_pages(this.totalLength);
      this.hideloader();
      this.chRef.detectChanges();
      

  });
}

  ngOnDestroy(): void {
    const currentRoute = this.routes.url;
   if (!currentRoute.includes('/add-garantie')) {
     localStorage.removeItem('search');
     localStorage.removeItem('page');
   }
 }

  goTofiche() {
    this.routes.navigate(['/add-garantie/nouveau']);
  }

  goToDetails(item: any) {
    this.routes.navigate(['/add-garantie/' + item.code]);
  }



  expandFileName(name: string): string {
    if (name.length < 12) {
      return name;
    } else {
      return name.substring(0, 11) + '...';
    }
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }


  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    localStorage.removeItem('search');
    localStorage.removeItem('page');
  }
  
  calculate_nb_pages(iTotalDisplayRecords) {
    this.totalPages = Math.floor(iTotalDisplayRecords / this.limit);
    if (iTotalDisplayRecords % this.limit !== 0) {
      this.totalPages += 1;
    }
  }

  first() {
    this.setPage(1);

  }

  prev() {
    this.setPage(Math.max(1, this.page - 1));
  }

  next() {
    this.setPage(Math.min(this.totalPages, this.page + 1));
  }

  last() {
    this.setPage(this.totalPages);
  }

  setPage(val: number) {
    this.page = val;
    localStorage.setItem('page',this.page.toString())
    this.listGarantie();
  }

  isFirst(): boolean {
    return this.page === 1;
  }

  isLast(): boolean {
    return this.page === this.totalPages;
  }

  changepage(number: number) {
    this.page = number; 
    localStorage.setItem('page',this.page.toString())
    this.listGarantie();
  }
  
  getchangeLimite() {
    if (this.intervale <= this.totalPages) {
      this.page = this.intervale;
      if (this.page <= this.totalPages) {
        this.listGarantie();
      }
    }
  }

  updateListGaranties() {
    this.page = 1;
    this.listGarantie();
  }

  filter(event) {
    this.page = 1;

      localStorage.setItem('search',this.search);
      localStorage.setItem('page',this.page.toString())
      this.listGarantie();
      
    }
  



}
