import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ControlMenuService {

  private menuItemsState = new BehaviorSubject<boolean>(true);
  menuItemsState$ = this.menuItemsState.asObservable();

  

  resetMenuActiveState(): void {
  
    this.menuItemsState.next(true); // Emit a new object reference
  }
}
