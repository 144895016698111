import { ContartActuelOpportunity } from './../../../../entity/Opportunity/ContratActuelOpportunity';
import { Injectable } from '@angular/core';
import { ApiProspectsService } from '../../../../services/ApiProspects/api-prospects.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, takeWhile, tap } from 'rxjs/operators';
import { CampagneInfo } from '../../../../entity/Opportunity/CampagneInfo';
import * as actions from './details-opportunite.actions';
import { Entreprise } from '../../../../entity/Opportunity/Entreprise';
import { GetOpportuniteDetails } from '../../../../entity/Opportunity/GetOpportuniteDetails';
import { ApiOpportunitService } from '../../../../services/ApiOpportunite/api-opportunite.service';
import { InformationsComplmentaire } from '../../../../entity/Opportunity/InformationsComplmentaire';
import { InformationsSpecifique } from '../../../../entity/Opportunity/InformationsSpecifique';
import { FormContract } from 'src/app/entity/Opportunity/FormContract';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class DetailsOpportuniteEffects {

  constructor(
    private actions$: Actions,
    private readonly apiProspectsService: ApiProspectsService,
    private apiOpportunitService: ApiOpportunitService
  ) {
  }

  getEntreprise: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GetProspect),
      mergeMap(
        ({ payload }) =>
          this.apiProspectsService.getProspectByOpprtuniteId(payload.opportuniteId).pipe(
            map((data: Entreprise) => actions.GetProspectSuccess({ payload: { entreprise: data } })),
            catchError((err) => of(actions.GetProspectFail({ payload: { errorMsg: err.message } })))
          )
      )
    )
  );

  getContratActuel: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GetConstractActuel),
      mergeMap(
        ({ payload }) =>
          this.apiProspectsService.GetContratActuel(payload.opportuniteId).pipe(
            map((data: FormContract) => actions.GetConstractActuelSuccess({ payload: { contratActuel: data } })),
            catchError((err) => of(actions.GetConstractActuelFail({ payload: { errorMsg: err.message } })))
          )
      )
    )
  );

  getInfoSpecifique: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GetInfoSpecifique),
      mergeMap(
        ({ payload }) =>
          this.apiOpportunitService.getInfoSpecifiqueOpportuniteGet(payload.opportuniteId).pipe(
            map((data: any) => actions.GetInfoSpecifqueSuccess({ payload: { informationSpecifiques: data } })),
            catchError((err) => of(actions.GetInfoSpecifqueFail({ payload: { errorMsg: err.message } })))
          )
      )
    )
  );

  getInfoComplementaire: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GetInfoComplementaire),
      mergeMap(
        ({ payload }) =>
          this.apiOpportunitService.getInfoComplementairesOpportuniteGet(payload.opportuniteId).pipe(
            map((data: any) => actions.GetInfoComplementaireSuccess({ payload: { informationComplementaires: data } })),
            catchError((err) => of(actions.GetInfoComplementaireFail({ payload: { errorMsg: err.message } })))
          )
      )
    )
  );

  saveDetailsProspect: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.SaveDetails),
      mergeMap(
        ({ payload }) => {
          if (payload?.entreprise?.prospectUpdated) {
            return this.apiProspectsService.UpdateEntreprise(payload.affaire_id, payload.entreprise.prospectUpdate, payload.opportunite_id.toString()).pipe(
              map((data: Entreprise) => actions.UpdateProspectServiceSuccess({ payload: data })),
              catchError((err: HttpErrorResponse) =>{
                if(err?.status === 409)
                return of(actions.UpdateProspectServiceFail({ payload: { errorMsg: err?.error?.message } }))
                else
                return of(actions.UpdateProspectServiceFail({ payload: { errorMsg: '' } }))
              })
            );
          } else {
            return of(actions.NoopAction({ payload }));
          }
        }
      )
    )
  );

  saveDetailsContrat: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.SaveDetails),
      mergeMap(
        ({ payload }) => {

          if (payload.constractActuelUpdated) {
            return this.apiOpportunitService.updateAncienContrat(payload.constractActuel).pipe(
              map((data: any) => actions.UpdateConstractActuelSuccess()),
              catchError((err) => of(actions.UpdateConstractActuelFail({ payload: { errorMsg: err.message } })))
            );
          } else {
            return of(actions.NoopAction({ payload }));
          }

        }
      )
    )
  );

  saveDetailsSpecifique: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.SaveDetails),
      mergeMap(
        ({ payload }) => {
          if (payload.informationSpecifiquesUpdated) {
          const informationSpecifiques = new InformationsSpecifique();
            informationSpecifiques.id_opp = payload.opportunite_id;
            informationSpecifiques.inf_prod = [];
            informationSpecifiques.json_prod = payload.informationSpecifiques.informationSpecifiques.form.data;
            return this.apiOpportunitService.updateInformationsSpecifique(payload.affaire_id,informationSpecifiques).pipe(
              map((data: any) => actions.UpdateInfoSpecifqueSuccess()),
              catchError((err) => of(actions.UpdateInfoSpecifqueFail({ payload: { errorMsg: err.message } })))
            );
          } else {
            return of(actions.NoopAction({ payload }));
          }

        }
      )
    )
  );

  saveDetailsComplementaire: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.SaveDetails),
      mergeMap(
        ({ payload }) => {
          if (payload.informationComplementairesUpdate) {
            const infocomplementaire = new InformationsComplmentaire();
            infocomplementaire.id_opp = payload.opportunite_id;
            infocomplementaire.infcamp = [];
            infocomplementaire.jsonform = payload.informationComplementaires.informationComplementaires.form.data;

            return this.apiOpportunitService.updateInformationsComplementaires(payload.affaire_id,infocomplementaire).pipe(
              map((data: any) => actions.UpdateInfoComplementaireSuccess()),
              catchError((err) => of(actions.UpdateInfoComplementaireFail({ payload: { errorMsg: err.message } })))
            );
          } else {
            return of(actions.NoopAction({ payload }));
          }

        }
      )
    )
  );

  saveDetailsPlanification: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.SaveDetails),
      mergeMap(
        ({ payload }) => {
          if (payload.detailsUpdated) {
            return this.apiOpportunitService.updateRendezVous(payload.details.id, payload.details.opportunite_details).pipe(
              map((data: Entreprise) => actions.UpdateDateRdvSuccess()),
              catchError((err) => of(actions.UpdateDateRdvFail()))
            );
          } else {
            return of(actions.NoopAction({ payload }));
          }

        }
      )
    )
  );

  saveDetailsobjetassurer: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.SaveDetails),
      mergeMap(
        ({ payload }) => {
          if (payload.detailsUpdated) {
            const nouvelleListe = payload.listObjetAssurer.map((element) => {
              return {
                [element.nom]: { index: element.index,data:element.form.data},

              };
            });
               return this.apiOpportunitService.update_objet_assure_by_entity(payload.details.id, 'opportunite', nouvelleListe).pipe(
              map((data: Entreprise) => actions.UpdateOBJETAssurerSuccess()),
              catchError((err) => of(actions.UpdateOBJETAssurerFail()))
            );
          } else {
            return of(actions.NoopAction({ payload }));
          }

        }
      )
    )
  );

  getDetails: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GetDetails),
      mergeMap(
        ({ payload }) => {
          return this.apiOpportunitService.GetOpportunite(payload.opportuniteId).pipe(
            map((data: GetOpportuniteDetails) => actions.GetDetailsSuccess({ payload: data })),
            catchError((err) => of(actions.GetDetailsFail({ payload: { errorMsg: err.message } })))
          );
        }
      )
    )
  );

}
