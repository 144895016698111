import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiOpportunitService } from 'src/app/services/ApiOpportunite/api-opportunite.service';
import {Store} from '@ngrx/store';
import { DetailsOpportuniteStore } from '../../store/details-opportunite.reducers';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent {


  infocomplementaire$: Observable<any> | null = null;
  constructor(  private apiOpportunitService: ApiOpportunitService) { }


}
