import {createReducer, on} from '@ngrx/store';
import {OpportunityAdd} from '../../../../entity/Opportunity/OpportunityAdd';
import * as addOppActions from './add-opportunite.actions';
import {ListProspect, Prospect} from '../../../../entity/Prospect';
import {Entreprise} from '../../../../entity/Opportunity/Entreprise';
import {Campagne} from '../../../../entity/Opportunity/Campagne';
import {Details} from '../../../../entity/Opportunity/Details';
import {Calendar} from '../../../../entity/Opportunity/Calendar';
import {CycleVie} from '../../../../entity/CycleVie';
import {LigneProduit} from '../../../../entity/Opportunity/LigneProduit';
import {CampagneInfo} from '../../../../entity/Opportunity/CampagneInfo';
import {Process} from '../../../../entity/Process';
import {Commerciaux} from '../../../../entity/Opportunity/Commerciaux';
import {SelectProduct} from './add-opportunite.actions';
import { UserLogin } from 'src/app/login/login.component';

export enum addOpportuniteTypeProspectEnum {
  ENTREPRISE = 'entrepise',
  PARTICULIER = 'particulier'
}

export enum addOpportuniteStateEnum {
  INIT_STATE = 'INIT STATE',
  LOADING_STATE = 'LOADING STATE',
  LOADED_STATE = 'LOADED STATE',
  ERROR_STATE = 'ERROR STATE',
  INSERT_STATE_SUCCESS = 'INSERT STATE SUCCESS'
}


export interface addOpportuniteStore {
  addOpportunite: AddOpportuniteState
}

export interface AddOpportuniteState {
  prospectParticulier: ListProspect,
  prospectPro: ListProspect,
  entreprise: Entreprise,
  typeProspect:
    addOpportuniteTypeProspectEnum,
  loadState: addOpportuniteStateEnum,
  errorMessage: any,
  campagne: Campagne,
  details: Details,
  calendar: Calendar,
  cycleVie: CycleVie,
  cycleVieComp: any[],
  cycleVieHorsComp: any[],
  listLignesProduits: LigneProduit[],
  infoComplementaireSchema: CampagneInfo,
  infoSpecifiquesSchema: any,
  listStatusCampagne: any,
  id_opportunite: string,
  listOfProcess: Process[],
  listCommerciaux: Commerciaux[],
  index_old_prospect: string,
}

export const initState: AddOpportuniteState = {
  loadState: addOpportuniteStateEnum.INIT_STATE,
  errorMessage: null,
  id_opportunite: null,
  typeProspect: null,
  entreprise: new Entreprise(),
  prospectParticulier: new ListProspect(),
  prospectPro: new ListProspect(),
  cycleVie: new CycleVie(),
  campagne: new Campagne(),
  details: new Details(),
  calendar: new Calendar(),
  cycleVieComp: [],
  cycleVieHorsComp: [],
  listLignesProduits: [],
  infoComplementaireSchema: null,
  infoSpecifiquesSchema: null,
  listStatusCampagne: null,
  index_old_prospect: null,
  listCommerciaux: [],
  listOfProcess: [
    new Process('Prospect', 'active'),
    new Process('Campagne', 'toDo'),
    new Process('Détails', 'toDo'),
    new Process('Calendrier', 'toDo'),
    new Process('Aperçu', 'toDo'),
  ]
};

export const _addOpportuniteReducer = createReducer<AddOpportuniteState>(
  initState,
  on(addOppActions.UpdateTypeProspectAction, (state, {payload}) => {
    return {
      ...state,
      typeProspect: payload
    };
  }),
  on(addOppActions.SelectProduct, (state, {payload}) => {
    return {
      ...state,
      infoSpecifiquesSchema: payload
    };
  }),
  on(addOppActions.ResetAddOpportunite, (state) => {
    return {
      ...state,
      loadState: addOpportuniteStateEnum.INIT_STATE,
      errorMessage: null,
      typeProspect: addOpportuniteTypeProspectEnum.PARTICULIER,
      entreprise: new Entreprise(),
      prospectParticulier: new ListProspect(),
      prospectPro: new ListProspect(),
      cycleVie: new CycleVie(),
      details: new Details(),
      calendar: new Calendar(),
      campagne: new Campagne(),
      opportunite: new OpportunityAdd(),
      infoComplementaireSchema: null,
      infoSpecifiquesSchema: null,
      id_opportunite: null,
    };
  }),
  on(addOppActions.AddProspectAction, (state) => {
    if (state.typeProspect === addOpportuniteTypeProspectEnum.ENTREPRISE) {
      let prospectPro = {...state.prospectPro};
      prospectPro.listProspects = [...prospectPro.listProspects, new Prospect()];
      return {
        ...state,
        prospectPro: prospectPro
      };
    } else {
      let prospectParticulier = {...state.prospectParticulier};
      prospectParticulier.listProspects = [...prospectParticulier.listProspects, new Prospect()];
      return {
        ...state,
        prospectParticulier: prospectParticulier
      };

    }
  }),
  on(addOppActions.SaveAddOpportunite, (state) => {
    return {
      ...state,
      loadState: addOpportuniteStateEnum.LOADING_STATE
    };
  }),
  on(addOppActions.ResetAddOpportuniteErrorState, (state) => {
    return {
      ...state,
      loadState: addOpportuniteStateEnum.INIT_STATE
    };
  }),
  on(addOppActions.SaveAddOpportuniteSuccess, (state, {payload}) => {
    if(payload){
      return {
        ...state,
        loadState: addOpportuniteStateEnum.INSERT_STATE_SUCCESS,
        id_opportunite: payload
      };
    }else {
      return {
        ...state,
        loadState: addOpportuniteStateEnum.ERROR_STATE,
        errorMessage: payload
      };
    }
  }),
  on(addOppActions.SaveAddOpportuniteFail, (state, {payload}) => {
    return {
      ...state,
      loadState: addOpportuniteStateEnum.ERROR_STATE,
      errorMessage: payload
    };
  }),
  on(addOppActions.SelectOldProspect, (state, {payload}) => {
    return {
      ...state, index_old_prospect: payload
    };
  }),
  on(addOppActions.FillOldProspect, (state, {payload}) => {

    if (state.typeProspect === addOpportuniteTypeProspectEnum.ENTREPRISE) {
      const prospectPro = Object.assign({}, state.prospectPro);
      let listProspects = [...prospectPro.listProspects];
      let index = +state.index_old_prospect;
      if (index >= 1) {
        listProspects[index - 1] = payload;
        prospectPro.listProspects = listProspects;
      } else {
        prospectPro.mainProspect = payload;
      }
      return {
        ...state,
        prospectPro: prospectPro
      };
    } else {
      const prospectParticulier = Object.assign({}, state.prospectParticulier);
      let listProspects = [...prospectParticulier.listProspects];
      let index = +state.index_old_prospect;
      if (index >= 1) {
        listProspects[index - 1] = payload;
        prospectParticulier.listProspects = listProspects;
      } else {
        prospectParticulier.mainProspect = payload;
      }
      return {
        ...state,
        prospectParticulier: prospectParticulier
      };
    }
  }),
  on(addOppActions.FillOldEntrprise, (state, {payload}) => {
    if (state.typeProspect === addOpportuniteTypeProspectEnum.ENTREPRISE) {
      return {
        ...state,
        entreprise: payload
      };
    }
  }),
  on(addOppActions.DeleteProspectAction, (state, {payload}) => {
    if (state.typeProspect === addOpportuniteTypeProspectEnum.ENTREPRISE) {
      let prospectPro = Object.assign({}, state.prospectPro);
      prospectPro.listProspects = [...prospectPro.listProspects];
      prospectPro.listProspects.splice(payload, 1);
      return {
        ...state,
        prospectPro: prospectPro
      };
    } else {
      let prospectParticulier = Object.assign({}, state.prospectParticulier);
      prospectParticulier.listProspects = [...prospectParticulier.listProspects];
      prospectParticulier.listProspects.splice(payload, 1);
      return {
        ...state,
        prospectParticulier: prospectParticulier
      };

    }
  }),
  on(addOppActions.UpdateProspectAction, (state, {payload}) => {
    if (state.typeProspect === addOpportuniteTypeProspectEnum.ENTREPRISE) {
      const prospectPro = Object.assign({}, state.prospectPro);
      let listProspects = [...prospectPro.listProspects];
      let index = payload.index;
      if (index >= 0) {
        listProspects[index-1] = payload.info;
        prospectPro.listProspects = listProspects;
      } else {
        prospectPro.mainProspect = payload.info;
      }
      return {
        ...state,
        prospectPro: prospectPro
      };
    } else {
      const prospectParticulier = Object.assign({}, state.prospectParticulier);
      let listProspects = [...prospectParticulier.listProspects];
      let index = payload.index;
      if (index >= 0) {
        listProspects[index] = payload.info;
        prospectParticulier.listProspects = listProspects;
      } else {
        prospectParticulier.mainProspect = payload.info;
      }
      return {
        ...state,
        prospectParticulier: prospectParticulier
      };
    }

  }),
  on(addOppActions.UpdateCampagne, (state, {payload}) => {
    let camp_id = payload.campagne_id
    if (payload.choice != state.campagne.choice) camp_id = null;
    return {
      ...state,
      campagne: {
        ...payload,
        campagne_id: camp_id
      },
      details: {...state.details, model_info_complementaires: {}, model_info_specifiques: {}}
    };
  }),
  on(addOppActions.UpdateDetails, (state, {payload}) => {
    return {
      ...state,
      details: payload
    };

  }),
  on(addOppActions.GetListCampagnes, (state) => {
    return {...state};
  }),
  on(addOppActions.GetListLigneProductsSuccess, (state, {payload}) => {
    return {...state, listLignesProduits: payload};
  }),
  on(addOppActions.GetListCommercieauxSuccess, (state, {payload}) => {
    return {...state, listCommerciaux: payload};
  }),
  on(addOppActions.GetListStatusSuccess, (state, {payload}) => {
    return {
      ...state,
      listStatusCampagne: payload,
      calendar: {...state.calendar, status: payload.statut_depart?.id, statusLibelle: payload.statut_depart?.etat}
    };
  }),
  on(addOppActions.GetListCampagnesSuccess, (state, {payload}) => {
    let cycleVieHorsComp = [];
    let cycleVieComp = [];
    Object.keys(payload.hors_campagne).forEach(elem => {
      cycleVieHorsComp = cycleVieHorsComp.concat(payload.hors_campagne[elem]);
    });
    Object.keys(payload.campagne).forEach(elem => {
      cycleVieComp = cycleVieComp.concat(payload.campagne[elem]);
    });

    return {...state, cycleVie: payload, cycleVieHorsComp: cycleVieHorsComp, cycleVieComp: cycleVieComp};
  }),
  on(addOppActions.GetInfoComplementairesSuccess, (state, {payload}) => {
    return {
      ...state,
      infoComplementaireSchema: payload,
      infoSpecifiquesSchema: payload.form_prod,
    };
  }),
  on(addOppActions.GetInfoComplementairesFail, (state, {payload}) => {
    return {...state, infoComplementaireSchema: null};
  }),
  on(addOppActions.GetListCampagnesFail, (state, {payload}) => {
    return {...state};
  }),
  on(addOppActions.UpdateEntrepriseAction, (state, {payload}) => {
    return {...state, entreprise: payload};
  }),
  on(addOppActions.UpdateCalendar, (state, {payload}) => {
    return {...state, calendar: payload};
  }),
  on(addOppActions.UpdateStatus, (state, {payload}) => {
    return {...state, calendar: {...state.calendar, status: payload.id, statusLibelle: payload.item}};
  }),
  on(addOppActions.UpdateNavigationAction, (state, {payload}) => {
    return {...state, listOfProcess: payload};
  }),
  )
;

export function addOpportuniteReducer(state, action) {
  return _addOpportuniteReducer(state, action);
}
