import { Configuration } from 'src/app/services/configuration';
import { Component, OnInit } from '@angular/core'
import { AccessList } from './entity/accessList';
import { ApiAuthentificationService } from './services/ApiAuthentification/api-authentification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiConfigurationFactory } from './factory/ApiConfigurationFactory';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'geoprod-Front';
  accessListe : AccessList;

  constructor(    private readonly apiAuthentificationService: ApiAuthentificationService,
    
    private configuration: Configuration
    ){

  }
  
  ngOnInit(): void {
    this.waitForIdSession().then(() => {
      const accessListObservable = this.apiAuthentificationService.GetAccessList();
      if (accessListObservable) {
        accessListObservable.subscribe((data) => {
          if (data) {
            this.accessListe = data;
            this.apiAuthentificationService.setAccessListe(this.accessListe);
          }
        });
      }
    });
  }

  // Method to wait for the idSession to be set
  private waitForIdSession(): Promise<void> {
    return new Promise((resolve) => {
      const checkIdSession = () => {
        if (this.configuration.apiKeys?.idSession) {
          resolve();
        } else {
          setTimeout(checkIdSession, 50); // Retry after 50ms
        }
      };
      checkIdSession();
    });
  }
}

