<h1 mat-dialog-title>{{ titleDialog }}</h1>
<form [formGroup]="opportuniteForm" (ngSubmit)="onSubmitClick()">
    <div mat-dialog-content>
        <div class="modal-body">
            <mat-horizontal-stepper  #stepper >
                <mat-step [stepControl]="opportuniteForm.get('informationVeloForm')">
                    <ng-template matStepLabel >Informations Vélo</ng-template>
                    <fieldset formGroupName="informationVeloForm">
                        <div class="row mt-4">
                            <div class="col-6">
                                <mat-form-field class="w-100" appearance="fill">
                                    <mat-label>Type du vélo </mat-label>
                                    <mat-select name="type" formControlName="type">
                                        <mat-option *ngFor="let biketype of biketypes | keyvalue" [value]="biketype.key">
                                            {{ biketype.value }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field class="w-100" appearance="fill">
                                    <mat-label>Prix du vélo (€)</mat-label>
                                    <input type="number" pattern="(^[0-9]{0,4}$)|(^[0-9]{0,4}.[0-9]{0,2}$)" min="0" step=".01" formControlName="purchasePrice" matInput name="purchasePrice" />
                                    <!-- <mat-error *ngIf="formBike.controls['Prix dachat'].hasError('required') ">Prix de vélo
                            obligatoire</mat-error>
                        <mat-error
                            *ngIf="!formBike.controls['Prix dachat'].hasError('required')  && formBike.controls['Prix dachat'].dirty  && formBike.controls['Prix dachat'].invalid ">
                            Prix de vélo doit etre inferieur a 8000 € et superieur a 100 € </mat-error> -->
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field class="w-100">
                                    <input matInput [matDatepicker]="purchase" formControlName="purchaseDate" [(ngModel)]="purchaseDate" [max]="dateNow" name="purchaseDate" placeholder="Date d'achat" autocomplete="off" (click)="purchase.open()" readonly />
                                    <mat-datepicker-toggle matSuffix [for]="purchase"></mat-datepicker-toggle>
                                    <mat-datepicker #purchase></mat-datepicker>
                                    <!-- <mat-error *ngIf="purchaseDateControl.invalid">Vérfier la date d'achat</mat-error> -->
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field class="w-100" appearance="fill">
                                    <mat-label>Pour quel type de déplacement ?</mat-label>
                                    <mat-select id="usage" name="usage" formControlName="usage" (selectionChange)="changeUsage($event.value)">
                                        <mat-option *ngFor="let type of bikeusageEnum | keyvalue" value="{{type.key}}">
                                            {{ type.value }}
                                        </mat-option>
                                    </mat-select>
                                    <!-- <mat-error *ngIf="formBike.controls['Usage'].hasError('required') ">Type de déplacements
                            obligatoire</mat-error> -->
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field class="w-100" appearance="fill">
                                    <mat-label>Le vélo est equipé d'un traceur GPS ?</mat-label>
                                    <mat-select name="TrackerGPS" formControlName="TrackerGPS">
                                        <mat-option *ngFor="let type of bikeGpsEnum | keyvalue" [value]="type.key">
                                            {{ type.value }}
                                        </mat-option>
                                    </mat-select>
                                    <!-- <mat-error *ngIf="formBike.controls['Gps'].hasError('required') ">GPS obligatoire</mat-error> -->
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field class="w-100" appearance="fill">
                                    <mat-label>Vitesse maximale (Km/h)</mat-label>
                                    <input type="number" numbersOnly matInput formControlName="maximumSpeed" name="maximumSpeed" max="25" />
                                    <mat-error *ngIf="maximumSpeed.invalid">
                                        La vitesse maximale doit être inférieure à 25km/h !
                                    </mat-error>

                                    <!--  <mat-error *ngIf="formBike.controls['Vitesse maximum'].hasError('required') ">Vitesse de vélo
                          obligatoire</mat-error>
                      <mat-error
                          *ngIf="!formBike.controls['Vitesse maximum'].hasError('required')  && formBike.controls['Vitesse maximum'].dirty  && formBike.controls['Vitesse maximum'].invalid ">
                          La vitesse maximale doit être inférieure à 25km/h </mat-error> -->
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field class="w-100" appearance="fill">
                                    <mat-label>Numéro de série</mat-label>
                                    <input name="serialNumber" type="text" formControlName="serialNumber" matInput numbersOnly />
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field class="w-100" appearance="fill">
                                    <mat-label>Numéro de gravage</mat-label>
                                    <input name="etchingNumber" (change)="valuechange($event)" type="text"  formControlName="etchingNumber" matInput />
                                    <mat-error *ngIf="etchingNumber.invalid && !GravageExiste"> Numéro de gravage obligatoire ! </mat-error>
                                    <mat-error *ngIf="etchingNumber.invalid && GravageExiste"> Numéro de gravage existe déja ! </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field class="w-100" appearance="fill">
                                    <mat-label>Pour quelle pratique principale ?</mat-label>
                                    <mat-select name="classification" formControlName="classification">
                                        <mat-option *ngFor="let bikeclass of bikeClassificationEnum | keyvalue" [value]="bikeclass.key">
                                            {{ bikeclass.value }}
                                        </mat-option>
                                    </mat-select>
                                    <!-- <mat-error *ngIf="formBike.controls['Classification'].hasError('required') ">pratique principale
                          obligatoire</mat-error> -->
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field class="w-100" appearance="fill">
                                    <mat-label>Marque du vélo</mat-label>
                                    <input name="brand" formControlName="brand" type="text" matInput />
                                    <!-- <mat-error *ngIf="formBike.controls['Marque'].hasError('required') ">Marque de vélo obligatoire
                        </mat-error> -->
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field class="w-100" appearance="fill">
                                    <mat-label>Modèle du vélo</mat-label>
                                    <input name="model" formControlName="model" type="text" matInput />
                                    <!-- <mat-error *ngIf="formBike.controls['Modèle'].hasError('required') ">Modèle de vélo obligatoire
                      </mat-error> -->
                                </mat-form-field>
                            </div>
                        </div>
                    </fieldset>
                    <button matStepperNext style="float: right" type="button" class="btn-dt-chevron">
            <img src="assets/icons/chevron-black-icon.svg"alt="cheveronblack" />
          </button>
                </mat-step>
                <!-- && opportuniteForm.get('informationProForm')-->
                <mat-step [stepControl]="opportuniteForm.get('prospectForm')">
                    <ng-template matStepLabel>Informations Personnelles</ng-template>

                    <fieldset formGroupName="informationProForm">
                        <div class="row mt-4 p-2 bg-color-pro rounded">
                            <div class="col-12">
                                <div class="form-group text-left mb-0">
                                    <p class="switch mb-0" style="height: 18px">
                    <input
                      name="informationPro"
                      formControlName="isPro"
                      [(ngModel)]="informationPro"
                      (click)="checkInformationPro()"
                      (keydown.enter)="checkInformationPro()"
                      type="checkbox"
                      id="informationPro"
                    />
                    <span class="slider-m round"></span>
                  </p>
                                    <p class="text-secondary mb-0 text-sm-left"><strong>Contrat PRO</strong></p>
                                </div>
                            </div>
                            <ng-container *ngIf="informationPro">
                                <div class="col-6">
                                    <mat-form-field class="w-100" appearance="fill">
                                        <mat-label>{{ 'languages.prospect.RaisonSocialeRS' | translate}} </mat-label>
                                        <input name="RS" matInput formControlName="RS" [required]="informationPro" />
                                        <!-- <mat-error *ngIf="name.invalid"> Nom obligatoire ! </mat-error> -->
                                    </mat-form-field>
                                </div>
                                <div class="col-6">
                                    <mat-form-field class="w-100" appearance="fill">
                                        <mat-label>SIREN</mat-label>
                                        <input name="siren" matInput formControlName="siren" [required]="informationPro" />
                                        <!-- <mat-error *ngIf="name.invalid"> Nom obligatoire ! </mat-error> -->
                                    </mat-form-field>
                                </div>
                                <!-- <app-auto-complete-adresses
                          (onChangeAdresse)="onChangeAdresseSaisieAuto($event)"
                          [iconColor]="'gray'"
                        >
                        </app-auto-complete-adresses> -->
                                <!--  <mat-form-field class="w-100" appearance="fill">
                            <mat-label>Adresse</mat-label>
                            <input name="adress" matInput formControlName="adress" autocomplete="off" />
                          </mat-form-field> -->
                                <div class="col-6">
                                    <mat-form-field class="w-100" appearance="fill">
                                        <mat-label>Code Postal</mat-label>
                                        <input name="postalCode" matInput formControlName="postalCode" (change)="getVilleByCodepostal()" />
                                    </mat-form-field>
                                </div>
                                <div class="col-6">
                                    <ng-container *ngIf="codePostalLoader">
                                        <div class="col-12 d-flex justify-content-center">
                                            <div class="spinner-style">
                                                <mat-spinner [diameter]="20"></mat-spinner>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <mat-form-field *ngIf="!codePostalLoader" class="w-100">
                                        <mat-label>Ville</mat-label>
                                        <mat-select formControlName="ville">
                                            <mat-option *ngFor="let ville of listVilles " [value]="ville"> {{ville}} </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-6">
                                    <mat-form-field class="w-100" appearance="fill">
                                        <mat-label>Voie</mat-label>
                                        <input name="streetName" matInput formControlName="streetName" />
                                    </mat-form-field>
                                </div>
                                <div class="col-6">
                                    <mat-form-field class="w-100" appearance="fill">
                                        <mat-label>N° de voie</mat-label>
                                        <input  type="number" name="streetNumber" matInput formControlName="streetNumber" (keypress)="numberOnly($event)"/>
                                    </mat-form-field>
                                </div>
                            </ng-container>
                        </div>
                    </fieldset>
                    <fieldset formGroupName="prospectForm">
                        <div class="row mt-4">
                            <div class="col-6">
                                <mat-form-field class="w-100" appearance="fill">
                                    <mat-label>Civilité</mat-label>
                                    <mat-select formControlName="civilite" name="civilite">
                                        <mat-option value="Mr">M.</mat-option>
                                        <mat-option value="Mrs">Mme.</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field class="w-100" appearance="fill">
                                    <mat-label>Nom </mat-label>
                                    <input name="name" required matInput formControlName="name" placeholder="Nom" />
                                    <mat-error *ngIf="name.invalid"> Nom obligatoire ! </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field class="w-100" appearance="fill">
                                    <mat-label>Prénom</mat-label>
                                    <input matInput required name="surname" formControlName="surname" placeholder="Prénom" />
                                    <mat-error *ngIf="surname.invalid"> Prénom obligatoire ! </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field class="w-100" appearance="fill">
                                    <mat-label>Date de naissance</mat-label>
                                    <input matInput [matDatepicker]="naissance" formControlName="DN" [(ngModel)]="birthDate" [max]="dateNow" name="date_naiss_user" placeholder="Date de naissance" autocomplete="off" (click)="naissance.open()" readonly />
                                    <mat-datepicker-toggle matSuffix [for]="naissance"></mat-datepicker-toggle>
                                    <mat-datepicker #naissance></mat-datepicker>
                                    <mat-error *ngIf="birthDateControl.getError('younger') as error">
                                        La souscription est réservée au plus de 18 ans</mat-error>
                                    <mat-error *ngIf="birthDateControl.invalid"> Date de naissance est obligatoire </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field class="w-100" appearance="fill">
                                    <mat-label>E-mail</mat-label>
                                    <input matInput required name="email" type="email" formControlName="adresse_mail" placeholder="E-mail" />
                                    <mat-error *ngIf="adresseMail.invalid"> Format est invalide ! </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field class="w-100" appearance="fill">
                                    <mat-label>Confirmation de l'e-mail</mat-label>
                                    <input matInput name="email" type="email" formControlName="confirmationEmail" placeholder="E-mail" />
                                     <mat-error *ngIf="confirmationEmail.invalid">
                                        Email ne correspond pas</mat-error>
                                </mat-form-field>
                            </div>
                            <!-- <div class="col-6">
                                <mat-form-field class="full-width" appearance="fill">
                                    <mat-label class="ng2tel-field-label">Mobile</mat-label>
                                    <input required (keypress)="numberOnly($event)" ng2TelInput matInput (intlTelInputObject)="telInputObject($event)" name="mobile" maxlength="14" formControlName="mobile" [ng2TelInputOptions]="{ initialCountry: 'fr' }" (ng2TelOutput)="getNumber($event, 'mobile')"
                                    />
                                    <i class="fa-solid fa-phone-flip float-right"></i>
                                </mat-form-field>
                            </div> -->
                            <!-- <div class="col-6">
                                <mat-form-field class="full-width" appearance="fill">
                                    <mat-label class="ng2tel-field-label">Téléphone</mat-label>
                                    <input (keypress)="numberOnly($event)" ng2TelInput matInput (intlTelInputObject)="telInputObject($event)" name="tel" maxlength="14" formControlName="tel" [ng2TelInputOptions]="{ initialCountry: 'fr' }" (ng2TelOutput)="getNumber($event, 'tel')" />
                                    <i class="fa-solid fa-phone-flip float-right"></i>
                                </mat-form-field>
                            </div> --> 
                            <div class="col-6">
                                <mat-form-field class="w-100" appearance="fill">
                                    <mat-label>Occupation</mat-label>
                                    <mat-select name="occupation" formControlName="occupation" required>
                                        <mat-option *ngFor="let occup of OccupationList" [value]="occup.key">
                                            {{ occup.value }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field class="w-100" appearance="fill">
                                    <mat-label>Code postal</mat-label>
                                    <input matInput (keypress)="numberOnly($event)" min="0" type="text" numbersonly maxlength="5" formControlName="CP" required name="zip_code" disabled />
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field class="w-100" appearance="fill">
                                    <mat-label>Ville</mat-label>
                                    <mat-select required name="ville" formControlName="ville" disabled>
                                        <mat-option *ngFor="let ville of listvilles" [value]="ville"> {{ ville }} </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field class="w-100" appearance="fill">
                                    <mat-label>N°de voie</mat-label>
                                    <input matInput formControlName="streetNumber" type="text" name="streetNumber" type="number" (keypress)="numberOnly($event)" />
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field class="w-100" appearance="fill">
                                    <mat-label>Voie</mat-label>
                                    <input matInput formControlName="streetName" type="text" name="streetName" />
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field class="w-100" appearance="fill">
                                    <mat-label>Complément d'adresse</mat-label>
                                    <textarea placeholder="Complément d'adresse" formControlName="complement_adr" matInput rows="1" name="complement_adresse"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </fieldset>

                    <button matStepperPrevious type="button" class="btn-dt-chevron-white">
            <img src="assets/icons/chevron-white-icon.svg" alt="cheveronright" />
          </button>
                    <button matStepperNext style="float: right" type="button" class="btn-dt-chevron">
            <img src="assets/icons/chevron-black-icon.svg"alt="cheveronblack" />
          </button>
                </mat-step>
                <mat-step [stepControl]="opportuniteForm.get('devis')">
                    <ng-template matStepLabel>Demande Devis</ng-template>
                    <p class="text-center">Voulez-vous envoyer le devis au prospect?</p>
                    <div class="text-center">
                        <button type="button" class="btn-dt-activer" (click)="demandeDevis('oui')"     [ngClass]="{'colorButtonOui': verif === 'no' , 'buttonSizeOui': verif === 'oui' }">OUI</button>
                        <button type="button" class="btn-dt-desactiver" (click)="demandeDevis('non')" [ngClass]="{'colorButtonNo': verif_Color === 'oui' , 'buttonSizeNon': verif_Color === 'no'}">NON</button>
                    </div>
                    <button matStepperPrevious type="button" class="btn-dt-chevron-white">
            <img src="assets/icons/chevron-white-icon.svg" alt="cheveronright" />
          </button>
                    <button matStepperNext style="float: right" type="button" class="btn-dt-chevron">
            <img src="assets/icons/chevron-black-icon.svg"alt="cheveronblack" />
          </button>
                </mat-step>
                <mat-step>
                    <ng-template matStepLabel>Confirmation</ng-template>
                    <div class="text-center mt-5">
                        <button type="button" class="btn-dt-cancel-popup mr-3" [mat-dialog-close]="false">
              <i class="fa-solid fa-circle-xmark  fa-lg mr-1"></i> Annuler
          </button>
        <button type="submit" class="btn-dt-save-popup " cdkFocusInitial [ngStyle]="{'background-color': couleur }">
            <i class="fa-solid fa-circle-check fa-lg mr-1 mt-1 fa-icon-style" (keyboard.enter)="getIndexTab($event)" (click)="getIndexTab($event)"></i>
            Confirmer
          </button>
                    </div>
                    <button matStepperPrevious type="button" class="btn-dt-chevron-white">
            <img src="assets/icons/chevron-white-icon.svg" alt="cheveronright" />
          </button>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
    </div>
</form>
