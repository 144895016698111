import { BankInformation } from './../BankInformation';
import { FormControl } from '@angular/forms';
import { Formbuilderr, Prospect } from '../Prospect';

export class AdresseEntreprise {
  postalCode: string;
  streetName: string;
  streetNumber: string;
  ville: string;
  CP?: string;
}
export class Entreprise {
  invalidForms: any[] = [];

  constructor(init?: Partial<FormControl>) {
    Object.assign(this, init);
  }

  error?;
  message?;
  // json_object;
  siren;
  fax;
  form_juridique;
  siret;
  naf;
  adress: AdresseEntreprise = new AdresseEntreprise();
  effectif;
  RS;
  FJ;
  mail;
  nom_commercial;
  web;
  tel;
  id;
  convention_collective;
  respensable;
  type_opportunite?;
  listProspectsLier: Prospect[] = [];
  mainProspect: Prospect = new Prospect();
  information_bancaire: BankInformation = new BankInformation();
  tranche_effectifs;
  date_creation;
  numero_tva_intra;
  form: Formbuilderr = new Formbuilderr();
}
