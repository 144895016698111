import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';

@Component({
  selector: 'app-add-new-maileva',
  templateUrl: './add-new-maileva.component.html',
  styleUrls: ['./add-new-maileva.component.css'],
})
export class AddNewMailevaComponent implements OnInit {
  fromDetails: Boolean = false;
  navbarTxt:string="";
  constructor(private readonly sharedMenuObserverService: SharedMenuObserverService,
    private translate : TranslateService
    ) {}

  ngOnInit(): void {



    this.translate.get('languages').subscribe((object: any) => {


      this.navbarTxt=object.navbar.maileva +' ➜ '+ object.navbar.Ajouter
      this.sharedMenuObserverService.updateMenu(this.navbarTxt);
    });
    this.translate.onLangChange.subscribe(() => {

      this.translate.get('languages').subscribe((object: any) => {

        this.navbarTxt=object.navbar.maileva +' ➜ '+ object.navbar.Ajouter
      this.sharedMenuObserverService.updateMenu(this.navbarTxt);
      });});
  }
}
