<div class="flex-column bg-white rounded-card">
  <div class="header-card-gestion py-3">
    <div class="col-3">
    <button type="button" class="btn-dt-add  ml-4" routerLink="fiche/Nouveau"  [attr.data-title]="'languages.companies.addCompagnie' | translate">
      <i class="fa-solid fa-plus "></i>
    </button>
  </div>
  </div>
  <div class="p-3 mb-3" >
    <div class="row">
      <div class="col-8"></div>
      <div class="col-4 w-50 p-2 mt-2 text-right">
        <input class="search" name="search" [(ngModel)]="search" (input)="onInputChange($event.target.value)" placeholder="{{'languages.miniListing.search' | translate}}">
        <i class="fa fa-search icon-search fa-md"></i>
      </div>
    </div>
    <table id="Table" class="table">
      <thead class="head-table">
        <tr>
          <th> {{"languages.prospect.Identifiant" | translate}}</th>
          <th> {{"languages.companies.Immatriculation" | translate}}  </th>
          <th> {{"languages.companies.Raisonsociale" | translate}} </th>
          <th>  {{"languages.listing.Type" | translate}}</th>
          <th>  {{"languages.listing.Action" | translate}}</th>
        </tr>
      </thead>
      <tbody class="body-table">
        <tr  *ngFor="let item of Companies; let index = index">
          <td>{{ item.siren }}</td>
          <td>{{ item.matricule }}</td>
          <td>{{ item.denomination }}</td>
          <td>{{ item.types }}</td>
          <td>
            <button type="button" class="btn-dt-list" routerLink="fiche/{{item.siren}}">
              <img src="/assets/icons/edit-icon.svg" alt="edit">


            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="navbar-center mb-4 row"  *ngIf="Companies.length >0">
      <div class="col-5 pt-1">
        <div class="row w-100 flex-nowrap" >
          <div  class="mt-1">
            <p>{{'languages.miniListing.Afficher' | translate}}</p>
          </div>
          <div  class="mx-1">
            <select [(ngModel)]="limit"  (change)="updateListCompagnie()" 
            class="bg-white border pt-1 rounded font-weight-bold">
              <option value="15">15</option>
              <option value="25">25</option>
              <option value="30">30</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <div class="mt-1 text-nowrap">
            <p>{{'languages.miniListing.pagesPerPage' | translate}} {{totalLength}} {{'languages.miniListing.element' | translate}}</p> 
          </div>
        </div>
      </div>
      <div class="col-5">
        <nav aria-label="Page navigation example"  *ngIf="totalPages>0">
          <ul class="pagination pg-blue">
            <li (click)="first()" class="page-item" [ngClass]="{'disabled':isFirst()}" (keydown.enter)="first()">
              <a class="page-link-symbol">
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            <li *ngIf="!isFirst()" (click)="prev()" (keydown.enter)="prev()" class="page-item mx-2" >

              <a class="page-link-symbol-chevron" aria-label="Previous">
                <i class="fa fa-chevron-left "></i>
              </a>
            </li>
            <ng-container *ngFor="let item of [].constructor(3); let i = index">
              <li *ngIf="i+page-3>0" class="page-item">
                <a (click)="changepage(i+page-3)" class="page-link mt-2 border rounded">{{i + page - 3}}</a>
              </li>
            </ng-container>
            <ng-container *ngFor="let item of [].constructor(3); let i = index">
              <li *ngIf="i+page<totalPages" class="page-item">
                <a (click)="changepage(i+page)" class="page-link mt-2 border rounded" 
                [ngClass]="{'active-page':page==(i+page)}">
                {{i + page}}
              </a>
              </li>
            </ng-container>
            <li *ngIf="totalPages>1 && totalPages > 3 " class="m-2"> ... </li>
            <li class="page-item">
              <a (click)="changepage(totalPages)" class="page-link mt-2 border rounded"
                [ngClass]="{'active-page':page==totalPages}">{{totalPages}}</a>
            </li>
            <li *ngIf="!isLast()" (click)="next()" class="page-item mx-2" (keydown.enter)="next()">
              <a class="page-link-symbol-chevron" aria-label="Next">
                <i class="fa fa-chevron-right"></i>
              </a>
            </li>
            <li (click)="last()" class="page-item" [ngClass]="{'disabled':isLast()}" (keydown.enter)="last()">
              <a class="page-link-symbol">
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
          </ul>
        </nav>
    </div>
      <div class="col">
        <div class="row position-absolute flex-nowrap" style="right: 0px">
          <div class="mt-1text-nowrap text-right mt-2">
            <p >{{'languages.listing.label_goto' | translate}}</p>
          </div>
          <div class="mt-1 ml-2">
            <input [(ngModel)]="intervale"    size="1"    min="1"  onkeypress="return event.charCode >= 48"  (keyup.enter)="getchangeLimite()"  (change)="getchangeLimite()"  type="number" style="margin-left: 4px;"
            size="1" class="page_input bg-white border rounded font-weight-bold" [value]="page" max="{{totalPages}}">
          </div>
        </div>
      </div>
    </div>
    <div class="text-center justify-content-center" *ngIf="Companies.length ==0">
      <p class="text-center justify-content-center">{{'languages.miniListing.noRecords' | translate}}</p>
 </div>
  </div>
</div>
