import { OpportunitiesDetailsComponent } from './../../opportunities-details.component';
import { Component, OnInit, OnChanges, AfterViewInit, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { SharedMenuObserverService } from '../../../../../services/SharedMenuObserver/shared-menu-observer.service';
import { ActivatedRoute, Route, Router } from '@angular/router';

import { Entreprise } from '../../../../../entity/Opportunity/Entreprise';
import { Store } from '@ngrx/store';
import * as selectors from '../../store';
import { Observable } from 'rxjs';
import { DetailsOpportuniteStore } from '../../store/details-opportunite.reducers';
import * as actions from '../../store/details-opportunite.actions';
import { DatePipe } from '@angular/common';
import { ApiOpportunitService } from 'src/app/services/ApiOpportunite/api-opportunite.service';
import { EventEmiterService } from 'src/app/services/event.emmiter.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-prospect',
  templateUrl: './prospect.component.html',
  styleUrls: ['./prospect.component.css'],
})
export class ProspectComponent implements OnInit, AfterViewInit {
  entreprise$: Observable<any> | null = null;
  id_prospect: string = '';
  idOpportunite: string = '';
  allowed_to_update: boolean;
  isperson: boolean;
  alerts: any;
  navbarTxt: string = '';
  constructor(
    private readonly datePipe: DatePipe,
    private readonly sharedMenuObserverService: SharedMenuObserverService,
    private store$: Store<DetailsOpportuniteStore>,
    private opportunitiesDetails: OpportunitiesDetailsComponent,
    private readonly apiOpportunitService: ApiOpportunitService,
    private readonly _eventEmiter: EventEmiterService,
    private readonly translate: TranslateService,
    private readonly router: Router,
    private cdr: ChangeDetectorRef
  ) {
    this._eventEmiter.getperson().subscribe((data) => {
      this.isperson = data;
    });
  }

  ngAfterViewInit(): void {
    this.apiOpportunitService.onChangeAccesOpportunite().subscribe((value: boolean) => {
      this.allowed_to_update = value;
      this.cdr.detectChanges();
    });
  }

  ngOnInit(): void {
    this.idOpportunite = this.opportunitiesDetails.opportunity_id;
    this.entreprise$ = this.store$.select(selectors.getEntrepriseFromDetailsOpportunite);

    this.translate.get('languages').subscribe((object: any) => {
      this.navbarTxt =
        object.navbar.Opportunites + ' ➜ ' + object.navbar.Détails + ' ➜ ' + object.navbar.OppDetailsProspect;
      this.sharedMenuObserverService.updateMenu(this.navbarTxt);
    });
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.navbarTxt =
          object.navbar.Opportunites + ' ➜ ' + object.navbar.Détails + ' ➜ ' + object.navbar.OppDetailsProspect;
        this.sharedMenuObserverService.updateMenu(this.navbarTxt);
      });
    });
    this.entreprise$.subscribe((data) => {
      if (data?.mainProspect != null) this.id_prospect = data.mainProspect.id;
    });
  }

  NaviagateToFicheProspect(idOpp, idProspect) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/prospectClient/fiche-prospect/' + idOpp + '/' + idProspect + '/opportunite'])
    );
    window.open(url, '_blank');
  }

  onUpdateEntreprise($event: Entreprise) {
    if (
      $event.mainProspect.name === '' ||
      $event.mainProspect.surname === '' ||
      $event.mainProspect.adresse_mail === ''
    ) {
      this._eventEmiter.seterrorUpdateProspect(true);
    } else {
      this._eventEmiter.seterrorUpdateProspect(false);
    }

    if ($event?.mainProspect?.id) {
      $event.mainProspect.DN = this.datePipe.transform($event.mainProspect.DN, 'yyyy-MM-dd');
      this.store$.dispatch(actions.UpdateProspectAction({ payload: $event }));
    } else {
      $event?.listProspectsLier.forEach((element) => {
        element.DN = this.datePipe.transform(element.DN, 'yyyy-MM-dd');
      });
      this.store$.dispatch(actions.UpdateProspectAction({ payload: $event }));
    }
  }

  resetEntreprise() {
    this.store$.dispatch(actions.ResetProspect());
  }
}
