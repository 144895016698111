<h3> Header et navbar Buttons</h3>
<div class="example-button-row">
  <div class="row">
    <div class="col-4">
      <!---------------Button Imprimer ------------------>
    <button type="button" class="btn-dt-print">
      <i class="fa-solid fa-print fa-lg"></i>
      Imprimer
    </button>
    </div>
    <div class="col-4">
      <!---------------Button Imprimer ------------------>
    <button type="button" class="btn-dt-actions-groupee">
      Actions Groupées
    </button>
    </div>
    <div class="col-4">
       <!---------------Button Exporter ------------------>
    <button  type="button" class="btn-dt-exporter">
      <i class="fa-solid fa-file-excel fa-lg"></i>
            Exporter en .csv
    </button>
    </div>
    <div class="col-4">
      <!---------------Button Actions ------------------>
    <button  type="button" class="btn-dt-action">
      <div class="icon-position">
        <img class="icon-btn-action" src="assets/icons/action-icon.svg" alt="actionIcon">
      </div>
      <span> Actions</span>
    </button>
    </div>
    <div class="col-4">
      <!---------------Button Retour à la liste dans les détails------------------>
     <button type="button" class="btn-dt-retour btn-custom-details">
      <div class="icon-position">
        <i class="fa-solid fa-arrow-left fa-icon-style fa-lg"></i>
      </div>
      <span> Retour à la liste </span>
    </button>
    </div>
    <div class="col-4">
      <!---------------Button Enregistrer ------------------>
    <button type="button" class="btn-dt-save">
        <i class="fa-solid fa-circle-check fa-lg mr-1"></i>
      Enregistrer
    </button>
    </div>
    <div class="col-4">
      <!---------------Button Retour à la liste ------------------>
     <button  type="button" class="btn-dt-retour">
      <div class="icon-position">
        <i class="fa-solid fa-arrow-left fa-icon-style fa-lg"></i>
      </div>
      <span> Retour à la liste </span>
    </button>
    </div>
    <div class="col-4">
      <!---------------Button Link ------------------>
    <button  type="button" class="btn-dt-link">
       <i class="fa-solid fa-link fa-icon-style"></i>
      Lien
    </button>
    </div>
    <div class="col-4">
      <!---------------Button Envoyer ------------------>
      <button  type="button" class="btn-dt-send">
        <img class="icon-btn-send" src="assets/icons/paper-plane-icon.svg">
        Envoyer
      </button>
    </div>
    <div class="col-4">
      <!---------------Button ellipsis ------------------>
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <i class="fa-solid fa-ellipsis-vertical fa fa-lg icon-menu-bleu"></i>
      </button>
    </div>
    <div class="col-4">
       <!---------------Button generateur key ------------------>
       <button type="button" class="btn-keygen">
         <i class="fa-solid fa-key fa-lg"></i>
        Générer
      </button>
    </div>
    <div class=" row d-flex justify-content-center mt-2" >
<div class="col-4">
  <button type="button" class="btn-suivant">
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1377 0.268984C13.4963 0.627628 13.4963 1.20911 13.1377 1.56775L5.70542 9L13.1377 16.4322C13.4963 16.7909 13.4963 17.3724 13.1377 17.731C12.779 18.0897 12.1975 18.0897 11.8389 17.731L3.75726 9.64938C3.39862 9.29074 3.39862 8.70926 3.75726 8.35062L11.8389 0.268984C12.1975 -0.0896612 12.779 -0.0896612 13.1377 0.268984Z"  />
      </svg>
    Précédent 
  
  
</button>
</div>
<div class="col-4">
  <button type="button" class="btn-suivant ml-4">
    Suivant 
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.86234 17.731C4.50369 17.3724 4.50369 16.7909 4.86234 16.4322L12.2946 9L4.86234 1.56775C4.50369 1.20911 4.50369 0.627628 4.86234 0.268982C5.22098 -0.0896626 5.80246 -0.0896626 6.1611 0.268982L14.2427 8.35062C14.6014 8.70926 14.6014 9.29074 14.2427 9.64938L6.1611 17.731C5.80246 18.0897 5.22098 18.0897 4.86234 17.731Z" />
      </svg>
</button>
</div>
<div class="col-4 w-100 pl-0 d-flex justify-content-center">
  <button type="button" class="filtrer" >
   <img src="assets/icons/filtre.svg" class="mr-3" alt="">
   Filtrer
  </button>
</div>
</div>
    
  </div>

  
  <br>
  <div class="row">
    <div class="col-12">
      <app-code [code]="codeHeaderBtn"></app-code>
    </div>
  </div>
  <h3> Header Exemple</h3>
  <!--------------- Card header ------------------>
  <div class="card-head text-center">
    <div class="row details-header">
      <button  type="button" class="btn-dt-retour">
        <div class="icon-position">
          <i class="fa-solid fa-arrow-left fa-icon-style fa-lg"></i>
        </div>
        <span> Retour à la liste </span>
      </button>
      <div class="row details-spinner">
          <ng-container> <!-- *ngIf="show_loading" -->
            <div>
              <div class="col-12 text-center">
                <div class="spinner-style">
                  <mat-spinner [diameter]="30"></mat-spinner>
                </div>
              </div>
            </div>
          </ng-container>
          <button  type="button" class="btn-load">
            <i class="fa-solid fa-rotate-right"></i>
          </button>
      </div>
      <button  type="button" class="btn-dt-save">
        <i class="fa-solid fa-circle-check fa-lg mr-1"></i>
          Enregistrer
      </button>
  </div>
  <div class="row" >

    <div class="col-1 d-flex align-items-center">
      <button type="button" class="btn-dt-retourr mt-1" >
          <div class="icon-position">
              <i class="fa-solid fa-arrow-left fa-icon-style fa-lg"></i>
          </div>
      </button>
  </div>
  </div>
</div>
  <br>
  <div class="row">
    <div class="col-12">
      <app-code title="Header Code" [code]="headercode"></app-code>
    </div>
  </div>

<h3>Buttons dans Popup</h3>

<div class="row">
  <div class="col-3">
    <!---------------Button save popup ------------------>
  <button  type="button" class="btn-dt-save-popup">
    <i class="fa-solid fa-circle-check fa-lg mr-1 mt-1 fa-icon-style"></i>

    Enregistrer
  </button>

 </div>
 <div class="col-3">
  <!---------------Button cancel popup ------------------>
  <button  type="button" class="btn-dt-cancel-popup">
      <i class="fa-solid fa-circle-xmark  fa-lg mr-1"></i>
    Annuler
  </button>
</div>
<div class="col-3">
  <!---------------Button edit popup ------------------>
  <button  type="button" class="btn-dt-edit-popup">
    <i class="fa-solid fa-pen-to-square fa-icon-style"></i>
    Modifier
  </button>
</div>
<div class="col-3">
  <!---------------Button supprimer popup ------------------>
  <button  type="button" class="btn-dt-delete-popup">
    <img class="icon-btn-action" src="assets/icons/trash-icon.svg">&nbsp;
    Supprimer
  </button>
</div>
</div>
<div class="row">
  <div class="col-12">
    <app-code [code]="codebtnpopup"></app-code>
  </div>
</div>

<div>
<h3>Details Boutons</h3>
<div class="row">
    <div class="col-4">
       <!---------------Button save details ------------------>
    <button  type="button" class="btn-dt-save-blue">
      <div class="icon-position">
        <img  src="/assets/icons/save-icon-blue.svg" alt="iconSave">&nbsp;&nbsp;
      </div>
      <span> Enregistrer</span>
    </button>
    </div>
    <div class="col-4">
          <!---------------Button save details ------------------>
      <button  type="button" class="btn-dt-save-white">
        <div class="icon-position">
          <img src="/assets/icons/save-icon-white.svg">&nbsp;&nbsp;
        </div>
        <span> Enregistrer</span>
      </button>
    </div>
    <div class="col-4">
      <!---------------Button Details ------------------>
     <button  type="button" class="btn-dt-details">
      <div class="icon-position">
        <i class="fa-solid fa-user fa-icon-style"></i>
      </div>
      <span> Buttons Details </span>
    </button>
    </div>
</div>
<div class="row">
  <div class="col-2">
    <button type="button" class="btn-dt-add" data-title="text ici">
      <i class="fa-solid fa-plus fa-lg"></i>
    </button>
  </div>
  <div class="col-2">
    <button  type="button" class="btn-dt-list">
      <img src="/assets/icons/edit-icon.svg" alt="edit">
    </button>
  </div>
  <div class="col-2">
    <button  type="button" class="btn-dt-list">
      <img src="/assets/icons/pdf-icon.svg" alt="iconPdf">
    </button>
  </div>
  <div class="col-2">
    <button  type="button" class="btn-dt-list">
      <img src="/assets/icons/share-icon.svg" alt="share">
    </button>
  </div>
  <div class="col-2">
    <button  type="button" class="btn-dt-list">
      <img src="/assets/icons/phone-icon.svg" alt="phoneicon">
    </button>
  </div>
  <div class="col-2">
    <button type="button" class="btn-dt-edit-tk" data-title="text ici">
      <img src="/assets/icons/edit-2-icon.svg">
    </button>
  </div>
</div>
<div class="row">
<div class="col-4">
  <button type="button"  class="Avenant-btn"
  data-toggle="modal" data-target="#myModal">
  <i class="fa-solid fa-square-plus fa fa-lg"></i>
  Créer un avenant
</button>
</div>
<div class="col-4">
  <button type="button" class="btn-dt-save-blue" >
    <div class="icon-position">
      <img src="/assets/icons/save-icon-blue.svg" alt="iconSave">&nbsp;&nbsp;
    </div>
    <span>Mettre à jour </span>
  </button>
  
</div>
<div class="col-4">
  <button type="button" class="changeStatus" >
    <span class="mb-1">Changer le statut</span>
    <i class="fa-solid fa-arrow-right fa-icon-style   ml-3 fa-lg"></i>
</button>
</div>
</div>
<div>
 
<div class="col-4">
  <button class="statut-envoieCancel text-center d-flex align-items-center "  >
    <i class="fa-solid fa-circle-xmark  fa-lg mr-2"></i>
            <b>{{'languages.communication.AnnulerLenvoie' | translate}}</b>
          </button>
</div>
</div>

<div class="row">
  <div class="col-12">
    <app-code [code]="btndetails"></app-code>
  </div>
</div>

</div>
<h3>des états et des Boutons</h3>
<div class="row">
    <div class="col-4">
      <!---------------Icon Check Circle ------------------>
      <span>
        <img src="assets/icons/check-circle.svg" alt="done">
      </span>
    </div>
    <div class="col-4">
      <!---------------Icon Cancel Circle ------------------>
      <span>
        <img src="assets/icons/times-icon.svg" alt="close">
      </span>
    </div>
    <div class="col-4">
      <!--------------- Icon Delete ------------------>
      <span>
        <img src="assets/icons/trash-icon-red.svg" alt="trashIcon"  >
      </span>
    </div>
    <div class="col-4">
      <!---------------Button Reset ------------------>
      <button  type="button" class="btn-load" type="button">
        <i class="fa-solid fa-rotate-right"></i>
      </button>
    </div>
    <div class="col-4">
      <!---------------Button Favoris ------------------>
      <input type='checkbox' id="perm" data-title="Text ici" class="star" />
      <label for="perm"></label>
    </div>
    <div class="col-4">
      <!---------------Button left  ------------------>
      <button  type="button" class="btn-dt-chevron-white">
        <img src="assets/icons/chevron-white-icon.svg" alt="cheveronright">
      </button>
    </div>
    <div class="col-4">
      <!---------------Button right ------------------>
      <button  type="button" class="btn-dt-chevron">
        <img src="assets/icons/chevron-black-icon.svg"alt="cheveronblack">
      </button>
    </div>
</div>
<div class="row">
  <div class="col-12">
    <app-code [code]="codebtnicon"></app-code>
  </div>
</div>
<h3>Data Table Buttons</h3>
<div class="row">
  <div class="col-2">
    <!---------------Button Add ------------------>
  <button type="button" class="btn-dt-add" data-title="text ici">
    <i class="fa-solid fa-plus"></i> 
  </button>
  </div>
  <div class="col-2">
    <!---------------Button Add ------------------>
  <button type="button" class="btn-dt-add">
    <i class="fa-solid fa-plus"></i> 
  </button>
  </div>
  <div class="col-4">
    <!---------------Button Edit ------------------>
    <button  type="button" class="btn-dt-edit">
      <img src="assets/icons/edit-icon.svg" alt="edit">

  </button>
  </div>
  <div class="col-4">
    <!---------------Button Delete ------------------>
  <button  type="button" class="btn-dt-delete">
    <i class="fa-solid fa-trash"></i>
  </button>
  </div>
  <div class="col-4">
    <!---------------Button Déactiver ------------------>
  <button  type="button" class="btn-dt-desactiver">
    
    <i class="fa-solid fa-lock fa-fw"></i>
    Désactiver
  </button>
  </div>
  <div class="col-4">
      <!---------------Button Activer ------------------>
    <button  type="button" class="btn-dt-activer">
        <i class="fa-solid fa-unlock-keyhole fa-fw"></i>
      Activer
    </button>
  </div>
  <div class="col-4">
    <!---------------Button Ouvrir ------------------>
    <button  type="button" class="btn-dt-ouvrir">
      
      <i class="fa-solid fa-up-right-from-square con-btn-ouvrir"></i>
      Ouvrir
    </button>
  </div>
  <div class="col-4">
    <!---------------Button Filtrer ------------------>
  <button  type="button" class="btn-dt-filter">
    <i class="fa-solid fa-filter fa-fw"></i>
    Filtrer
  </button>
  </div>
    <!---------------Button Settings ------------------>
  <div class="col-4">
    <button type="button" class="btn btn-dt-settings" data-title="text ici">
      <img class="icon-btn-settings" src="assets/icons/settings-icon.svg">
    </button>
  </div>
<!---------------Button add file ------------------>
  <div class="col-4">
    <span class="add-file-contaniner">
      <i class="fa-solid fa-circle-plus add-file-icon"></i>
      <i class="fa-solid fa-paperclip add-file-paper-icon"></i>
    </span>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <app-code [code]="codeDataTableBtn"></app-code>
  </div>
</div>

  <mat-menu #menu="matMenu">
    <button  type="button" mat-menu-item class="divider-menu bleu-clr">
      <i class="fa fa-share-square-o fa-fw" aria-hidden="true"></i>&nbsp;
      <span>Exporter sélection</span>
    </button>
    <button type="button" mat-menu-item class=" bleu-clr">
      <i class="fa fa-stop-circle-o fa-fw" aria-hidden="true"></i>&nbsp;
      <span>Actions Groupés</span>
    </button>
  </mat-menu>

</div>

<h3>buttons toggle</h3>
<div class="row">
  <div class="col-3">
    <div class="switch-toggle switch-ios">
      <input id="On" value="on" name="view" type="radio" checked>
      <label for="On" onclick="">ON</label>

      <input value="na" id="N/A" name="view" type="radio">
      <label for="N/A" onclick="">N/A</label>

      <input value="off" id="Off" name="view" type="radio">
      <label for="Off" onclick="">OFF</label>
      <a></a>
    </div>
  </div>
  <div class="col-3">
    <div class="custom-slide-togglee" [class.checked]="isChecked" (click)="changeToggle()">
      <div class="togglee-thumb"></div>
      <span class="togglee-label">{{switcher}}</span>
    </div>
  </div>
</div>


  <mat-radio-group class=" TypeContratt col-6 d-flex align-items-center justify-content-center m-1" >
    <mat-radio-button class="col-3" value="N/R">N/R</mat-radio-button>
    <mat-radio-button class="col-3" value="Non Assure">Non Assure</mat-radio-button>
    <mat-radio-button class="col-3" value="Assure">Assure</mat-radio-button>
  </mat-radio-group>

<app-code [code]="codebtntoggle"></app-code>


<h3>buttons lock</h3>
<div class="col-md-6 d-flex justify-content-end mb-1 pl-5 ">
  <div  *ngIf="isPasswordInputVisible" class="row passwordDiv" >
      <img  class=" col-3" src="assets/icons/lock.svg" alt="lockIcon">
  <div class="col-9 d-flex  justify-content-end pl-0">
      <mat-form-field >
          <input
            matInput
            [type]="showPassword ? 'text' : 'password'"
            placeholder="{{'languages.affaire.writePassword' | translate}}"
            [(ngModel)]="password"
            (change)="validPass()"
            autocomplete="off">

        </mat-form-field>
        <button type="button" (click)="togglePasswordVisibility()" [disabled]="allow_to_change" class= "eyeBtn">
          <i class="fa-solid fa-eye-slash fa fa-lg" *ngIf="!showPassword" ></i>
          <i class="fa-solid  fa-eye fa fa-lg" *ngIf="showPassword"></i>
              </button>

  </div>
</div>
  <div class="col-1">
  <button type="button" (click)="togglePasswordInput()" [disabled]="allow_to_change" class="btn-lock " >
    <i class="fa-solid fa-lock-open fa fa-lg" *ngIf="allow_to_change"></i>
    <i class="fa-solid fa-lock fa fa-lg" *ngIf=" !allow_to_change"></i>
    </button>


  </div>
</div>

<app-code [code]="codeBtnLock"></app-code>


<h3>Tabs</h3>
<div class="example-button-row">
  <mat-tab-group>
    <mat-tab label="Exemple 1">
      <div class="example-large-box mat-elevation-z4">
        Content 1
      </div>
    </mat-tab>
    <mat-tab label="Exemple 2">
      <div class="example-small-box mat-elevation-z4">
        Content 2
      </div>
    </mat-tab>
    <mat-tab label="Exemple 3"> Content 3 </mat-tab>
  </mat-tab-group>
</div>

<app-code [code]="codebttats"></app-code>



<h3> Add Buttons</h3>
<div class="example-button-row row">
  <div class="col-3">
    <button  type="button" class="btn-dt-ajouter">
    <i class="fa-solid fa-circle-plus fa fa-lg"></i>
    
      Ajouter
    </button>
  </div>
  <div class="col-3">
    <button  type="button" class="add-btn-em">
      <img class="fa-icon-add" src="assets/icons/add-icon.svg" alt="addIcon">&nbsp;
      Ajouter un Employé
    </button>
  </div>
  <div class="col-3">
    <button  type="button" class="add-btn-en">
      <span class="add-bg-btn">
        <i class="fa-solid fa-plus"></i> 
      </span>
    </button>
  </div>
  <div class="col-3">
    <button  type="button" class="btn-dt-fermer">
      <i class="fa-solid fa-circle-xmark fa  mr-1  fa-lg"></i>
        Fermer
      </button>
  </div>
  <div class="col-3">
    <button type="button"  class="add-btn-em">
      <img class="fa-icon-add" src="assets/icons/add-icon.svg" alt="addIcon">&nbsp;
      <span>Ajouter un objet</span>

    </button>
  </div>

</div>
<app-code [code]="codeAddBtn"></app-code>

<h3>Ajouter dossier/ Document/ Ticket/ Tâche</h3>
<div class="row">
  <div class="col-3">
    <button type="button" class="btn-document-add" data-title="Ajouter une tâche">
      <!-- add to class 'btn-details-add' => postion top-right -->
      <i class="fa-solid fa-plus fa fa-lg"></i>
    </button>
  </div>

  <div class="col-3">
    <button type="button" class="btn-document-add" data-title="Envouyer un dossier">
      <!-- add to class 'btn-details-add' => postion top-right -->
       <i class="fa-solid fa-paper-plane fa fa-lg"></i>
     
    </button>
  </div>
</div>
<app-code [code]="addFolderDocument"></app-code>

<br>
<br>

<h3>Search Buttons</h3>
<div class="example-button-row row">
  <div class="col-3">
    <button  type="button" class="add-btn-search">
      <i class="fa-solid fa-magnifying-glass fa fa-lg fa-flip-horizontal"></i>&nbsp;&nbsp;
      Rechercher par&nbsp;&nbsp;
      <i class="fa-solid fa-caret-down fa fa-lg"></i>
      
    </button>
  </div>
  <div class="col-3">
    <form class="example">
      <input type="text"  placeholder="Search.." name="search2">
      <button type="submit"><i class="fa fa-search"></i></button>
    </form>
  </div>
  <div class="col-3 text-right">
 <input class="search" name="" placeholder="{{'languages.miniListing.search' | translate}}">
<i aria-hidden="true" class="fa-solid fa-magnifying-glass icon-search fa-md"></i>

  </div>
  <div class="filter-bubble">
    <div class="row">
      <div class="col">
        <div class="filter-bubble-icon">
          
          <i class="fa-solid fa-magnifying-glass"></i>
        </div>
      </div>
    </div>
  </div>
<div class="col-3" ></div>
  <button 
            type="button"
               class="add-btn-searchOpp" >
             
               <i aria-hidden="true" class="fa-solid fa-magnifying-glass fa fa-lg fa-flip-horizontal"></i>
               Rechercher par&nbsp;&nbsp;

           </button>

</div>

<app-code [code]="codeSearchBtn"></app-code>
