import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
 
import { Gamme } from 'src/app/entity/Generic/Gamme';
import { OrganismeListe } from 'src/app/entity/OrganismeListe';
import { ApiOpportunitService } from 'src/app/services/ApiOpportunite/api-opportunite.service';
import { ApiOrganismeService } from 'src/app/services/ApiOrganisme/api-organisme.service';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import swal from 'sweetalert2';
import { ApiCampagniesService } from 'src/app/services/ApiCompagnies/api-compagnies.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
export class Convention {
  background: string = "#D6D6D6";
  color: string = "#24242";
  creation_date: any;
  id: any = "";
  id_org_distrib: any = "";
  id_org_fourn: any = "";
  last_update: any;
  libelle: string = "Brouilliant";
  nom: string = "Nom Convention";
  start_date: any;
  status: number = 3;
  valid_unitl: any;
  version: string = "";
  nom_organisme_distib: string = "";
  nom_organisme_fourn : string = "";
  gammes: any = [];
  rule: number = 1;
  convention_prec = "";
  details: any[] = [];
}
@Component({
  selector: 'app-fiche-convention',
  templateUrl: './fiche-convention.component.html',
  styleUrls: ['./fiche-convention.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FicheConventionComponent implements OnInit {
   

  listConventionAsc: Convention[] = []

  changedetect = false;
  modeAjoutConvention: boolean;
  updatedListeConvention: Convention[] = []
  newConventionListe: Convention[] = []


  isExpanded: boolean = false;
  isExpandDes: boolean = false;
  @ViewChild('myForm') myForm;

  indexDes: number;
  newConventionListeAsc: Convention[] = []
  pushListconventions: Convention[] = []
  id_fourn: string = "";


  limit: number = 7;
  page = 1;
  totalPages: number;

  limit_asc: number = 7;
  page_asc = 1;
  totalPages_asc: number;

  newConvention: Convention = new Convention();
  updatedConvention: Convention = new Convention();


  showloader: boolean = false;
  showloaderModif: boolean = false;

  allGammes: Gamme[] = [];
  allOrganisme: OrganismeListe[] = [];
  idOrg : string ="";
  nomOrg : string ="" ;

  loaderDesc : Boolean = true;
  loaderAsc : Boolean = true;
  disabledButton : boolean = false;
  ConventionStatusFerme = environment.ConventionStatusFerme;


  IndexVersion: number = null;
  constructor(private apiOrgaismeServices: ApiOrganismeService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
    private routes: Router,
    private readonly sharedMenuObserverService: SharedMenuObserverService,
    private readonly apiOpportunitService: ApiOpportunitService,
    private ApiCampagniesService: ApiCampagniesService,
   ) { }


  ngOnInit(): void {
    this.sharedMenuObserverService.updateMenu('Fiche des Conventions');
    this.route.paramMap.subscribe((params) => {
      if (params.get('organisme_id') !== 'nouveau') {

      this.id_fourn = params.get('organisme_id')
      this.ApiCampagniesService.getAllOrganismes(this.id_fourn).subscribe((Response: OrganismeListe[]) => {
        this.allOrganisme = Response;
      });
      this.apiOpportunitService.getGammeAutorise(this.id_fourn).subscribe((Response: any) => {
        this.allGammes = Response.gammes;
      });
      this.apiOrgaismeServices.GetOrganismeById(this.id_fourn).subscribe((data)=>{
       this.idOrg = data.real_id;
        this.nomOrg =data.nom;
      })

      this.apiOrgaismeServices.getConvenions(this.id_fourn, 'DESC', this.limit, this.page).subscribe((data) => {
        this.pushListconventions = data.conventions;
        this.calculate_nb_pages(data.total)
        this.loaderDesc = false;
      });
      this.apiOrgaismeServices.getConvenions(this.id_fourn, 'ASC', this.limit_asc, this.page_asc).subscribe((data) => {
        this.listConventionAsc = data.conventions;
        this.loaderAsc= false;
        this.calculate_nb_pages_asc(data.total);
      })
    }
    });


  }

  duplicateConvention(item, index) {
    this.modeAjoutConvention = true;
    this.changedetect = true;
    this.isExpanded = false;
    let copy: Convention = new Convention()
    copy = item
    const newObject = JSON.parse(JSON.stringify(copy));
    newObject.status = 3;
    newObject.libelle = "Brouilliant"
    newObject.background = "#D6D6D6";
    newObject.color = "#24242";
    newObject.rule = 1;
    newObject.id = "";
    newObject.details = [];
    this.pushListconventions.push(newObject)
    this.newConventionListe.push(newObject)
  }

  newVersion(item, index) {
    let copy: Convention = new Convention()
    copy = item;
    const newObject = JSON.parse(JSON.stringify(copy));
    newObject.status = 3;
    newObject.libelle = "Brouilliant"
    newObject.background = "#D6D6D6";
    newObject.color = "#24242";
    newObject.convention_prec = item.id;
    newObject.rule = 1;
    newObject.id = "";
    newObject.version = this.pushListconventions[index].details.length + 2;
    this.pushListconventions[index].details.push(newObject);
  }


  DuplicateVersion(item, index) {
    let copy: Convention = new Convention()
    copy = item;
    const newObject = JSON.parse(JSON.stringify(copy));
    newObject.status = 3;
    newObject.libelle = "Brouilliant"
    newObject.background = "#D6D6D6";
    newObject.color = "#24242";
    newObject.convention_prec = item.convention_prec;
    newObject.rule = 1;
    newObject.id = "";
    newObject.version = this.pushListconventions[index].details.length + 2;
    this.pushListconventions[index].details.push(newObject)
  }



  expandFileName(name: string): string {
    if (name.length < 15) {
      return name;
    } else {
      return name.substring(0, 14) + '...';
    }
  }


  first() {
    this.setPage(1);
  }

  prev() {
    this.setPage(Math.max(1, this.page - 1));
  }

  next() {
    this.setPage(Math.min(this.totalPages, this.page + 1));
  }

  last() {
    this.setPage(this.totalPages);
  }

  calculate_nb_pages(iTotalDisplayRecords) {
    this.totalPages = Math.floor(iTotalDisplayRecords / this.limit);
    if (iTotalDisplayRecords % this.limit !== 0) {
      this.totalPages += 1;
    }
  }

  setPage(val: number) {
    this.page = val;
    this.pushListconventions= [];
    this.loaderDesc = true;
    this.apiOrgaismeServices.getConvenions(this.id_fourn, 'DESC', this.limit, this.page).subscribe((data) => {
      this.pushListconventions = data.conventions;
      this.calculate_nb_pages(data.total);
      this.loaderDesc = false;

    });

  }

  isFirst(): boolean {
    return this.page === 1;
  }

  isLast(): boolean {
    return this.page === this.totalPages;
  }

  changepage(number: number) {
    this.page = number;
    this.pushListconventions= [];
    this.loaderDesc = true;
    this.apiOrgaismeServices.getConvenions(this.id_fourn, 'DESC', this.limit, this.page).subscribe((data) => {
      this.pushListconventions = data.conventions;
      this.loaderDesc = false;
      this.calculate_nb_pages(data.total)
    });
  }

  first_asc() {
    this.setPage_asc(1);
  }

  prev_asc() {
    this.setPage_asc(Math.max(1, this.page_asc - 1));
  }

  next_asc() {
    this.setPage_asc(Math.min(this.totalPages_asc, this.page_asc + 1));
  }

  last_asc() {
    this.setPage_asc(this.totalPages_asc);
  }

  calculate_nb_pages_asc(iTotalDisplayRecords) {
    this.totalPages_asc = Math.floor(iTotalDisplayRecords / this.limit_asc);
    if (iTotalDisplayRecords % this.limit_asc !== 0) {
      this.totalPages_asc += 1;
    }
  }

  setPage_asc(val: number) {
    this.page_asc = val;
    this.loaderAsc = true;
    this.listConventionAsc = [];
    this.apiOrgaismeServices.getConvenions(this.id_fourn, 'ASC', this.limit_asc, this.page_asc).subscribe((data) => {
      this.listConventionAsc = data.conventions;
      this.calculate_nb_pages_asc(data.total);
      this.loaderAsc = false;

    });
  }

  isFirst_asc(): boolean {
    return this.page_asc === 1;
  }

  isLast_asc(): boolean {
    return this.page_asc === this.totalPages_asc;
  }

  changepage_asc(number: number) {
    this.page_asc = number;
    this.loaderAsc = true;
    this.listConventionAsc = [];
    this.apiOrgaismeServices.getConvenions(this.id_fourn, 'ASC', this.limit_asc, this.page_asc).subscribe((data) => {
      this.listConventionAsc = data.conventions;
      this.calculate_nb_pages_asc(data.total)
      this.loaderAsc = false;

    });
  }

  pushListconvention() {
    this.disabledButton = true;
    let convention = new Convention();
    convention.background = "#D6D6D6";
    convention.color = "#24242";
    convention.creation_date = new Date();
    convention.id = "";
    convention.id_org_distrib = "";
    convention.id_org_fourn = "";
    convention.last_update = new Date();
    convention.libelle = "Brouilliant";
    convention.nom = "Nom Convention";
    convention.start_date = new Date();
    convention.status = 3;
    convention.valid_unitl = new Date();
    convention.version = "";
    convention.nom_organisme_distib = "";
    convention.gammes = [];
    convention.rule = 1;
    this.pushListconventions.push(convention)
    this.newConventionListe.push(convention)
    this.indexDes = this.pushListconventions.length - 1
    if (this.isExpandDes === false) {
      this.isExpandDes = true;
      const box = document.getElementById('expandDes');
      box.classList.remove('hide');
      box.classList.add('add_panel');

    }
  }
  resetForm() {
    this.ngOnInit();
    this.disabledButton = false;
    if (this.isExpandDes === true) {
      this.isExpandDes = false;
      const box = document.getElementById('expandDes');
      box.classList.remove('hide');
      box.classList.add('add_panel');
    }
  }
  status : number ;
  getDataConvention(event) {
    this.modeAjoutConvention = event.modeAjout
    if (event.modeAjout) {
      this.newConvention = event.convention;
      this.newConvention.rule = 1;
      this.newConvention.id_org_fourn = this.id_fourn;
    } else {

      this.updatedConvention = event.convention;
      this.status = event.newIdStatus;
      this.updatedConvention.rule = 1;
    }

  }

  getDataVersion(event){

  }

  createConvention() {
    this.disabledButton = false;
    this.showloader = true;
    this.apiOrgaismeServices.CreateConvention(this.newConvention).subscribe((response) => {
      this.alertSuccess(response);
      this.showloader = false;
    }, (error) => {
      this.alertError(error.error.message);
      this.showloader = false;
    });
  }

  updateConvention() {
    if (this.status !== undefined) this.updatedConvention.status = this.status;
    this.apiOrgaismeServices.updateConvention(this.updatedConvention, this.updatedConvention.id).subscribe((response) => {
      this.alertSuccess(response)
    }, (error) => {
      this.alertError(error.error.message)
    })

  }

  initForm() {
    this.apiOrgaismeServices.getConvenions(this.id_fourn, 'DESC', this.limit, this.page).subscribe((data) => {
      this.pushListconventions = data.conventions;
      this.calculate_nb_pages(data.total)
    });
  }

  enregistrer(index, key, indexV) {
    let conv = new Convention();
    if (key == 'version') {
      conv = this.pushListconventions[index].details[indexV]
    } else {
      conv = this.pushListconventions[index]
    }
    if (this.modeAjoutConvention) {
      this.showloader = true;
      this.apiOrgaismeServices.CreateConvention(conv).subscribe((response) => {
        this.alertSuccess(response);
        this.showloader = false;
      }, (error) => {
        this.alertError(error.error.message)
        this.showloader = false;
      });
    } else {
      this.showloaderModif = true;
     if (this.status !== undefined) conv.status = this.status;
      this.apiOrgaismeServices.updateConvention(conv, conv.id).subscribe((response) => {
        this.alertSuccess(response);
        this.showloaderModif = false;
      }, (error) => {
        this.alertError(error.error.message);
        this.showloaderModif = false;

      })
    }
  }


  alertSuccess(data) {
    swal
      .fire({
        title: 'Opération Réussie',
        text: data.message,
        icon: 'success',
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonColor: '#68a45b',
        focusConfirm: true,
        showCancelButton: true,
        cancelButtonText: 'Annuler',
        confirmButtonText: 'Liste des organismes',
      }).then((result) => {
        this.loaderDesc = true;
        this.pushListconventions= [];
        this.apiOrgaismeServices.getConvenions(this.id_fourn, 'DESC', this.limit, this.page).subscribe((data) => {
          this.pushListconventions = data.conventions;
          this.calculate_nb_pages(data.total)
          this.loaderDesc = false;
        });
        if (this.isExpandDes === true) {
          this.isExpandDes = false;
          const box = document.getElementById('expandDes');
          box.classList.remove('hide');
          box.classList.add('add_panel');
        }
      })

  }


  alertError(data) {
    swal.fire({
      title: data,
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#d53a3a',
    })
      ;
  }

  goToOrganisme() {
    this.routes.navigate(['/gestion-organisme/fiche/' + this.id_fourn])
  }

  listeOrganisme(id) {
    this.ApiCampagniesService.getAllOrganismes(id).subscribe((Response: OrganismeListe[]) => {
      this.allOrganisme = Response;
    });
  }

  panneauOuvertIndex: number = null;
  ouvrirPanneau(index: number) {
    this.panneauOuvertIndex = index;
    this.cdr.detectChanges();
  }

  fermerPanneau() {
    this.panneauOuvertIndex = -1;
    this.cdr.detectChanges();
  }


  ouvrirPanneauVersion(index: number) {
      this.IndexVersion = index;

  }

  fermerPanneauVersion() {
      this.IndexVersion = -1;
  }


  IndexAsc: number = null;
  ouvrirPanneauAsc(index: number) {
    this.IndexAsc = index;
  }

  fermerPanneauAsc() {
    this.IndexAsc = -1;
  }

  IndexAscVersion: number = null;
  ouvrirPanneauAscV(index: number) {
    this.IndexAscVersion = index;

  }

  fermerPanneauAscV() {
    this.IndexAscVersion = -1;
  }
}
