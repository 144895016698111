import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminLayoutRoutingModule } from './admin-layout-routing.module';
import { AdminLayoutComponent } from './admin-layout.component';
import { OpportunitiesComponent } from '../pages/opportunities/list-opportunite/opportunities.component';
import { DashboardComponent } from '../pages/dashboard/dashboard.component';
import { AffairesComponent } from '../pages/affaires/affaire-list/affaires.component';
import { CalendarComponent } from '../pages/calendar/calendar.component';
import { MailevaComponent } from '../pages/maileva/list-maileva/maileva.component';
import { DriveComponent } from '../pages/drive/drive.component';
import { ComparatorComponent } from '../pages/comparator/comparator.component';
import { NavbarComponent } from '../components/navbar/navbar.component';
import { SidebarComponent } from '../components/sidebar/sidebar.component';
import { SharedModule } from '../shared/shared.module';
import { ChartsModule } from 'ng2-charts';
import { SearchPipe } from '../components/pipes/search.pipe';
import { ProfileComponent } from '../pages/profile/profile/profile.component';
import { GitlabIssueBulleComponent } from '../components/gitlab-issue-bulle/gitlab-issue-bulle.component';
import { WebmailComponent } from '../pages/webmail/webmail.component';
import { TicketsDetailsComponent } from '../pages/tickets-and-support/details-tickets/tickets-details.component';
import { TicketsDetailsModule } from '../pages/tickets-and-support/details-tickets/tickets-details.module';
import { OpportunitiesDetailsModule } from '../pages/opportunities/details-opportunite/opportunities-details.module';
import { MailevaDetailsModule } from '../pages/maileva/details-maileva/maileva-details.module';

import { GestionOrganismeModule } from '../pages/parametres/gestion-organisme/gestion-organisme.module';
import { FiltresOpportuniteModule } from '../pages/opportunities/list-opportunite/filters/filtres-opportunite.module';
import { FilterComponent } from '../pages/opportunities/list-opportunite/filters/filter/filter.component';

import { FiltresAffaireModule } from '../pages/affaires/affaire-list/filters/filtres-affaire.module';
import { FilterAffaireGlobalComponent } from '../pages/affaires/affaire-list/filters/filter-affaire-global/filter-affaire-global.component';

import { FiltresMailevaModule } from '../pages/maileva/list-maileva/filters/filters-maileva.module';
import { FilterMailvaComponent } from '../pages/maileva/list-maileva/filters/filter/filter.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AddNewMailevaComponent } from '../pages/maileva/add-new-maileva/add-new-maileva.component';
import { AddNewTicketsComponent } from '../pages/tickets-and-support/add-tickets/add-new-tickets.component';
import { HistoriqueTransactionComponent } from '../pages/historique-transaction/historique-transaction.component';
import { TarificateurVeloComponent } from '../pages/tarificateur-velo/tarificateur-velo.component';
import { AddOpportuniteTarificateurDialogComponent } from '../pages/tarificateur-velo/_dialog/add-opportunite-tarificateur-dialog-component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ListFavorisComponent } from '../components/list-favoris/list-favoris.component';
@NgModule({
  declarations: [
    AdminLayoutComponent,
    NavbarComponent,
    DashboardComponent,
    AffairesComponent,
    CalendarComponent,
    MailevaComponent,
    DriveComponent,
    ComparatorComponent,
    OpportunitiesComponent,
    SidebarComponent,
    GitlabIssueBulleComponent,
    WebmailComponent,
    TarificateurVeloComponent,
    AddOpportuniteTarificateurDialogComponent,
    ProfileComponent,
    TicketsDetailsComponent,
    SearchPipe,
    // AddTicketsComponent,
    FilterComponent,
    FilterMailvaComponent,
    FilterAffaireGlobalComponent,
    FilterAffaireGlobalComponent,
    AddNewMailevaComponent,
    AddNewTicketsComponent,
    HistoriqueTransactionComponent,
    ListFavorisComponent,
  ],
  exports: [AddOpportuniteTarificateurDialogComponent],
  imports: [
    CommonModule,
    AdminLayoutRoutingModule,
    SharedModule,
    ChartsModule,
    TicketsDetailsModule,
    OpportunitiesDetailsModule,
    OpportunitiesDetailsModule,
    MailevaDetailsModule,
    NgbModule,
    GestionOrganismeModule,
    FiltresOpportuniteModule,
    FiltresMailevaModule,
    FiltresAffaireModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatStepperModule,
    MatButtonModule,
    MatIconModule,
  ],
})
export class AdminLayoutModule {}
