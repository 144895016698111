import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Configuration } from '../configuration';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { BASE_PATH } from '../variables';

@Injectable({
  providedIn: 'root'
})
export class EditingMachineService {

  protected basePath = environment.api_url;
  protected PathAdresses = environment.PathAdresses;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  public GetKeys(observe: any = 'body', reportProgress: boolean = false): Observable<any> {



    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/editing_machine/get_keys?`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
  /**
*  traitement de modification de statut
* @param idopp
* @param idstatut
* @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
* @param reportProgress flag to report request and response progress.
*/
public editingAction(idopp: string, idstatut: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

  let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
  let headers = this.defaultHeaders;

  // authentication (Acess_Token) required
  if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
    headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
  }

  // to determine the Accept header
  let httpHeaderAccepts: string[] = [];
  const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
  if (httpHeaderAcceptSelected != undefined) {
    headers = headers.set('Accept', httpHeaderAcceptSelected);
  }


  const consumes: string[] = [];

  return this.httpClient.request<any>('get', `${this.basePath}/editing_machine/action/${idopp}/${idstatut}`,
    {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    }
  );
}




}
