
<!DOCTYPE html>
<html lang="en" xml:lang="en">
<head>
  <meta charset="utf-8"/>
  <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>template</title>
</head>
<body>
<div class="container">
  <div class="row">
    <div class="col-4">
      <div class="form-group">
        <mat-form-field>
          <mat-label>Input Text</mat-label>
          <input matInput type="text">
        </mat-form-field>
      </div>
    </div>
    <div class="col-4">
      <mat-form-field class="input-width">
        <mat-icon matPrefix [ngStyle]="{'color': colorCtr.value}">circle</mat-icon>
        <input matInput [ngxMatColorPicker]="picker" [formControl]="colorCtr" [disabled]="disabled" [ngStyle]="{'color': colorCtr.value}" >
        <ngx-mat-color-toggle matSuffix [for]="picker" style="font-size: 20px;" ></ngx-mat-color-toggle>
        <ngx-mat-color-picker #picker [touchUi]="touchUi" [color]="color"></ngx-mat-color-picker>
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field  class="input-width" >
        <mat-label>Date</mat-label>
        <input matInput [matDatepicker]="basicDatepicker">
        <mat-datepicker-toggle matSuffix [for]="basicDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #basicDatepicker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field  class="input-width">
        <mat-label>Select Option</mat-label>
        <mat-select>
          <mat-option *ngFor="let v of values" [value]="v.value">
            {{v.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
<app-code [code]="codeBasicInput"></app-code>
<br><br><br>
<div class="row text-center" >
  <div class="col">
    <label class="radio">
      <input type="radio" class="input-radio" name="foobar">
      Radio 1
    </label>
  </div>
  <div class="col">
    <label class="radio">
      <input type="radio" class="input-radio" name="foobar" checked>
      Radio 2
    </label>
  </div>
</div>

<div class="container">
  <div class="row container-switch" >
    <div class="col">
      <div class="Liblle">
        <span>Exemple 1.1</span>
      </div>
      <label class="switch space-switch" >
        <input type="checkbox">
        <span class="slider round"></span>
      </label>
    </div>
    <div class="col">
      <div class="Liblle">
        <span>Exemple 1.2</span>
      </div>
      <label class="switch space-switch">
        <input type="checkbox" checked>
        <span class="slider round"></span>
      </label>
    </div>
  </div>
  <br>

  <div class="row container-switch" >
    <div class="col">
      <div class="Liblle-switch">
        <span>Exemple 2.1</span>
      </div>
      <label class="switch-m space-switch" >
        <input type="checkbox">
        <span class="slider-m round"></span>
      </label>
    </div>
    <div class="col">
      <div class="Liblle-switch">
        <span>Exemple 2.2</span>
      </div>
      <label class="switch-m space-switch">
        <input type="checkbox" checked>
        <span class="slider-m round"></span>
      </label>
    </div>
  </div>

  
</div>
<br>
<app-code [code]="codeSwitchToggle"></app-code>
<br>
<app-code [code]="codeDate_Select_Inputs"></app-code>
<div class="row">
  <div class="col-md-6">
    <div class="row">
      <div class="col-2 pt-1 px-3 border-right-0 border border-primary" >
        <i class="fa-light fa-image fa fa-2x pt-3 pl-0"></i>
        
      </div>
      <div class="col-8 pt-2 px-3 border-right-0 border-left-0 border border-primary" >
        <input type="file" id="idlogo" (change)="getLogo($event,'favicon')" hidden />
        <label for="idlogo" class="select-label py-3 pl-2">
          Sélectionner favicon
        </label> 
      </div>
      <div class="col-2 pt-3 pl-3 border-left-0 border border-primary" >
        <img class="img" data-toggle="modal" alt="img"  data-target="#Favicon" src="{{show_favicon}}">
      </div>
    </div>
  </div>
</div>



<app-code [code]="codeSelect_logo"></app-code>

</body>
</html>
