import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { FilterHistoryService } from 'src/app/services/ApiFilterSave/filter-history.service';
import { NotificationMessage, NotificationType } from 'src/app/entity/NotificationMessage';
import { NotificationsService } from 'src/app/shared/NotificationsService/notifications.service';
import { Collection, ListCollection } from 'src/app/entity/filterSave';
import swal from 'sweetalert2';
import * as internal from 'events';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith, pluck, tap } from 'rxjs/operators';
import { FormControl, Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { tree } from 'src/app/entity/TreeView';
import { TranslateService } from '@ngx-translate/core';

export interface CardItem {
  title: string;
  subTitle: string;
  subSubTitle: string;
  checked: boolean;
  id: number;
}
@Component({
  selector: 'app-save-filtre',
  templateUrl: './save-filtre.component.html',
  styleUrls: ['./save-filtre.component.css'],
})
export class SaveFiltreComponent implements OnInit {
  @Input() filterOpportunite;
  @Input() entity;
   
 
  checkedcollectionId: number = null;
  Libellefiltre: string = '';
  LibelleCollection: string = '';
  collection: string = '';
  search: string = '';
  checkAddCollection: boolean = false;
  isClickEnabled: boolean = true;
  isDropdownVisible = true;
  sSearch: string;
  listCollection: ListCollection;
  listCollectionSelected: Collection[] = [];
  errrorFilterLibelle: boolean = false;
  errrorCollectionLibelleA: boolean = false;
  errrorCollectionLibelle: boolean = false;
  showLaoder: boolean = false;
  filteredCollection: Observable<any[]>;
  collectionForm: FormControl = new FormControl(null, []);
  separatorKeysCodes: number[] = [ENTER, COMMA];
  removable = true;
  isAutocompleteReadOnly: boolean = false;
  isSaveDisabled: boolean = false;
  @ViewChild('collInput') collInput: ElementRef<HTMLInputElement>;
  buttons: any;
  alerts: any;
  constructor(
    private apiFIlterHistoryService: FilterHistoryService,
    private readonly notificationsService: NotificationsService,
    private translate : TranslateService
  ) {}

  ngOnInit(): void {
    this.showLaoder = true;
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
      });});

      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
      });

    this.getListCollection(this.entity, this.sSearch);
    this.filteredCollection = this.collectionForm.valueChanges.pipe(
      startWith(null),
      map((collection: string | null) => {
        return collection ? this._filterCollection() : this.listCollection?.collections.slice();
      })
    );
  }

  private _filterCollection(): any[] {
    let filterValue = this.collectionForm.value.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.listCollection?.collections.filter((collection) =>
      collection.libelle != null ? collection.libelle.toLowerCase().includes(filterValue.toLowerCase()) : false
    );
  }

  onSelectCollection($event: MatAutocompleteSelectedEvent) {
    if (!this.listCollectionSelected?.includes($event.option.value)) {
      this.listCollectionSelected = [];
      this.listCollectionSelected.push($event.option.value);
      this.errrorCollectionLibelle = false;
    }
    if (this.listCollectionSelected.length > 0) {
      this.isClickEnabled = false;
      this.errrorCollectionLibelleA = false;
      this.isAutocompleteReadOnly = true;
    } else {
      this.isClickEnabled = true;
      this.isAutocompleteReadOnly = false;
    }
    // this.isAutocompleteReadOnly = this.listCollectionSelected.length === 1;
    const i = this.listCollection.collections.indexOf($event.option.value);
    this.listCollection.collections[i].checked = !this.listCollection.collections[i].checked;
    for (let j = 0; j < this.listCollection.collections.length; j++) {
      if (j !== i) {
        this.listCollection.collections[j].checked = false;
      }
    }

    if (this.listCollection.collections[i].checked === true) {
      this.checkedcollectionId = this.listCollection.collections[i].id;
      // this.isClickEnabled = false;
      this.checkAddCollection = false;
    } else {
      // this.isClickEnabled = true;
      this.checkAddCollection = false;
      this.checkedcollectionId = null;
    }
    this.isDropdownVisible = true;
    this.collInput.nativeElement.value = '';
    this.collectionForm.setValue(null);
  }

  public displayCollFn(collection?: any): string | undefined {
    this.collectionForm = collection?.id;
    return collection ? collection.libelle : null;
  }

  stopClickPropagation(event: Event) {
    this.collectionForm.setValue('');
    event.stopPropagation();
  }

  showhidePop() {
    this.isDropdownVisible = !this.isDropdownVisible;
    this.getListCollection(this.entity, this.sSearch);
  }

  showhidePopfirst() {
    this.getListCollection(this.entity, this.sSearch);
    if (this.isDropdownVisible === false) {
      this.isDropdownVisible = true;
    }
  }

  addNewCollection() {
    this.checkAddCollection = !this.checkAddCollection;
    this.errrorCollectionLibelleA = false;
    this.errrorCollectionLibelle = false;
  }

  AddFilterCollection() {
    if (!this.checkAddCollection && this.listCollectionSelected.length === 0) {
      this.errrorCollectionLibelleA = true;
    }
    if ((this.LibelleCollection === '' || this.LibelleCollection === null) && this.checkedcollectionId === null) {
      // this.checkAddCollection = true;
      this.errrorCollectionLibelle = true;
      if (this.Libellefiltre === '') {
        this.errrorFilterLibelle = true;
      }
    } else {
      if (this.LibelleCollection !== '' && this.LibelleCollection !== null && this.checkedcollectionId === null) {
        this.isSaveDisabled = true;
        const unsubscribeFilterSave = this.apiFIlterHistoryService
          .addFilterCollection(this.entity, this.LibelleCollection)
          .subscribe(
            (response) => {
              this.checkedcollectionId = response.id;
              this.AddFilter(this.checkedcollectionId, JSON.stringify(this.filterOpportunite));
            },
            (error) => {
              const notificationMessage = new NotificationMessage();
              notificationMessage.type = NotificationType.error;
              notificationMessage.title = this.alerts.Operationechoue;
              notificationMessage.message = error.error.message;
              this.notificationsService.sendMessage(notificationMessage);
              this.errrorCollectionLibelleA = false;
              this.errrorCollectionLibelle = false;
              this.checkAddCollection = false;
              this.isAutocompleteReadOnly = false;
            }
          );
      } else {
        this.AddFilter(this.checkedcollectionId, JSON.stringify(this.filterOpportunite));
      }
    }
  }

  AddFilter(collectionId: number, jsonFilter: String) {
    if (this.Libellefiltre !== '' && this.Libellefiltre !== null) {
      const unsubscribeFilterSave = this.apiFIlterHistoryService
        .addFilterHistory(this.entity, this.Libellefiltre, collectionId, jsonFilter)
        .subscribe(
          (response) => {
            const notificationMessage = new NotificationMessage();
            notificationMessage.type = NotificationType.success;
            notificationMessage.title = this.alerts.Successoperation;
            notificationMessage.message = response.message.toString();
            this.notificationsService.sendMessage(notificationMessage);
            this.LibelleCollection = '';
            this.Libellefiltre = '';
            this.listCollectionSelected = [];
            this.checkedcollectionId = null;
            this.errrorCollectionLibelle = false;
            this.errrorCollectionLibelleA = false;
            this.checkAddCollection = false;
            this.errrorFilterLibelle = false;
            this.isDropdownVisible = false;
            this.isAutocompleteReadOnly = false;
            this.isSaveDisabled = false;
            this.getListCollection(this.entity, this.sSearch);
          },
          (error) => {
            const notificationMessage = new NotificationMessage();
            notificationMessage.type = NotificationType.error;
            notificationMessage.title = this.alerts.Operationechoue;
            notificationMessage.message = error.error.message;
            this.notificationsService.sendMessage(notificationMessage);
            this.isSaveDisabled = false;
          }
        );
    } else {
      this.errrorFilterLibelle = true;
    }
  }

  getListCollection(entity, sSearch) {
    const unsubscribeFilterSave = this.apiFIlterHistoryService.getListCollection(entity, sSearch).subscribe(
      (response: ListCollection) => {
        this.showLaoder = false;
        this.listCollection = response;
      },
      (error) => {
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.error;
        notificationMessage.title = this.alerts.Operationechoue;
        notificationMessage.message = error?.error?.message;
        this.notificationsService.sendMessage(notificationMessage);
      }
    );
  }

  expandcollectionName(name: string): string {
    if (name.length < 15) {
      return name;
    } else {
      return name.substring(0, 14) + '...';
    }
  }

  getDataValue(event) {
    if (event.target.value.length > 2 || event.target.value.length === 0) {
      this.sSearch = event.target.value;
      this.getListCollection(this.entity, this.sSearch);
    }
  }

  removeCollection(collection): void {
    const index = this.listCollectionSelected.indexOf(collection);
    if (index >= 0) {
      this.isAutocompleteReadOnly = false;

      this.listCollectionSelected.splice(index, 1);
      this.collectionForm.setValue(null);
      this.errrorCollectionLibelleA = true;
      this.checkedcollectionId = null;
      this.LibelleCollection = '';
    }
    this.isClickEnabled = true;
    this.isAutocompleteReadOnly = this.listCollectionSelected.length === 1;
  }

  detecterror() {
    if (this.Libellefiltre.length === 0) {
      this.errrorFilterLibelle = true;
    } else {
      this.errrorFilterLibelle = false;
    }
  }

  resetAddFilter() {
    this.isDropdownVisible = false;
    this.errrorCollectionLibelleA = false;
    this.errrorFilterLibelle = false;
    this.listCollectionSelected = [];
    this.checkedcollectionId = null;
    this.LibelleCollection = '';
    this.Libellefiltre = '';
    this.isClickEnabled = true;
    this.checkAddCollection = false;
    this.isAutocompleteReadOnly = false;
  }
}
