<div class="d-flex justify-content-end">

    <button class="button" (click)="onClose()">
        <mat-icon>close</mat-icon>
    </button>


</div>

<h3 style="text-align: center;">  {{'languages.convention.changeStatus' | translate}} </h3>
<div class="d-flex justify-content-evenly" id="box">
<mat-spinner  [diameter]="20" *ngIf="showLaoder"></mat-spinner>
</div>
<div class="d-flex justify-content-evenly" id="box">
    <div *ngFor=" let item of statusListe"
     [ngStyle]="{ color: item.color, 'background-color': item.background }"
    (click)="onchangeStatus(item)" (keydown.enter)="onchangeStatus(item)"
      style="margin-block-end: 20px;" class="btn-status w-50 " >
        {{item.libelle}}
    </div>


</div>
