<div class="sidebar" [class.collapsed]="isCollapsed" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
  <div class="sidebar-header">
    <!-- Retain the Sigaf image -->
    <img *ngIf="isCollapsed" (keydown.enter)="toggleSidebar()" (click)="toggleSidebar()" class="mr-1" src="assets/img/sigaf/smallLogo.png" style="height:70px" alt="logo" />
    <img *ngIf="!isCollapsed" class="geoprod mr-2" src="https://staging.cdn.sigaf.cloud.geoprod.com/static/uploads/files/logo_sigafMiniVersionwhite.png" alt="logo" style="width: 150px; height: 76px;" />

  </div>
  <div class="toggle-icon" (keydown.enter)="toggleSidebar()"  (click)="toggleSidebar()">
    <i class="fa-solid" [ngClass]="{ 'fa-chevron-left': !isCollapsed }"></i>
  </div>
  <ul class="sidebar-links w-100">
    <ng-template #menuTemplate let-item="menuItem">
      <li [ngClass]="{ 'active': item.isActive }" class="row" (mouseenter)="onHover(item)" (mouseleave)="onHoverOut(item)">
        <a (click)="toggleSubMenu(item)" [routerLink]="item.path.trim()?.toString() !='' ? [item.path.trim()?.toString()] : []" class="row w-100 d-flex justfy-content-center">
          <div class="col-2" [ngClass]="{ 'col-3': isCollapsed  }" ><i style="font-size: 16px;" [class]="item.icon"></i></div>
          <div class="col-9"   *ngIf="!isCollapsed"> <span class="menu-title" >{{ item.title }}</span></div>
          <div class="col-1"  *ngIf="!isCollapsed && item.child.length">
            <i class="fa-solid" [ngClass]="{'fa-chevron-down': item.expanded, 'fa-chevron-right': !item.expanded}"></i>
          </div>
          
          
          
        </a>
        <ul class="w-100" *ngIf="(isCollapsed && item.isHovered) || (!isCollapsed && item.child.length)" [@expandCollapse]="item.expanded ? 'expanded' : 'collapsed'">
          <ng-container *ngFor="let child of item.child">
            <ng-template [ngTemplateOutlet]="menuTemplate" [ngTemplateOutletContext]="{ menuItem: child }"></ng-template>
          </ng-container>
        </ul>
      </li>
    </ng-template>
    <ng-container *ngFor="let item of routes">
      <ng-template [ngTemplateOutlet]="menuTemplate" [ngTemplateOutletContext]="{ menuItem: item }"></ng-template>
    </ng-container>
  </ul>
  <hr class="divider">
  <div class="user-profile" [ngClass]="{'collapsed-profile': isCollapsed}">
    <div class="user-detail row d-flex ">
      <div class="col-2" [ngClass]="{ 'col-3': isCollapsed }"><i class="fa-light fa-user iconSize"></i></div>
      
      <div *ngIf="!isCollapsed" class=" col-10 d-inline-block text-left text-nowrap" id="ifoname">
        <p class="m-0">{{ expandNameUser(capitalizeFirstLetter(nom), capitalizeFirstLetter(prenom)) }}</p>
      </div>
    </div>
  </div>
</div>
