import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Gamme } from 'src/app/entity/Generic/Gamme';
import { OrganismeListe } from 'src/app/entity/OrganismeListe';
import { ApiOpportunitService } from 'src/app/services/ApiOpportunite/api-opportunite.service';
import { ApiCampagniesService } from 'src/app/services/ApiCompagnies/api-compagnies.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { StatusConventionComponent } from '../status-convention/status-convention.component';
import { Convention } from 'src/app/pages/parametres/gestion-organisme/fiche-convention/fiche-convention.component';
 
import { environment } from 'src/environments/environment';
import { exists } from 'fs';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-form-convention',
  templateUrl: './form-convention.component.html',
  styleUrls: ['./form-convention.component.css'],

})
export class FormConventionComponent implements OnInit, OnChanges {
  /** organisme */

  ListOrganisme: string[] = [];
  allOrganisme: OrganismeListe[] = [];
  listOrganisme: OrganismeListe[] = [];
  organismeFromCtrl: FormControl = new FormControl('');
  @ViewChild('OrganismeInput') OrganismeInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoOrganisme') matAutocompleteOrganisme: MatAutocomplete;
  filteredOrganisme: Observable<OrganismeListe[]>;

   
  gammeFromCtrl: FormControl = new FormControl('');
  filteredGammes: Observable<Gamme[]>;
  ListGammes: string[] = [];
  allGammes: Gamme[] = [];
  gammeSelected: any[] = [];
  @ViewChild('gammeInput') gammeInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoGamme') matAutocompleteGamme: MatAutocomplete;
  selectableGamme = true;
  removableGamme = true;
  datedebut: any;
  datefin: any;
  datedeffet: any;
  nom: any;
  fournisseur: any;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  @Input() conventionData;
  @Input() index;
  @Output() onPushConvention: EventEmitter<any> = new EventEmitter();
  @Output() onPushConventionAsc: EventEmitter<any> = new EventEmitter();
  @Input() type: any
  @Input() organisme_id: any;
  @Input() mode: any;
  @Input() disabledName : boolean;
  @Input() allOrganismeList : OrganismeListe[] = [];
  @Input() allGammesList : Gamme[] = [];
  @Input() color ;
  dialogRef: MatDialogRef<StatusConventionComponent, any>;
  appearModalStatus = false;
  readonlyInput = false;
  modeAjout = false;
  loaderOrganisme = false;
  readOnlyAscConvention = false;
  ConventionStatusActive = environment.ConventionStatusActive;
  ConventionStatusFerme = environment.ConventionStatusFerme;
  constructor(private readonly apiOpportunitService: ApiOpportunitService,
    private ApiCampagniesService: ApiCampagniesService,
    private dialog: MatDialog,

  ) { }
  ngOnChanges(changes: SimpleChanges): void {
  if (changes.allGammesList) this.allGammes =this.allGammesList
  if (changes.allOrganismeList){
    this.loaderOrganisme = true;
    this.allOrganisme =this.allOrganismeList;
  }
    if (this.conventionData.gammes.length > 0) {
      this.gammeSelected = this.conventionData.gammes;
      this.ListGammes = [];
      for (let i of this.conventionData.gammes) {
        this.ListGammes.push(i.nom);
      }
    }
    if (this.conventionData.id_org_distrib) {
      let org: any = {};
      org.real_id = this.conventionData.id_org_distrib
      org.nom = this.conventionData.nom_organisme_distib
      this.organismeFromCtrl.setValue(org);
    }
    if (this.conventionData.id !== "") {
      this.modeAjout = false;
      this.appearModalStatus = true;
    } else {
      this.modeAjout = true;
      this.appearModalStatus = false;
    }

    this.filteredGammes = this.gammeFromCtrl.valueChanges.pipe(
      startWith(null),
      map((gamme: string | null) => (gamme ? this._filterGammes() : this.allGammes.slice()))
    )

    this.filteredOrganisme = this.organismeFromCtrl.valueChanges.pipe(
      startWith(null),
      map((organisme: string | null) => (organisme ? this._filterOrganisme() : this.allOrganisme.slice()))
    );

    if (this.type == 'des') this.readOnlyAscConvention = true;
    this.emitForm()

  }

  ngOnInit(): void {
    //this.listeOrganisme();
   // this.getGamme();
    if (this.conventionData.gammes.length > 0) {
      this.gammeSelected = this.conventionData.gammes;
      this.ListGammes = [];
      for (let i of this.conventionData.gammes) {
        this.ListGammes.push(i.nom);
      }
    }
    if (this.conventionData.id_org_distrib) {
      let org: any = {};
      org.real_id = this.conventionData.id_org_distrib
      org.nom = this.conventionData.nom_organisme_distib
      this.organismeFromCtrl.setValue(org);
    }
    if (this.conventionData.id !== "") {
      this.modeAjout = false;
      this.appearModalStatus = true;
    } else {
      this.conventionData.valid_unitl.setDate(this.conventionData.valid_unitl.getDate() + 365);
      this.modeAjout = true;
      this.appearModalStatus = false;
    }



    this.filteredGammes = this.gammeFromCtrl.valueChanges.pipe(
      startWith(null),
      map((gamme: string | null) => (gamme ? this._filterGammes() : this.allGammes.slice()))
    )

    this.filteredOrganisme = this.organismeFromCtrl.valueChanges.pipe(
      startWith(null),
      map((organisme: string | null) => (organisme ? this._filterOrganisme() : this.allOrganisme.slice()))
    );

    if (this.type == 'des') this.readOnlyAscConvention = true;


    this.emitForm()
  }

  /** convention*/

  selectedGammes(event: MatAutocompleteSelectedEvent): void {
    let existG = this.ListGammes.some(objct => objct == event.option.viewValue);
    if (!existG) this.ListGammes.push(event.option.viewValue);
    let exist = this.gammeSelected.some(objct => objct.id == event.option.value.id);
    if (!exist) this.gammeSelected.push(event.option.value);
    this.gammeInput.nativeElement.value = '';
    this.gammeFromCtrl.setValue(null);
    this.conventionData.gammes = this.gammeSelected;
    this.emitForm();
  }

  removeGammes(gamme: string): void {
    const index = this.ListGammes.indexOf(gamme);
    if (index >= 0) {
      this.ListGammes.splice(index, 1);
      this.gammeSelected.splice(index, 1);
    }
    this.emitForm();
  }

  private _filterGammes(): Gamme[] {
    let filterValue = this.gammeFromCtrl.value.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.allGammes.filter((gamme) =>
      gamme.nom != null ? gamme.nom.toLowerCase().indexOf(filterValue.toLowerCase()) === 0 : null
    );
  }

/*   getGamme() {
    this.apiOpportunitService.getGammeAutorise(this.organisme_id).subscribe((Response: any) => {
      this.allGammes = Response.gammes;
      this.readonlyInput = true;
    });
  } */

  /** organisme */

/*   listeOrganisme() {
    this.ApiCampagniesService.getAllOrganismes().subscribe((Response: OrganismeListe[]) => {
      this.allOrganisme = Response;
      this.loaderOrganisme = true;
    });
  } */

  selectedOrganisme(event: MatAutocompleteSelectedEvent): void {
    if (event == null){
      this.conventionData.id_org_distrib ="";
    }else{
      this.conventionData.id_org_distrib = event.option.value.id;
    }
    this.readonlyInput = true;
    this.emitForm()
  }
  onInputChanged() {
    const inputValue = this.organismeFromCtrl.value;
    if (!inputValue) {
      this.selectedOrganisme(null);
    }
  }
  private _filterOrganisme(): OrganismeListe[] {
    let filterValue = this.organismeFromCtrl.value.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.allOrganisme.filter((organisme) =>
      organisme.nom != null ? organisme.nom.toLowerCase().indexOf(filterValue.toLowerCase()) === 0 : null
    );
  }

  public displayOrgFn(organisme?: any): string | undefined {
    this.organismeFromCtrl = organisme?.real_id;
    return organisme ? organisme.nom : null;
  }


  emitForm() {
    let data = {
      index: this.index,
      convention: this.conventionData,
      modeAjout: this.modeAjout,

    }
    // if (this.type =='des') {
    this.onPushConvention.emit(data);
    /* }  else {
       this.onPushConventionAsc.emit(data)
     } */


  }

  idStatusConvention: any = ""
  OnOpen() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '25%';
    dialogConfig.data = {
      index: this.index,
      idConv : this.conventionData.id

    };
    this.dialogRef = this.dialog.open(StatusConventionComponent, dialogConfig);
    this.dialogRef.afterClosed().subscribe((data) => {
      let idStatus = data.data.id;
      // this.conventionData.background = data.data.background;
      // this.conventionData.color = data.data.color;
      // this.conventionData.libelle = data.data.libelle;
      let dataC = {
        index: this.index,
        convention: this.conventionData,
        newIdStatus : idStatus ,
        modeAjout: this.modeAjout,
      }
      this.onPushConvention.emit(dataC);
    });
  }


}
