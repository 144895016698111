import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GestionOrganismeRoutingModule } from '../../../pages/parametres/gestion-organisme/gestion-organisme-routing.module'
import { SharedModule } from '../../../shared/shared.module';
import { ListOrganismeComponent } from './list-organisme/list-organisme.component';
import { FicheOrganismeComponent } from './fiche-organisme/fiche-organisme.component';

import { FicheConventionComponent } from './fiche-convention/fiche-convention.component';
import { AngularMaterialModule } from '../../../angular-material/angular-material.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  declarations: [ListOrganismeComponent, FicheOrganismeComponent, FicheConventionComponent],
  imports: [
    CommonModule,
    GestionOrganismeRoutingModule,
    SharedModule,
    AngularMaterialModule,
    MatFormFieldModule,
    TranslateModule
  ]
})
export class GestionOrganismeModule { }
