<div class="row">
  <div class="position-fixed" style="z-index: 5;">
    <app-filtres-ticket 
    [collapseFromParent]="collapseFromParent" 
    (collapseCloseToparent)="getCollapseValue($event)"
    (onSubmitFilter)="getListTicketsSupport($event)" 
    (onPushFilters)="EmitFilterTickets($event)" 
    [filterSsearch]="filterSsearch">
    </app-filtres-ticket>
  </div>
</div>
<div class="row" #myDiv >
  <!-- Datatables -->
  <div class="col-lg-12">
    <div class="navbar-center row">


      <div class="col">
        <div class="row position-absolute flex-nowrap" style="right: 0px">
          <div class="col text-nowrap text-right mt-2">
            <p >{{'languages.listing.label_goto' | translate}}</p>
          </div>
          <div class="col mt-1 ml-2">
            <input [(ngModel)]="intervale" size="1" min="1" onkeypress="return event.charCode >= 48"
              (keyup.enter)="getchangeLimite()" (change)="getchangeLimite()" type="number" style="margin-left: 4px;"
              size="1" class="page_input bg-white border rounded font-weight-bold" [value]="pages" max="{{totalPages}}">
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-4 border-0 border" >
      <div class="row d-flex justify-content-center align-items-center">
        <div class="col-3 ">
          <button type="button" class="btn-dt-add  ml-4" (click)="NavigateToAddTicket()" [attr.data-title]="'languages.ticket.addTicket' | translate">
            <i class="fa-solid fa-plus"></i>
          </button>
        </div>
        <div class="col-6 blocDate mt-2">
          <div class="row d-flex justify-content-center align-items-center">
              <!-- <div class="col-5">
                  <mat-form-field appearance="fill" class="w-100">
                      <mat-label>{{'languages.listing.label_debutdate' | translate}}</mat-label>
                      <input matInput [matDatepicker]="pickerDebut" id="date_debut" name="date_debut" [(ngModel)]="date_debut" [value]="date_debut">
                      <mat-datepicker-toggle matSuffix [for]="pickerDebut"></mat-datepicker-toggle>
                      <mat-datepicker #pickerDebut></mat-datepicker>
                  </mat-form-field>
              </div>
              <div class="col-5">
                  <mat-form-field appearance="fill" class="w-100">
                      <mat-label>{{'languages.listing.label_enddate' | translate}}</mat-label>
                      <input matInput [matDatepicker]="pickerFin" id="date_fin" name="date_fin" [(ngModel)]="date_fin" [value]="date_fin | date:'yyyy-MM-dd'">
                      <mat-datepicker-toggle matSuffix [for]="pickerFin"></mat-datepicker-toggle>
                      <mat-datepicker #pickerFin></mat-datepicker>
                  </mat-form-field>
              </div> -->
              <div class="col-2">    
                <img src="assets/icons/calendarListing.svg" class="mr-3" alt="">
        </div>
            <div class="col-8">
            
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-date-range-input [rangePicker]="picker"  >
                      <input matStartDate [(ngModel)]="date_debut" readonly placeholder="Date début "  (click)="openDatePicker()" />
                      <input matEndDate [(ngModel)]="date_fin" readonly placeholder="Date fin" (click)="openDatePicker()"/>
                      
                    </mat-date-range-input>
                    
                    <mat-datepicker-toggle class="m-0 p-0" matPrefix [for]="picker">    <mat-icon matDatepickerToggleIcon>
                      <img src="assets/icons/calendar.svg" alt="calendar" > 
                    </mat-icon></mat-datepicker-toggle>
                    <img  class="remouveDate" matSuffix (click)="clearDateCreation()" src="assets/icons/delete-left.svg" alt="delete" alt="delete">
                    <mat-date-range-picker  [calendarHeaderComponent]="rangeDateCreation" #picker></mat-date-range-picker>
                  </mat-form-field>
               
              </div>
              <div class="col-2 w-100 pl-0 ">
                  <button type="button" class="filtrer" (click)=" filtrer()">
                    <img src="assets/icons/filtre.svg" class="mr-3" alt=""> 
      Filtrer
    </button>
              </div>
          </div>
      </div>
        <div class="col-3 p-2  text-right">
          <input class="search" name="search" [(ngModel)]="search"  (input)="onInputChange($event.target.value)" placeholder="{{'languages.miniListing.search' | translate}}">
          <i aria-hidden="true" class="fa-solid fa-magnifying-glass icon-search fa-md"></i>
        </div>
        <!-- <div class="col-3  p-2 d-flex justify-content-center">
          <button class="filtreFavoris">
              <img class="mr-3" src="assets/icons/filtreFavoris.svg">
               b
          </button>
      </div> -->
      </div>
      <div class="row row-info">
        <div class="card-info-1"  >
          <p class="p-info-1">{{categorieTickets.totale}}</p>
          <p class="p-info-2"> {{'languages.ticket.label_total' | translate}}</p>
        </div>
        <div class="card-info-2"  >
          <p class="p-info-1">{{categorieTickets.Declare}}</p>
          <p class="p-info-2"> {{'languages.ticket.label_declares' | translate}}</p>
        </div>
        <div class="card-info-3"  >
          <p class="p-info-1">{{categorieTickets.Ferme_positif}}</p>
          <p class="p-info-2"> {{'languages.ticket.label_fermespositif' | translate}}</p>
        </div>
        <div class="card-info-4"  >
          <p class="p-info-1">{{categorieTickets.Ferme_negatif}}</p>
          <p class="p-info-2">{{'languages.ticket.label_fermesnegatif' | translate}}</p>
        </div>
      </div>
  <!--     <div class="row">
        <button type="button" class="btn-dt-actions-groupee ml-4" (click)="actionGroupeTicket()"
          [disabled]="!actionButton"
          >
          {{'languages.groupedactions.groupedactions' | translate}}
        </button>
      </div> -->
      <div class="table-responsive p-3">
        <table class="table align-items-center">
          <thead class="head-table">
            <tr>
              <!-- <th>
                <input type="checkbox" class="check" [(ngModel)]="masterSelected" name="list_name" value="m1" (change)="checkUncheckAll()" />
              </th> -->
              <th>

                {{'languages.listing.actions' | translate}}
              </th>
              <th  >
                {{'languages.opportunite.label_creation' | translate}}
                <div class="sort-icon d-inline-block" [ngClass]="{'sort-active': sort_field=='ticket_date_creation'}"
                  [ngClass]="{'sort-up': !sort_value && sort_field=='ticket_date_creation'}">
               
                <!--   <i class="fa-solid fa-arrow-down-short-wide fa-lg"></i> -->
                </div>
              </th>

              <th  >
                {{'languages.communication.creator' | translate}}
                <div class="sort-icon d-inline-block" [ngClass]="{'sort-active': sort_field=='ticket_date_creation'}"
                  [ngClass]="{'sort-up': !sort_value && sort_field=='ticket_date_creation'}">
               
                <!--   <i class="fa-solid fa-arrow-down-short-wide fa-lg"></i> -->
                </div>
              </th>
          <!--     <th (click)="setSortField('ticket_user_creat')">{{'languages.opportunite.label_prospect' | translate}}
                <div class="sort-icon d-inline-block" [ngClass]="{'sort-active': sort_field=='prospect'}"
                  [ngClass]="{'sort-up': !sort_value && sort_field=='prospect'}">
                 
                  <i class="fa-solid fa-arrow-down-short-wide fa-lg ml-2"></i>
                </div>
              </th> -->
          <!--     <th  >
                {{'languages.listing.demande' | translate}}
                <div class="sort-icon d-inline-block" [ngClass]="{'sort-active': sort_field=='opportunité'}"
                  [ngClass]="{'sort-up': !sort_value && sort_field=='opportunité'}">
                  <i class="fa-solid fa-arrow-down-short-wide fa-lg ml-2"></i>
                </div> 
              </th> -->
        <!--       <th (click)="setSortField('ticket_user_affect')">{{'languages.alerts.affectedTo'
                | translate}}
                <div class="sort-icon d-inline-block" [ngClass]="{'sort-active': sort_field=='User affecté'}"
                  [ngClass]="{'sort-up': !sort_value && sort_field=='User affecté'}">
                  <i class="fa-solid fa-arrow-down-short-wide fa-lg "></i>
                </div>
              </th> -->
              <th  >{{'languages.alerts.subject' |
                translate}}
                <div class="sort-icon  d-inline-block" [ngClass]="{'sort-active': sort_field=='sujet'}"
                  [ngClass]="{'sort-up': !sort_value && sort_field=='sujet'}">
              <!--   <i class="fa-solid fa-arrow-down-short-wide fa-lg ml-2"></i> -->
                </div>
              </th>
      <!--         <th (click)="setSortField('ticket_categorie')">{{'languages.ticket.Category' |
                translate}}
                <div class="sort-icon  d-inline-block" [ngClass]="{'sort-active': sort_field=='catégorie'}"
                  [ngClass]="{'sort-up': !sort_value && sort_field=='catégorie'}">
                <i class="fa-solid fa-arrow-down-short-wide fa-lg ml-2"></i>
                </div>
              </th> -->
              <th  >
                {{'languages.opportunite.label_status' | translate}}
                <div class="sort-icon" style="display: inline-block;" [ngClass]="{'sort-active': sort_field=='statut'}"
                  [ngClass]="{'sort-up': !sort_value && sort_field=='statut'}">
         <!--        <i class="fa-solid fa-arrow-down-short-wide fa-lg ml-2"></i> -->
                </div>
              </th>
            </tr>
          </thead>
          <tbody class="body-table">
            <tr *ngFor="let ticket of ticketsSupport; let i = index">
       <!--        <td>
                <div class="pt-1">
                  <label class="container">
                    <input type="checkbox" [(ngModel)]="ticket.isSelected" name="list_name"
                      value="{{ticket.id_ticket}}" (change)="isAllSelected()">
                  </label>


                </div>
              </td> -->
              <td>

                <a type="button" class="btn-dt-list" (click)="NavigateToTicketDetails(ticket.id_ticket)"
                style="font-size: 20px;"
                  >
                  <i class="fa-solid fa-eye" ></i>                </a>
              </td>
              <td>
                <p>{{ticket.ticket_date_creation}}</p>
              </td>
              <td>
                <p>{{ticket.ticket_createur_name}}</p>
              </td>
 
         <!--      <td>
                <p>{{ticket.prospect_full_name}}</p>
              </td> -->
      <!--         <td>
            <div class="d-flex flex-column">
                  <div *ngIf="ticket.id_affaire_ticket">
                    <a type="button" class="btn-dt-link text-left"  target="_blank" rel="noopener noreferrer"
                      href="/affaires/details/{{ticket.id_affaire_ticket}}">
                      <i class="fa-solid fa-link fa-icon-style"></i>
                    {{'languages.listing.Affaire' | translate}}
                    </a>
                  </div>
                <div *ngIf="ticket.id_opportunite">
                    <a type="button" class="btn-dt-link text-left pl-2"  target="_blank" rel="noopener noreferrer"
                      href="/opportunities/details/{{ticket.id_opportunite}}">
                      <i class="fa-solid fa-link fa-icon-style"></i>
                   {{'languages.listing.Opportunite' | translate}}
                    </a>
                  </div> 
                </div>  
              </td> -->
             <!--  <td>
                <p>{{ticket.ticket_affect_full_name}}</p>
              </td> -->
              <td>
                <p>{{expandFileName(ticket.ticket_titre)}}</p>
              </td>
            <!--   <td>
                <p>{{ticket.categorie_libelle}}</p>
              </td> -->

              <td>

                <!--    -->
                <div class="label-status" [ngStyle]="{'background':ticket.statut_libelle[0]?.background_color}">
                  <span class="pt-2 " [ngStyle]="{'color':ticket.statut_libelle[0]?.color}">
                    {{ticket.statut_libelle[0].libelle.split('(')[0].trim()}}
                  </span>
                </div>

              </td>
            </tr>
          </tbody>
        </table>
        <div class="row d-flex justify-center align-items-center text-center ">
          <p *ngIf="ticketsSupport?.length===0"  class="col-12 noTicket">  {{'languages.ticket.noTicket' | translate}} </p>
         </div>
      </div>
    </div>
    <div class="navbar-center mb-4 row">
      <div class="col-5 pt-1">
        <div class="row w-100 flex-nowrap" >
          <div  class="mt-1">
            <p>{{'languages.miniListing.Afficher' | translate}}</p>
          </div>
          <div class="mx-1">
            <select [(ngModel)]="limit" (change)="updateListTickets()"
              class="bg-white border pt-1 rounded font-weight-bold">
              <option value="15">15</option>
              <option value="25">25</option>
              <option value="30">30</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <div class="mt-1">
            <p class="text-nowrap">{{'languages.miniListing.pagesPerPage' | translate}} {{totalLength}} {{'languages.miniListing.element' | translate}}</p>
          </div>
        </div>
      </div>
      <div class="col-6">
        <nav aria-label="Page navigation example" *ngIf="totalPages>0">
          <ul class="pagination pg-blue">
            <li (click)="first()" class="page-item" [ngClass]="{'disabled':isFirst()}" (keydown.enter)="first()">
              <a class="page-link-symbol">
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            <li *ngIf="!isFirst()" (click)="prev()" (keydown.enter)="prev()" class="page-item mx-2"> 
              <a class="page-link-symbol-chevron" aria-label="Previous">
                <i class="fa fa-chevron-left "></i>
              </a>
            </li>
            <ng-container *ngFor="let item of [].constructor(3); let i = index">
              <li *ngIf="i+pages-3>0" class="page-item">
                <a (click)="changepage(i+pages-3)" class="page-link mt-2 border rounded">{{i + pages - 3}}</a>
              </li>
            </ng-container>
            <ng-container *ngFor="let item of [].constructor(3); let i = index">
              <li *ngIf="i+pages<totalPages" class="page-item">
                <a (click)="changepage(i+pages)" class="page-link mt-2 border rounded"
                  [ngClass]="{'active-page':pages==(i+pages)}">
                  {{i + pages}}
                </a>
              </li>
            </ng-container>
            <li *ngIf="totalPages>1 && totalPages > 3 " class="m-2"> ... </li>
            <li class="page-item">
              <a (click)="changepage(totalPages)" class="page-link mt-2 border rounded"
                [ngClass]="{'active-page':pages==totalPages}">{{totalPages}}</a>
            </li>
            <li *ngIf="!isLast()" (click)="next()" class="page-item mx-2" (keydown.enter)="next()">
              <a class="page-link-symbol-chevron" aria-label="Next">
                <i class="fa fa-chevron-right"></i>
              </a>
            </li>
            <li (click)="last()" class="page-item" [ngClass]="{'disabled':isLast()}" (keydown.enter)="last()">
              <a class="page-link-symbol">
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <div class="col">
        <div class="row position-absolute flex-nowrap" style="right: 0px">
          <div class="mt-1text-nowrap text-right mt-2">
            <p >{{'languages.listing.label_goto' | translate}}</p>
          </div>
          <div class="mt-1 ml-2">
            <input [(ngModel)]="intervale" size="1" min="1" onkeypress="return event.charCode >= 48"
              (keyup.enter)="getchangeLimite()" (change)="getchangeLimite()" type="number" style="margin-left: 4px;"
              size="1" class="page_input bg-white border rounded font-weight-bold" [value]="pages" max="{{totalPages}}">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
