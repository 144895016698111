<div class=" mb-2" id="accordionFolder" *ngIf="exist.existe=== documents_rajoutes || portedoc" [ngClass]="{'dark': exist.oblig ===1, 'light':exist.oblig ===0 || portedoc || !exist.oblig}">
    <div >
        <div class="row flex-nowrap" style="cursor: pointer;">
            <div class="col-1 m-2 pl-0 greyColor" >
                <i class="fa-solid fa-folder folder-icon"></i>

            </div>
            <div class="col-9" (keydown.enter)="consulterFiles(exist.md5_id)" (click)="consulterFiles(exist.md5_id)" [attr.data-toggle]="'collapse'" [attr.data-target]="'#idDocument' + exist.md5_id" aria-expanded="true" [attr.aria-controls]="'collapseTwo' + exist.md5_id">
                <p class=" greyColor ml-3 mt-2">
                    {{exist.name}} <span *ngIf="exist.oblig === 1">( * )</span>
                </p>
                <p class="ml-3 greyColor text-dark ">
                    {{exist.files.length}}   {{"languages.document.files" | translate }}
                </p>
            </div>
         <!--    <div class="col-2 text-right mt-3 position-relative pl-0" *ngIf="show_three_points">
                <button mat-icon-button [matMenuTriggerFor]="menu" [disabled]="!accee" aria-label="Example icon-button with a menu">
                    <i class="fa-solid fa-ellipsis-vertical bi-three-dots-vertical"></i>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item>
                    <input id="existeFile{{i}}" type="file" hidden multiple  (change)="getFiles($event,exist.md5_id, exist.name)" />
                    <label for="existeFile{{i}}" class="add-file">
<i class="fa-solid fa-circle-plus"></i>
                       {{"languages.document.addFile" | translate }}
                    </label>
                  </button>
  <button mat-menu-item data-toggle="modal" data-target="#formModal" (click)="getIdDocument(exist.md5_id)">
                    <label class="add-file">
<i class="fa-solid fa-table"></i>
                      {{"languages.document.Formulaire" | translate }}
                    </label>
                  </button>  
                </mat-menu>

            </div> -->
        </div>
    </div>




</div>
<div [id]="'idDocument' + exist.md5_id" *ngIf="consulterFile && idDoc == exist.md5_id" class="collapse" [attr.aria-labelledby]="'headingFolder' + exist.md5_id" [attr.data-parent]="'#accordionFolder'">
    <div class=" mb-2 custemC" *ngFor="let file of exist.files; let index = index; ">
        <div class="row d-flex align-items-center filesDiv">


                <div class=" mt-2  col-12 " *ngIf="!file.url">
                    <div class="d-flex align-items-center">
                    <p class="d-flex justify-content-right mt-1 links col-11" ta-title="{{ file.name }}">
                        {{expandName(file.name)}}

                    </p>
                    <button  type="button" (click)="removeFile(index)" class="btn-dt-delete mb-2 deleteFile">
                        <img src="assets/icons/closeIcon.svg" alt=closeIcon>
                      </button>
                   </div>
                </div>
                <a *ngIf="file.url" href="{{ file.url }}"  target="_blank" rel="noopener noreferrer" class="links   col-12 ml-2 mt-1">
                    <p data-title="{{ file.name }}">
                        {{expandName(file.name)}}
                    </p>
                </a>
        </div>
    </div>
</div>
<!-- Formulaire Modal -->
<div *ngIf="openModal">
    <div class="modal fade" id="formModal" tabindex="-1" data-backdrop="static"   aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg"  >
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="modal-title" id="formModalLabel">  {{"languages.document.Formulaire" | translate }}</h2>
                    <button type="button" class="close" #closeModal data-dismiss="modal" aria-label="Close" (click)="onClearForm()">
                <span aria-hidden="true">&times;</span>
              </button>
                </div>
                <div class="modal-body text-center">
                    <ng-container *ngIf="loadingData">
                        <div class="d-flex justify-content-center">
                            <mat-spinner [diameter]="30"></mat-spinner>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!loadingData">
                        <formly-form *ngIf="data != null" [model]="model" [fields]="fields">
                        </formly-form>
                        <p *ngIf="data == null">  {{"languages.document.Noformulaire" | translate }}</p>
                    </ng-container>
                </div>
                <div class="modal-footer">
                    <ng-container *ngIf="show_loading_form">
                        <span class="spinner-style">
                  <mat-spinner [diameter]="30"></mat-spinner>
                </span>
                    </ng-container>

                    <button *ngIf="!show_loading_form || data == null" type="button" class="btn-dt-save-popup mt-1" (click)="onSaveForm()">
                        <i class="fa-solid fa-circle-check fa-lg mr-1"></i>
            {{"languages.buttons.enregistrer" | translate }}
              </button>
              <button *ngIf="!show_loading_form" type="reset" class="btn-dt-cancel-popup mt-1" (click)="onClearForm()">
                <i class="fa-solid fa-circle-xmark fa-icon-style mr-1 fa-lg"></i>
                         {{"languages.buttons.fermer" | translate }}
                              </button>
                </div>
            </div>
        </div>
    </div>
</div>
