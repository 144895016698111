<div class="card-head text-center">
    <div class="row details-header">
        <button type="button" (click)="retourToList()" class="btn-dt-retourr mt-1" >
            <div class="icon-position">
                <i class="fa-solid fa-arrow-left fa-icon-style fa-lg"></i>
            </div>
        </button>
        <div class="row">
            <div class="row details-spinner">
                <div class="col">
                    <ng-container *ngIf="show_loading_add">
                        <div class="col-12">
                            <div class="spinner-style">
                                <mat-spinner [diameter]="30"></mat-spinner>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="col mr-3">
                    <button type="button" class="btn-load" (click)="clearForm()">
                        <i class="fa-solid fa-rotate-right"></i>
                    </button>
                </div>
            </div>
            <button type="button" class="btn-dt-save" (click)="add()" [disabled]="show_loading_add">
<i class="fa-solid fa-circle-check fa-lg mr-1"></i>
                {{"languages.buttons.enregistrer" | translate}}
            </button>
        </div>
    </div>
</div>
<!--
-->

<div class="card border-0 m-3 mb-5 p-3 ">
    <div class="row ">
        <div class="col-md-6">
            <h2 *ngIf="ModeAjout"> {{"languages.garantie.addGarantie" | translate}}</h2>
            <h2 *ngIf="!ModeAjout">{{"languages.garantie.modifyGarantie" | translate}}</h2>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <span class="text-danger">( * )  {{"languages.affaire.champsOblig" | translate}}</span>
        </div>
    </div>
    <hr>

    <div class="row ">
        <div class="col">
            <form [formGroup]="grantieForm" (ngSubmit)="add()">

                <div class="row mt-3">
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <mat-form-field class="w-100">
                                <mat-label>{{'languages.garantie.codeGarantie' | translate}}</mat-label>
                                <input type="text" matInput formControlName="code" required placeholder="{{'languages.garantie.codeGarantie' | translate}}">
                                <mat-error
                                    *ngIf="grantieForm.get('code').hasError('required') && grantieForm.get('code').touched">
                                    {{'languages.garantie.obligCode' | translate}} 
                                </mat-error>
                            </mat-form-field>

                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <mat-form-field class="w-100">
                                <mat-label>{{"languages.garantie.nomGarantie" | translate}}</mat-label>
                                <input type="text" matInput formControlName="nom" required placeholder="{{'languages.garantie.nomGarantie' | translate}}">
                                <mat-error
                                    *ngIf="grantieForm.get('nom').hasError('required') && grantieForm.get('nom').touched">
                                    {{"languages.garantie.obligNomGarantie" | translate}} 
                                </mat-error>
                            </mat-form-field>

                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <mat-form-field class="w-100">
                                <mat-label>{{"languages.garantie.valeurMin" | translate}} </mat-label> 
                                <input type="text" matInput formControlName="valeur_min" (keypress)="numberOnly($event)"
                                    placeholder="{{'languages.garantie.valeurMin' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <mat-form-field class="w-100">
                                <mat-label>{{"languages.garantie.valeurMax" | translate}}</mat-label>
                                <input type="text" matInput formControlName="valeur_max" (keypress)="numberOnly($event)"
                                    placeholder="{{'languages.garantie.valeurMax' | translate}}">
                                 
                                <mat-error *ngIf="grantieForm.hasError('valeurMinInferieureValeurMax')">
                                    {{'languages.garantie.difValeurMinMax' | translate}} 
                                </mat-error>
                            </mat-form-field>

                        </div>
                    </div>

                    <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <mat-form-field class="w-100">
                                <mat-label>{{"languages.garantie.unit" | translate}}</mat-label>
                                <mat-select formControlName="unite" required>

                                    <mat-option value="{{currency}}">{{currency}}</mat-option>
                                    <mat-option value="%">%</mat-option>

                                </mat-select>
                                <mat-error
                                    *ngIf="grantieForm.get('unite').hasError('required')  && grantieForm.get('unite').touched">
                                    {{"languages.garantie.obligUnite" | translate}}
                                </mat-error>
                            </mat-form-field>

                        </div>
                    </div>


                    <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <mat-form-field class="w-100">
                                <mat-label>{{"languages.ticket.description" | translate}}</mat-label>
                                <textarea matInput placeholder="{{'languages.ticket.description' | translate}}"
                                    formControlName="description"></textarea>
                            </mat-form-field>
                        </div>
                    </div>



                    <div class="col-md-6">
                        <div class="form-group">
                            <app-icon-picker
                             [searchText]="icon"
                            (checkedNameIcon)="onCheckedNameIcon($event)"
                             [req]="false" 
                            (onChangeIcon)="onSelectIcon($event)"
                            (onChangeNameIcon)="onSelectNameIcon($event)"></app-icon-picker>
                        </div>
                    </div>
                    <div class="col-md-6 mt-5 col-sm-12">
                        <P class="mr-5">{{"languages.garantie.Cotisation" | translate}}:</P>
                        <P class="switch ">
                            <input type="checkbox" name="multiple" formControlName="cotisation">
                            <span class="slider round"></span>
                        </P>
                        <P class="mr-5">{{"languages.affaire.capitauxAssure" | translate}}:</P>
                        <P class="switch ">
                            <input type="checkbox" name="capito_assure" formControlName="capitaux_assure">
                            <span class="slider round"></span>
                        </P>
                    </div>
                </div>
            </form>

        </div>

    </div>



</div>