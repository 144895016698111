<div class="row col-12 mt-3">
  <mat-accordion class="example-headers-align w-100"  multi>
      <mat-expansion-panel [(expanded)]="isExpanded"  id="{{index}}">
            <mat-expansion-panel-header>

              <i class="fa-solid fa-clone iconDuiplicate" (click)="$event.stopPropagation();" (click)="duplicateobjet(formObjectAssurer)" (keydown.enter)="duplicateobjet(formObjectAssurer)" ></i>
<i class="fa-solid fa-trash iconTRash" (click)="$event.stopPropagation();" (click)="deleteobjet(formObjectAssurer)" (keydown.enter)="deleteobjet(formObjectAssurer)" ></i>
                
                <mat-panel-title>
                    <h2 class="font-primary-geoprod">
                      <i class=" {{formObjectAssurer?.icon}} fa-lg mr-1" *ngIf="formObjectAssurer?.icon && formObjectAssurer?.icon !== 'None' "></i>
                         {{formObjectAssurer.nom}}</h2>
                </mat-panel-title>
                <mat-panel-description>

                </mat-panel-description>
            </mat-expansion-panel-header>

    <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form" (change)="changeValue($event)" ></formly-form>

  </mat-expansion-panel>
</mat-accordion>
</div>
