import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as $ from 'jquery';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ApiGroupGarantieService } from 'src/app/services/ApiGroupGarantie/api-group-garantie.service';

import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
@Component({
  selector: 'app-list-group-garanties',
  templateUrl: './list-group-garanties.component.html',
  styleUrls: ['./list-group-garanties.component.css'],
})
export class ListGroupGarantiesComponent implements OnInit, OnDestroy {
  garantie: []=[];
  inputSubject = new Subject<string>();
  dataTable: any;
  unsubscribeGetListGroupGarantie: Subscription;
  miniListing: any;
  currentLanguage: string;
  constructor(
    private routes: Router,
    private apiGroupGarantieService: ApiGroupGarantieService,
    private readonly sharedMenuObserverService: SharedMenuObserverService,
    private chRef: ChangeDetectorRef,
    private translate: TranslateService
  ) {

      this.inputSubject.pipe(
      debounceTime(1000) 
    ).subscribe((inputValue: string) => {
      this.filter(inputValue);
    });
  }

  onInputChange(value: string): void {
    this.inputSubject.next(value);
  }


  ngOnDestroy(): void {
    const currentRoute = this.routes.url;
    if (!currentRoute.includes('groupe-garantie')) {
      localStorage.removeItem('search');
      localStorage.removeItem('page');
    }
  
  }

  ngOnInit(): void {
    // this.showloader();

    this.translate.get('languages').subscribe((object: any) => {
      this.sharedMenuObserverService.updateMenu(object.navbar.gestionGroupGarantie);
      this.miniListing = object.miniListing;
    });
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.sharedMenuObserverService.updateMenu(object.navbar.gestionGroupGarantie);
        this.miniListing = object.miniListing;
        //  this.showloader();
    
      });
    });

    this.getListGroup();
  }



  getListGroup(){
    this.showloader();
    if(localStorage.getItem("page")) this.page = parseInt (localStorage.getItem("page"));
    if(localStorage.getItem("search")) this.search = localStorage.getItem("search");
    this.unsubscribeGetListGroupGarantie = this.apiGroupGarantieService
    .getListGroupGarantieV1(this.limit, this.page,this.search)
    .subscribe((data: any) => {
      this.garantie = data.Data;
      this.totalLength = data.totalCount;
      this.calculate_nb_pages(this.totalLength);
  
      this.hideloader();
      this.chRef.detectChanges();
    });
  
}

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  goTofiche() {
    this.routes.navigate(['/add-groupe-garantie/nouveau']);
  }

  goToDetails(item: any) {
    this.routes.navigate(['/add-groupe-garantie/' + item.id]);
  }

  expandFileName(name: string): string {
    if (name.length < 12) {
      return name;
    } else {
      return name.substring(0, 11) + '...';
    }
  }




  limit = 15;
  page = 1;
  totalPages = 0;
  totalLength: any;
  intervale = 1;
  search: string="";

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    localStorage.removeItem('search');
    localStorage.removeItem('page');
  }
  
  calculate_nb_pages(iTotalDisplayRecords) {
    this.totalPages = Math.floor(iTotalDisplayRecords / this.limit);
    if (iTotalDisplayRecords % this.limit !== 0) {
      this.totalPages += 1;
    }
  }
  first() {
    this.setPage(1);

  }

  prev() {
    this.setPage(Math.max(1, this.page - 1));
  }

  next() {
    this.setPage(Math.min(this.totalPages, this.page + 1));
  }

  last() {
    this.setPage(this.totalPages);
  }

  setPage(val: number) {
    this.page = val;
    localStorage.setItem('page',this.page.toString())
    this.getListGroup();
  }

  isFirst(): boolean {
    return this.page === 1;
  }

  isLast(): boolean {
    return this.page === this.totalPages;
  }

  changepage(number: number) {
    this.page = number; 
    localStorage.setItem('page',this.page.toString())
    this.getListGroup();
  }
  
  getchangeLimite() {
    if (this.intervale <= this.totalPages) {
      this.page = this.intervale;
      if (this.page <= this.totalPages) {
        this.getListGroup();
      }
    }
  }

  updateListGroup() {
    this.page = 1;
    this.getListGroup();
  }

  filter(event) {
    this.page = 1;

      localStorage.setItem('search',this.search);
      localStorage.setItem('page',this.page.toString())
      this.getListGroup();
      
    }
  


}
