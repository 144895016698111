<div class="col-lg-12">
<div class="flex-column bg-white borderr ">
    <div class="col-4 p-2 mt-2 text-right float-right" >
      <input class="search" name="" 
      [(ngModel)]="FilterProspectClient.sSearch"
      (keypress)="getDataValue($event)"
        placeholder="{{'languages.miniListing.search' | translate}}">
        <i aria-hidden="true" class="fa-solid fa-magnifying-glass icon-search fa-md"></i>

    </div>
    <div class="p-3 mb-3" >
      <table id="Table" class="table w-100" >
        <thead class="head-table">
          <tr>
            <th style="width: 50px;"> {{'languages.listing.Num' | translate}}</th>
            <th>  {{'languages.prospect.nom' | translate}}</th>
            <th>  {{'languages.prospect.prenom' | translate}}</th>
            <th>  {{'languages.prospect.email' | translate}}</th>
          <th>  {{'languages.prospect.phone' | translate}}</th>
          <th>  {{'languages.listing.activities' | translate}}</th>
          <th>  {{'languages.ticket.details' | translate}}</th>
          </tr>
        </thead>
       
        <tbody class="body-table"  *ngIf="listProspectClient.length > 0">
   
          <tr *ngFor="let item of listProspectClient; let index = index">
        <td> {{index + 1}}</td>
            <td *ngIf="item.surname !== null">
                 {{item.surname}}
             </td>
             <td *ngIf="item.surname == null">
              -
             </td>
             <td *ngIf="item.name !== null">
              {{item.name}}
               </td>
               <td *ngIf="item.name == null">
                -
               </td>
               <td *ngIf="item.adresse_mail !== null ">
                {{item.adresse_mail}}
               </td >
               <td *ngIf="item.adresse_mail == null">
                -
               </td>
               <td *ngIf="item.mobile !== null">
                {{item.mobile}}
               </td>
               <td *ngIf="item.mobile == null">
                -
               </td>
               <td class=" d-flex justify-content-center">
                <div class="w-100 text-center" >

                  <div class="btn-status my-2  Opportunite"  >
                    <i class="fa-solid fa-bullhorn pr-2"></i>
                  <span  > {{item.nombre_opportunite}}  {{'languages.listing.opportunitess' | translate}}</span></div>



                  <div class="btn-status my-2 affaire" >
                    <i class="fa-solid fa-road  pr-2"></i>
                    <span  >{{item.nombre_affaire}}  {{'languages.listing.affaires' | translate}}</span></div>

                   <div class="btn-status my-2 ticket" >
                    <i class="fa-solid fa-money-check-dollar pr-2"></i>
                    <span >{{item.nombre_ticket}}  {{'languages.listing.ticketss' | translate}}</span></div></div>
               </td>
            <td>
              <button type="button" class="btn-dt-list" routerLink="/prospectClient/fiche-prospect/ProspectCLient/{{item.id}}/ProspectCLient">
              <img src="/assets/icons/edit-icon.svg" alt="edit">
            </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    
    <div class="navbar-center mb-4 row" *ngIf="listProspectClient.length > 0">
      <div class="col-5 pt-1">
        <div class="row w-100 flex-nowrap" >
          <div  class="mt-1">
            <p>{{'languages.miniListing.Afficher' | translate}}</p>
          </div>
          <div  class="mx-1">
            <select [(ngModel)]="limit" (change)="updateListProspect()"
            class="bg-white border pt-1 rounded font-weight-bold">
              <option value="15">15</option>
              <option value="25">25</option>
              <option value="30">30</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <div class="mt-1">
            <p class="text-nowrap">{{'languages.miniListing.pagesPerPage' | translate}} {{totalLength}} {{'languages.miniListing.element' | translate}}</p>
          </div>
        </div>
      </div>
      <div class="col-6">
        <nav aria-label="Page navigation example"  *ngIf="totalPages>0">
          <ul class="pagination pg-blue">
            <li (click)="first()" class="page-item" [ngClass]="{'disabled':isFirst()}" (keydown.enter)="first()">
              <a class="page-link-symbol">
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            <li *ngIf="!isFirst()" (click)="prev()" (keydown.enter)="prev()" class="page-item mx-2" >

              <a class="page-link-symbol-chevron" aria-label="Previous">
                <i class="fa fa-chevron-left "></i>
              </a>
            </li>
            <ng-container *ngFor="let item of [].constructor(3); let i = index">
              <li *ngIf="i+pages-3>0" class="page-item">
                <a (click)="changepage(i+pages-3)" class="page-link mt-2 border rounded">{{i + pages - 3}}</a>
              </li>
            </ng-container>
            <ng-container *ngFor="let item of [].constructor(3); let i = index">
              <li *ngIf="i+pages<totalPages" class="page-item">
                <a (click)="changepage(i+pages)" class="page-link mt-2 border rounded"
                [ngClass]="{'active-page':pages==(i+pages)}">
                {{i + pages}}
              </a>
              </li>
            </ng-container>
            <li *ngIf="totalPages>1 && totalPages > 3 " class="m-2"> ... </li>
            <li class="page-item">
              <a (click)="changepage(totalPages)" class="page-link mt-2 border rounded"
                [ngClass]="{'active-page':pages==totalPages}">{{totalPages}}</a>
            </li>
            <li *ngIf="!isLast()" (click)="next()" class="page-item mx-2" (keydown.enter)="next()">
              <a class="page-link-symbol-chevron" aria-label="Next">
                <i class="fa fa-chevron-right"></i>
              </a>
            </li>
            <li (click)="last()" class="page-item" [ngClass]="{'disabled':isLast()}" (keydown.enter)="last()">
              <a class="page-link-symbol">
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
          </ul>
        </nav>
    </div>
      <div class="col">
        <div class="row position-absolute flex-nowrap pagination">
          <div class="mt-1text-nowrap text-right mt-2">
            <p >{{'languages.listing.label_goto' | translate}}</p>
          </div>
          <div class="mt-1 ml-2">
            <input [(ngModel)]="intervale"    size="1"    min="1"  onkeypress="return event.charCode >= 48"  (keyup.enter)="getchangeLimite()"  (change)="getchangeLimite()"  type="number"
            size="1" class="page_input bg-white border rounded font-weight-bold ml-1" [value]="pages" max="{{totalPages}}">
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center text-center"
    *ngIf="listProspectClient.length == 0  ">
    <p class="text-center justify-content-center mb-3 Noprospect "> {{'languages.affaire.noDataAvailable' | translate}}  </p>
</div>
  </div>
</div>
