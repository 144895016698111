
<p>Nouveau Listing Gestion :</p>
<div class="flex-column bg-white rounded-card">
  <div class="header-card-gestion py-3">
    <div class="col-3">
    <button type="button" class="btn-dt-add  ml-4" routerLink="fiche/nouveau"  data-title="Ajouter une campagne">
      <i class="fa-solid fa-plus fa-lg"></i>
    </button>
  </div> </div>
  <div class="p-3 mb-4">
    <table class="table">
      <thead class="head-table">
        <tr>
          <th> Column1 </th>
          <th> Column2 </th>
          <th> Column2 </th>
          <th> Column3</th>
        </tr>
      </thead>
      <tbody class="body-table">
        <tr>
          <td>Body 1</td> 
          <td>Body 2</td>
          <td>Body 3</td>
          <td>Body 4</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<app-code [code]="listinggestion"></app-code>


<div class="table-responsive p-3">
  <table class="table align-items-center" id="dataTable1">
    <thead class="head-table">
    <tr>
      <th>Actions</th>
      <th>
        Création 
        <i class="fa-solid fa-arrow-down-short-wide fa-lg"></i>
      </th>
      <th>
        Organisme
        <i class="fa-solid fa-arrow-down-short-wide fa-lg"></i>
      </th>
      <th>
        Zone & Code postal
        <i class="fa-solid fa-arrow-down-short-wide fa-lg"></i>
      </th>
      <th>
        Prospect
        <i class="fa-solid fa-arrow-down-short-wide fa-lg"></i>
      </th>
      <th>
        RDV
        <i class="fa-solid fa-arrow-down-short-wide fa-lg"></i>
      </th>
      <th>
        Status
        <i class="fa-solid fa-arrow-down-short-wide fa-lg"></i>
      </th>
    </tr>
    </thead>
    <tbody class="body-table">
    <tr>
      <td>
        <div class="d-flex justify-content-center">
          <div class="pt-1">
            <button  type="button" class="btn-dt-list">
              <img src="/assets/icons/phone-icon.svg" alt="phoneicon">
            </button>
          </div>
          <div class="pt-1">
            <button  type="button" class="btn-dt-list">
              <img src="/assets/icons/edit-icon.svg" alt="edit">
            </button>
          </div>
          <div>
            <input type='checkbox' id="perm" data-title="Text ici" class="star" />
            <label for="perm"></label>
          </div>
        </div>
      </td>
      <td>Leaders Leads
      </td>
      <td >
        78000 Versailles
      </td>
      <td>Victorine Monnier</td>
      <td>Sans RDV</td>
      <td>
        test
      </td>
      <td>
      test
      </td>
    </tr>
    </tbody>
  </table>
 </div>

    <app-code [code]="codeData_Table"></app-code>

    
    <h3>Pagination</h3>
        <div class="navbar-center row">
          <div class="col-5 pt-1">
            <div class="row w-100 flex-nowrap" >
              <div  class="mt-1">
                <p>Afficher</p>
              </div>
              <div  class="mx-1">
                <select  
                class="bg-white border pt-1 rounded font-weight-bold"
                  >
                  <option value="15">15</option>
                  <option value="25">25</option>
                  <option value="30">30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div class="mt-1">
                <p>Entrées</p>
              </div>
            </div>
          </div>
          <div class="col-6">
            <nav aria-label="Page navigation example">
              <ul class="pagination pg-blue">
                <li class="page-item">
                  <a class="page-link-symbol">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li class="page-item  mx-2" >
                  <a class="page-link-symbol-chevron" aria-label="Previous">
                    <i class="fa fa-chevron-left "></i>
                  </a>
                </li>
                <ng-container>
                  <li class="page-item">
                    <a class="page-link mt-2 border rounded">1
                    </a>
                  </li>
                </ng-container>
                <ng-container>
                  <li class="page-item">
                    <a class="page-link mt-2 border rounded">4</a>
                  </li>
                </ng-container>
                <li class="page-item">
                  <a class="page-link mt-2 border rounded">15
                  </a>
                </li>
                <li class="page-item mx-2" >
                  <a class="page-link-symbol-chevron" aria-label="Next">
                    <i class="fa fa-chevron-right"></i>
                  </a>
                </li>
                <li class="page-item ">
                  <a class="page-link-symbol">
                    <span aria-hidden="true">&raquo;</span></a>
                </li>
              </ul>
            </nav>
        </div>
          <div class="col">
            <div class="row position-absolute flex-nowrap" style="right: 0px">
              <div class="mt-1text-nowrap text-right mt-2">
                <p>Aller à la page N°  </p>
              </div>
              <div class="mt-1 ml-2">
                <input type="number" value="1" class="page_input bg-white border rounded font-weight-bold">
              </div>
            </div>
          </div>
        </div>
        <app-code [code]="pagination_code"></app-code>
          <h3>Data table Label </h3>
          <div class="Doublon-row">
            <div class="rejected nunitosans-bold-mandy-13px">Doublon</div>
          </div>

          <div class="Propsition-row">
            <div class="approved nunitosans-bold-robins-egg-blue-13px">Propsition</div>
          </div>

          <div class="Rappel-row">
            <div class="pending nunitosans-bold-pizazz-12px">Rappel</div>
          </div>

          <div class="Vente-row">
            <div class="approved nunitosans-bold-pine-13px">Vente</div>
          </div>

          <div class="Affectee-row">
            <div class="x12 nunitosans-bold-pizazz-13px">Affectée (création)</div>
          </div>
        

     

        <app-code [code]="codeLabel"></app-code>

        <div class=" py-5 ">
          cards des chiffres 
          <div class=" row row-info">
            <div class="card-info-1" >
                <p class="p-info-1">2,34 M€</p>
                <p class="p-info-2">Chiffre d’Affaires</p>
            </div>
            <div class="card-info-2" >
                <p class="p-info-1">9653</p>
                <p class="p-info-2">Nbre des contrats</p>
            </div>
            <div class="card-info-3" >
                <p class="p-info-1">5402,33</p>
                <p class="p-info-2">Nbre des contacts</p>
            </div>
            <div class="card-info-4" >
                <p class="p-info-1">5402,33</p>
                <p class="p-info-2">Nbre des contacts</p>
            </div>
            <div class="card-info-5" >
              <p class="p-info-1">5402,33</p>
              <p class="p-info-2">Nbre des contacts</p>
          </div>
        </div>
        </div>
        <app-code [code]="cardListing"></app-code>

<div class="row">Pas de données disponibles</div>
        <div class="row pt-2" >
          <div class="col-12  d-flex justify-content-center">
            <img src="assets/icons/nodata.svg" alt="noData">
          </div>
          <div class="col-12  d-flex justify-content-center">
            <p class="noData">Pas de données disponibles</p>
          </div>
         
  </div>
  <app-code [code]="NodataDispo"></app-code>