import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.css']
})
export class LoadingBarComponent  {
  imgLogoSiagf_2: any;

  constructor() { 
    this.imgLogoSiagf_2=environment.imgLogoSiagf_2
  }

   

}
