import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiOpportunitService } from '../../../../services/ApiOpportunite/api-opportunite.service';
import { Observable, of } from 'rxjs';
import * as addOppActions from './add-opportunite.actions';
import { catchError, map, mergeMap, pluck } from 'rxjs/operators';
import { CycleVie } from '../../../../entity/CycleVie';
import { ApiContratsService } from '../../../../services/ApiContrats/api-contrats.service';
import { LigneProduit } from '../../../../entity/Opportunity/LigneProduit';
import { CampagneInfo } from '../../../../entity/Opportunity/CampagneInfo';
import { OpportunityAdd } from '../../../../entity/Opportunity/OpportunityAdd';
import { Commerciaux } from '../../../../entity/Opportunity/Commerciaux';
import { ApiUsersService } from '../../../../services/ApiUsers/api-users.service';
import { ApiCampagniesService } from '../../../../services/ApiCompagnies/api-compagnies.service';

@Injectable()
export class addOpportuniteEffects {
  constructor(
    private actions$: Actions,
    private apiOpportuniteService: ApiOpportunitService,
    private apiCampagniesService: ApiCampagniesService,
    private apiContratsService: ApiContratsService,
    private apiUserServices: ApiUsersService,
    private apiOpportunitService: ApiOpportunitService
  ) {}

  saveOpportunite: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(addOppActions.SaveAddOpportunite),
      mergeMap(({ payload }) =>
        this.apiOpportuniteService.insertOpportunite(payload).pipe(
          map((data) => {
            const nouvelleListe = payload.campagne.objectAssurer.map((element) => {
              return {
                [element.nom]: { index: element.index, data: element.form.data },
              };
            });
            this.apiOpportuniteService
              .update_objet_assure_by_entity(data.id_opportunite_md5, 'opportunite', nouvelleListe)
              .subscribe(
                () => {
                  // Traitement en cas de succès
                },
                (err) => {
                  // Gestion des erreurs
                  addOppActions.SaveAddOpportuniteFail({ payload: err });
                }
              );
            return addOppActions.SaveAddOpportuniteSuccess({ payload: data.id_opportunite_md5 });
          }),
          catchError((err) => of(addOppActions.SaveAddOpportuniteFail({ payload: err })))
        )
      )
    )
  );

  loadListCampagnies: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(addOppActions.GetListCampagnes),
      mergeMap(() =>
        this.apiOpportunitService.getListCampagne().pipe(
          map((data: CycleVie) => addOppActions.GetListCampagnesSuccess({ payload: data })),
          catchError((err) => of(addOppActions.GetListCampagnesFail({ payload: err.message })))
        )
      )
    )
  );

  // loadListLignesProduit: Observable<any> = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(addOppActions.GetListCampagnes),
  //     mergeMap(() =>
  //       this.apiContratsService.listeAllProduits().pipe(
  //         pluck('lignes_produits'),
  //         map((data: LigneProduit[]) => addOppActions.GetListLigneProductsSuccess({ payload: data })),
  //         catchError((err) => of(addOppActions.GetListLigneProductsFail({ payload: err.message })))
  //       )
  //     )
  //   )
  // );

  loadListCommerciaux: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(addOppActions.GetListCampagnes),
      mergeMap(() =>
        this.apiUserServices.getUser().pipe(
          map((data: Commerciaux[]) => addOppActions.GetListCommercieauxSuccess({ payload: data })),
          catchError((err) => of(addOppActions.GetListCommercieauxFail({ payload: err.message })))
        )
      )
    )
  );

  loadInfoComplementaires: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(addOppActions.SelectCampagne),
      mergeMap(({ payload }) =>
        this.apiCampagniesService.getInfoCompementairesByCampId(payload.campagneId).pipe(
          map((data: CampagneInfo) => addOppActions.GetInfoComplementairesSuccess({ payload: data })),
          catchError((err) => of(addOppActions.GetInfoComplementairesFail({ payload: err.message })))
        )
      )
    )
  );

  loadInfoComplementaires2: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(addOppActions.SelectCampagne),
      mergeMap((payload) =>
        this.apiOpportuniteService.getListStatusCreationByCampgneId(payload.payload.campagneId).pipe(
          map((data: any) => addOppActions.GetListStatusSuccess({ payload: data })),
          catchError((err) => of(addOppActions.GetListStatusFail({ payload: err.message })))
        )
      )
    )
  );
}
