export class UserProfile { 
    Informations_essentielles: Informations_essentielles = new Informations_essentielles()
    Informations_etendues: Informations_etendues = new Informations_etendues()
    Informations_webmail: Informations_webmail = new Informations_webmail()
    Param_user: Param_user = new Param_user()

}
export class Informations_essentielles {
    api_key: string = "";
    civilite: string = "";
    date_naiss_user: string = "";
    identite_reelle: string = "";
    mail: string = "";
    matricule_rh: string = "";
    nom: string = "";
    password: string = "";
    prenom: string = "";
    id_user: string = "";
    url_photo: string = "";
    constructor(){}
}
export class  Informations_etendues {
    cp: string = "";
    geom: string = "";
    lat: string = "";
    lng: string = "";
    mobile: string = "";
    num_inter: string = "";
    num_tel: string = "";
    num: string = "";
    pays: string = "";
    rue: string = "";
    skype: string = "";
    tel2: string = "";
    tel_urgence: string = "";
    url_photo: string = "";
    ville: string = "";
    location: string = "";
    adresse_auto: string = "";
    constructor(){}
} 
export class Informations_webmail {
    webmail_mail: string = "";
    webmail_port_in: string = "";
    webmail_port_out: string = "";
    webmail_pwd: string = "";
    webmail_server_in: string = "";
    webmail_server_out: string = "";
    webmail_ssl: string = "";
    constructor(){}
} 
export class Param_user {
    adresse_auto: string = "";
    heure_deb: string = "";
    heure_fin: string = "";
    samedi_off: string = "";
    constructor(){}
}