import { Inject, Injectable, Optional } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Configuration } from '../configuration';
import { BASE_PATH } from '../variables';
import { ModelObject } from '../model/modelObject';
import { Observable } from 'rxjs';
import { CustomHttpUrlEncodingCodec } from '../encoder';

@Injectable({
  providedIn: 'root',
})
export class ApiBusinessIntelligenceService {
  protected basePath = environment.api_url;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }  }

  /**
   * Ajouter ou modifier une categorie status opportunite suivant son identifiant codé en md5
   *
   * @param cle cle de l&#x27;operation update ou create
   * @param libelle libelle de status opportunité
   * @param icon icon de status opportunité
   * @param id Identifiant de status opportunité  a modifier (Champs obligatoire pour la modification)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getPowerBICredits(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/bi/get_credits`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  public updatePowerBICredits(
    cle: string,
    libelle: ModelObject,
    icon: ModelObject,
    id?: ModelObject,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (cle === null || cle === undefined) {
      throw new Error(
        'Required parameter cle was null or undefined when calling createOrUpdateCatergorieStatusOppGet.'
      );
    }

    if (libelle === null || libelle === undefined) {
      throw new Error(
        'Required parameter libelle was null or undefined when calling createOrUpdateCatergorieStatusOppGet.'
      );
    }

    if (icon === null || icon === undefined) {
      throw new Error(
        'Required parameter icon was null or undefined when calling createOrUpdateCatergorieStatusOppGet.'
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (cle !== undefined && cle !== null) {
      queryParameters = queryParameters.set('cle', cle as any);
    }
    if (id !== undefined && id !== null) {
      queryParameters = queryParameters.set('id', id as any);
    }
    if (libelle !== undefined && libelle !== null) {
      queryParameters = queryParameters.set('libelle', libelle as any);
    }
    if (icon !== undefined && icon !== null) {
      queryParameters = queryParameters.set('icon', icon as any);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/create_or_update_catergorieStatusOpp`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  public getAuthDashboard(reporting_path: any): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }
    if (reporting_path !== undefined && reporting_path !== null) {
      queryParameters = queryParameters.set('reporting_path', reporting_path as any);
    }

    queryParameters = queryParameters.append('token', this.configuration.apiKeys.idSession);

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/admin/user_acces_reporting_path?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
    });
  }
}
