<div class="footer">
  <div id="Groupe_976">
    <div id="ID2020__Neopolis_Corp_Tous_Dro">
      <span>{{year}} © {{ "languages.footer" | translate }}</span>
      <a href="https://neopolis-dev.com/"  target="_blank" rel="noopener noreferrer" >
        <img style="width: 82px;height: 26px;margin-left: 20px;padding-top: 3px;" src="https://staging.cdn.sigaf.cloud.geoprod.com/static/uploads/files/logoNeopolisBlue.png" alt="logo">
      </a>
     
    </div>
    <span class="version float-left" >V {{formatVersion(version)}} {{build}}</span>
  </div>
</div>
