import {Inject, Injectable, Optional} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Configuration} from "../configuration";
import {BASE_PATH} from "../variables";
import {Observable} from "rxjs";
import {CustomHttpUrlEncodingCodec} from "../encoder";
import {ModelObject} from "../model/modelObject";

@Injectable({
  providedIn: 'root'
})
export class ApiMailTemplatesService {

  protected basePath = environment.api_url;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }  }

  /**
   * Ajouter ou modifier une categorie status opportunite suivant son identifiant codé en md5
   *
   * @param cle cle de l&#x27;operation update ou create
   * @param libelle libelle de status opportunité
   * @param icon icon de status opportunité
   * @param id Identifiant de status opportunité  a modifier (Champs obligatoire pour la modification)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getListTemplates(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/modele_mail/list`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

/**
   * Afficher les informations sur les communiquée , le naf et cloudpros suivant l&#x27;identifiant de collaborateur codé en md5 et la date_objectif
   *
   * @param Model
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public SaveModel(Model: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (Model === null || Model === undefined) {
      throw new Error('Required parameter idCollab was null or undefined when calling listCommunicationDaeObjectifGet.');
    }
    
    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('post', `${this.basePath}/mail_template/save?`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        body: Model
      }
    );
  }
  public addTemplate(tempmodel: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    // if (label === null || label === undefined) {
    //   throw new Error('Required parameter cle was null or undefined when calling createOrUpdateCatergorieStatusOppGet.');
    // }

    // if (model === null || model === undefined) {
    //   throw new Error('Required parameter libelle was null or undefined when calling createOrUpdateCatergorieStatusOppGet.');
    // }


    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    // if (label !== undefined && label !== null) {
    //   queryParameters = queryParameters.set('label', <any>label);
    // }


    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    headers = headers.set('Content-Type', 'application/json');

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('post', `${this.basePath}/modele_mail/insert`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        body: tempmodel
      }
    );
  }
  
  public UpdateTemplate(tempmodel: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    // if (label !== undefined && label !== null) {
    //   queryParameters = queryParameters.set('label', <any>label);
    // }


    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    headers = headers.set('Content-Type', 'application/json');

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('put', `${this.basePath}/modele_mail/update`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        body: tempmodel
      }
    );
  }

  public deleteTemplate(id_template: number, id_choix:string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    // if (id_template === null || id_template === undefined) {
    //   throw new Error('Required parameter cle was null or undefined when calling createOrUpdateCatergorieStatusOppGet.');
    // }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    // if (id_template !== undefined && id_template !== null) {
    //   queryParameters = queryParameters.set('id_template', <any>id_template);
    // }
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];

    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('delete', `${this.basePath}/modele_mail/delete_modele/${id_template}/${id_choix}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
   /**
  * Retourner un Courrier by id
  *
  * @param id id Courrier
  * @param choix choix Courrier
  * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
  * @param reportProgress flag to report request and response progress.
  */
    public GetModelCourireById(id: string, choix:string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

      if (id === null || id === undefined) {
        throw new Error('Required parameter id_camp was null or undefined when calling getMailCmpGet.');
      }
  
      let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
      let headers = this.defaultHeaders;
  
      // authentication (Acess_Token) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
        headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
      }
  
      // to determine the Accept header
      let httpHeaderAccepts: string[] = [];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }
  
      // to determine the Content-Type header
      const consumes: string[] = [];
  
      return this.httpClient.request<any>('get', `${this.basePath}/modele_mail/get_by_id/${id}/${choix}`,
        {
          params: queryParameters,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }
  
}
