/**
 * Documentation Geoprod
 * Description des web services de Remoteprod
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

 import { Inject, Injectable, Optional }                      from '@angular/core';
 import { HttpClient, HttpHeaders, HttpParams,
   HttpResponse, HttpEvent }                           from '@angular/common/http';
 import { CustomHttpUrlEncodingCodec }                        from '../encoder';

 import { Observable }                                        from 'rxjs';

 import { ModelObject } from '../model/modelObject';

 import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
 import { Configuration }                                     from '../configuration';
 import {environment} from "../../../environments/environment";


 @Injectable()
 export class ApiStatusService {

   protected basePath = environment.api_url;
   public defaultHeaders = new HttpHeaders();
   public configuration = new Configuration();

   constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
     if (basePath) {
       this.basePath = basePath;
     }
     if (configuration) {
       this.configuration = configuration;
       this.basePath = basePath || configuration.basePath || this.basePath;
     }
   }

   /**
    * @param consumes string[] mime-types
    * @return true: consumes contains 'multipart/form-data', false: otherwise
    */
   private canConsumeForm(consumes: string[]): boolean {
     const form = 'multipart/form-data';
     for (const consume of consumes) {
       if (form === consume) {
         return true;
       }
     }
     return false;
   }


   /**
    * Ajouter ou modifier une categorie status opportunite suivant son identifiant codé en md5
    *
    * @param cle cle de l&#x27;operation update ou create
    * @param libelle libelle de status opportunité
    * @param icon icon de status opportunité
    * @param id Identifiant de status opportunité  a modifier (Champs obligatoire pour la modification)
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
   public createOrUpdateCatergorieStatusOppGet(cle: string, libelle: ModelObject, icon: ModelObject, id?: ModelObject, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

     if (cle === null || cle === undefined) {
       throw new Error('Required parameter cle was null or undefined when calling createOrUpdateCatergorieStatusOppGet.');
     }

     if (libelle === null || libelle === undefined) {
       throw new Error('Required parameter libelle was null or undefined when calling createOrUpdateCatergorieStatusOppGet.');
     }

     if (icon === null || icon === undefined) {
       throw new Error('Required parameter icon was null or undefined when calling createOrUpdateCatergorieStatusOppGet.');
     }


     let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
     if (cle !== undefined && cle !== null) {
       queryParameters = queryParameters.set('cle', <any>cle);
     }
     if (id !== undefined && id !== null) {
       queryParameters = queryParameters.set('id', <any>id);
     }
     if (libelle !== undefined && libelle !== null) {
       queryParameters = queryParameters.set('libelle', <any>libelle);
     }
     if (icon !== undefined && icon !== null) {
       queryParameters = queryParameters.set('icon', <any>icon);
     }

     let headers = this.defaultHeaders;

     // authentication (Acess_Token) required
     if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
       headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
     }

     // to determine the Accept header
     let httpHeaderAccepts: string[] = [
     ];
     const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
     if (httpHeaderAcceptSelected != undefined) {
       headers = headers.set('Accept', httpHeaderAcceptSelected);
     }

     // to determine the Content-Type header
     const consumes: string[] = [
     ];

     return this.httpClient.request<any>('get',`${this.basePath}/create_or_update_catergorieStatusOpp`,
       {
         params: queryParameters,
         withCredentials: this.configuration.withCredentials,
         headers: headers,
         observe: observe,
         reportProgress: reportProgress
       }
     );
   }

   /**
    * Ajouter ou modifier un status dossier
    *
    * @param cle cle de l&#x27;operation update ou create
    * @param etat libelle de l&#x27;etat
    * @param categorie &lt;p&gt;Categorie de l&#x27;etat dossier &lt;br&gt; 1 Creation &lt;br&gt; 2 Enregistrement &lt;br&gt; 3 Emission &lt;br&gt; 4 SAV &lt;br&gt; 5 Pré-neg&lt;/p&gt;
    * @param classe &lt;p&gt;Classe de l&#x27;etat dossier &lt;br&gt; 1 Traitement &lt;br&gt; 2 Annulation &lt;br&gt; 3 Perte &lt;br&gt; 4 Gain &lt;br&gt; 5 Evaluation&lt;/p&gt;
    * @param level Niveau de l&#x27;etat dossier 0 ou 1
    * @param parent Identifiant de l&#x27;etat parent codé en md5
    * @param visa &lt;p&gt;Visa de l&#x27;etat dossier &lt;br&gt; 1 Traitement &lt;br&gt; 2 Annulation &lt;br&gt; 3 Perte &lt;br&gt; 4 Gain &lt;br&gt; 5 Evaluation&lt;/p&gt;
    * @param dependance Identifiant de dependance de l&#x27;etat dossier codé en md5 (a demander par mail a it@sodedif.fr)
    * @param explication Explication ou description de l&#x27;etat dossier
    * @param msgConf Une note a préciser
    * @param alert Alert de l&#x27;etat dossier
    * @param comm Si l&#x27;etat dossier est commissionable 1 si non 0
    * @param supp supp de l&#x27;etat dossier
    * @param id Identifiant de l&#x27;etat a modifier codé en md5 (Champs obligatoire pour la modification)
    * @param ticket ticket de l&#x27;etat dosseier
    * @param modelePost Media et modele de transfert de l&#x27;etat dossier obligatoire pour la modifcation
    * @param modeleMail Media et modele de transfert de l&#x27;etat dossier obligatoire pour la modifcation
    * @param modeleSms Media et modele de transfert de l&#x27;etat dossier obligatoire pour la modifcation
    * @param modeleFax Media et modele de transfert de l&#x27;etat dossier obligatoire pour la modifcation
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
   public createOrUpdateStatutDossGet(cle: string, etat: ModelObject, categorie: string, classe: string, level: string, parent: ModelObject, visa: string, dependance: ModelObject, explication: ModelObject, msgConf: ModelObject, alert: string, comm: string, supp: string, id?: ModelObject, ticket?: string, modelePost?: ModelObject, modeleMail?: ModelObject, modeleSms?: ModelObject, modeleFax?: ModelObject, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

     if (cle === null || cle === undefined) {
       throw new Error('Required parameter cle was null or undefined when calling createOrUpdateStatutDossGet.');
     }

     if (etat === null || etat === undefined) {
       throw new Error('Required parameter etat was null or undefined when calling createOrUpdateStatutDossGet.');
     }

     if (categorie === null || categorie === undefined) {
       throw new Error('Required parameter categorie was null or undefined when calling createOrUpdateStatutDossGet.');
     }

     if (classe === null || classe === undefined) {
       throw new Error('Required parameter classe was null or undefined when calling createOrUpdateStatutDossGet.');
     }

     if (level === null || level === undefined) {
       throw new Error('Required parameter level was null or undefined when calling createOrUpdateStatutDossGet.');
     }

     if (parent === null || parent === undefined) {
       throw new Error('Required parameter parent was null or undefined when calling createOrUpdateStatutDossGet.');
     }

     if (visa === null || visa === undefined) {
       throw new Error('Required parameter visa was null or undefined when calling createOrUpdateStatutDossGet.');
     }

     if (dependance === null || dependance === undefined) {
       throw new Error('Required parameter dependance was null or undefined when calling createOrUpdateStatutDossGet.');
     }

     if (explication === null || explication === undefined) {
       throw new Error('Required parameter explication was null or undefined when calling createOrUpdateStatutDossGet.');
     }

     if (msgConf === null || msgConf === undefined) {
       throw new Error('Required parameter msgConf was null or undefined when calling createOrUpdateStatutDossGet.');
     }

     if (alert === null || alert === undefined) {
       throw new Error('Required parameter alert was null or undefined when calling createOrUpdateStatutDossGet.');
     }

     if (comm === null || comm === undefined) {
       throw new Error('Required parameter comm was null or undefined when calling createOrUpdateStatutDossGet.');
     }

     if (supp === null || supp === undefined) {
       throw new Error('Required parameter supp was null or undefined when calling createOrUpdateStatutDossGet.');
     }

     let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
     if (cle !== undefined && cle !== null) {
       queryParameters = queryParameters.set('cle', <any>cle);
     }
     if (id !== undefined && id !== null) {
       queryParameters = queryParameters.set('id', <any>id);
     }
     if (etat !== undefined && etat !== null) {
       queryParameters = queryParameters.set('etat', <any>etat);
     }
     if (categorie !== undefined && categorie !== null) {
       queryParameters = queryParameters.set('categorie', <any>categorie);
     }
     if (classe !== undefined && classe !== null) {
       queryParameters = queryParameters.set('classe', <any>classe);
     }
     if (level !== undefined && level !== null) {
       queryParameters = queryParameters.set('level', <any>level);
     }
     if (parent !== undefined && parent !== null) {
       queryParameters = queryParameters.set('parent', <any>parent);
     }
     if (visa !== undefined && visa !== null) {
       queryParameters = queryParameters.set('visa', <any>visa);
     }
     if (dependance !== undefined && dependance !== null) {
       queryParameters = queryParameters.set('dependance', <any>dependance);
     }
     if (explication !== undefined && explication !== null) {
       queryParameters = queryParameters.set('explication', <any>explication);
     }
     if (msgConf !== undefined && msgConf !== null) {
       queryParameters = queryParameters.set('msg_conf', <any>msgConf);
     }
     if (alert !== undefined && alert !== null) {
       queryParameters = queryParameters.set('alert', <any>alert);
     }
     if (ticket !== undefined && ticket !== null) {
       queryParameters = queryParameters.set('ticket', <any>ticket);
     }
     if (comm !== undefined && comm !== null) {
       queryParameters = queryParameters.set('comm', <any>comm);
     }
     if (supp !== undefined && supp !== null) {
       queryParameters = queryParameters.set('supp', <any>supp);
     }
     if (modelePost !== undefined && modelePost !== null) {
       queryParameters = queryParameters.set('modele_post', <any>modelePost);
     }
     if (modeleMail !== undefined && modeleMail !== null) {
       queryParameters = queryParameters.set('modele_mail', <any>modeleMail);
     }
     if (modeleSms !== undefined && modeleSms !== null) {
       queryParameters = queryParameters.set('modele_sms', <any>modeleSms);
     }
     if (modeleFax !== undefined && modeleFax !== null) {
       queryParameters = queryParameters.set('modele_fax', <any>modeleFax);
     }

     let headers = this.defaultHeaders;

     // authentication (Acess_Token) required
     if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
       headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
     }

     // to determine the Accept header
     let httpHeaderAccepts: string[] = [
     ];
     const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
     if (httpHeaderAcceptSelected != undefined) {
       headers = headers.set('Accept', httpHeaderAcceptSelected);
     }

     // to determine the Content-Type header
     const consumes: string[] = [
     ];

     return this.httpClient.request<any>('get',`${this.basePath}/create_or_update_statut_doss`,
       {
         params: queryParameters,
         withCredentials: this.configuration.withCredentials,
         headers: headers,
         observe: observe,
         reportProgress: reportProgress
       }
     );
   }

   /**
    * Sert a affiché les etat  de staut et leur id où le cycle de vie et le role equivalent a celui passé en paramétre
    *
    * @param id Identifiant de l'opportunite '7;etat a modifier codé en md5 (Champs obligatoire pour la modification)
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
   public getStatutAutoriseGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

     if (id === null || id === undefined) {
       throw new Error('Required parameter cycleVie was null or undefined when calling getStatutAutoriseGet.');
     }

     let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
     if (id !== undefined && id !== null) {
       queryParameters = queryParameters.set('id_opp', <any>id);
     }

     let headers = this.defaultHeaders;

     // authentication (Acess_Token) required
     if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
       headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
     }

     // to determine the Accept header
     let httpHeaderAccepts: string[] = [
     ];
     const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
     if (httpHeaderAcceptSelected != undefined) {
       headers = headers.set('Accept', httpHeaderAcceptSelected);
     }

     // to determine the Content-Type header
     const consumes: string[] = [
     ];

     return this.httpClient.request<any>('get',`${this.basePath}/get_statut_autorise`,
       {
         params: queryParameters,
         withCredentials: this.configuration.withCredentials,
         headers: headers,
         observe: observe,
         reportProgress: reportProgress
       }
     );
   }



      /**
    * Sert a affiché les etat  de staut et leur id où le cycle de vie et le role equivalent a celui passé en paramétre
    *
    * @param id Identifiant de l'opportunite '7;etat a modifier codé en md5 (Champs obligatoire pour la modification)
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
       public getStatutAutoriseAffaireGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
          throw new Error('Required parameter cycleVie was null or undefined when calling getStatutAutoriseGet.');
        }



        let headers = this.defaultHeaders;

        // authentication (Acess_Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
          headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
          headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/get_statut_autorise_affaire/${id}`,
          {

            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
          }
        );
      }
   /**
    * Sert a affiché les fils de status passé en parametre suivant un id de status parent
    *
    * @param identifiant de status de l'etat parent
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */

   public getListActionsGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

     if (id === null || id === undefined) {
       throw new Error('Required parameter cycleVie was null or undefined when calling getStatutAutoriseGet.');
     }

     let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
     if (id !== undefined && id !== null) {
       queryParameters = queryParameters.set('id_etat_parent', <any>id);
     }

     let headers = this.defaultHeaders;

     // authentication (Acess_Token) required
     if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
       headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
     }

     // to determine the Accept header
     let httpHeaderAccepts: string[] = [
     ];
     const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
     if (httpHeaderAcceptSelected != undefined) {
       headers = headers.set('Accept', httpHeaderAcceptSelected);
     }

     // to determine the Content-Type header
     const consumes: string[] = [];

     return this.httpClient.request<any>('get',`${this.basePath}/listaction`,
       {
         params: queryParameters,
         withCredentials: this.configuration.withCredentials,
         headers: headers,
         observe: observe,
         reportProgress: reportProgress
       }
     );
   }


      /**
    * Sert a affiché les fils de status passé en parametre suivant un id de status parent
    *
    * @param identifiant de status de l'etat parent
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
       public getListActionsAffaireGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
          throw new Error('Required parameter cycleVie was null or undefined when calling getStatutAutoriseGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Acess_Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
          headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
          headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [];

        return this.httpClient.request<any>('get',`${this.basePath}/listaction_affaire/${id}`,
          {

            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
          }
        );
      }

   public GetTreeView(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



     let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});


     let headers = this.defaultHeaders;

     // authentication (Acess_Token) required
     if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
       headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
     }

     // to determine the Accept header
     let httpHeaderAccepts: string[] = [
     ];
     const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
     if (httpHeaderAcceptSelected != undefined) {
       headers = headers.set('Accept', httpHeaderAcceptSelected);
     }

     // to determine the Content-Type header
     const consumes: string[] = [];

     return this.httpClient.request<any>('get',`${this.basePath}/get_etat_opp_tree?`,
       {
         params: queryParameters,
         withCredentials: this.configuration.withCredentials,
         headers: headers,
         observe: observe,
         reportProgress: reportProgress
       }
     );
   }




   public GetTreeViewAffaire(excludedStatus , observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});


    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const body ={
      "excludedStatus": excludedStatus
    }

    return this.httpClient.request<any>('post',`${this.basePath}/get_etat_dossier_tree?`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        body:body,
      }
    );
  }



     /**
    * Sert lister les informations de tous les services (categories status opp)
    *
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
      public getLisServicesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

       let headers = this.defaultHeaders;

       // authentication (Acess_Token) required
       if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
         headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
       }

       // to determine the Accept header
       let httpHeaderAccepts: string[] = [
       ];
       const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
       if (httpHeaderAcceptSelected != undefined) {
         headers = headers.set('Accept', httpHeaderAcceptSelected);
       }

       // to determine the Content-Type header
       const consumes: string[] = [];

       return this.httpClient.request<any>('get',`${this.basePath}/list_opp_services`,
         {
           withCredentials: this.configuration.withCredentials,
           headers: headers,
           observe: observe,
           reportProgress: reportProgress
         }
       );
     }

 }
