import { Utilisateur } from 'src/app/entity/TicketsAndSupport/FilterTicket';
import { Validators, FormControl } from '@angular/forms';
import { startWith, map, tap, catchError } from 'rxjs/operators';
import { ApiStatusService } from 'src/app/services/ApiStatus/api-status.service';
import { ApiUsersService } from 'src/app/services/ApiUsers/api-users.service';
import { ApiCampagniesService } from 'src/app/services/ApiCompagnies/api-compagnies.service';
import { ApiLocationServices } from 'src/app/services/ApiLocation/api-location.service';
import { Zone } from 'src/app/entity/Zone';
import { GroupUser } from 'src/app/entity/User/GroupUser';
import { UserAdminList } from 'src/app/entity/User/UserAdminList';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { OrganismeListe } from 'src/app/entity/OrganismeListe';
import { Observable, forkJoin } from 'rxjs';
import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  SimpleChanges,
  ChangeDetectorRef,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
 
import { Acces } from 'src/app/entity/User/UserAdmin';
import { UtilisateurOpp } from 'src/app/entity/Affaires/FilterAffaires';
@Component({
  selector: 'app-filter-user-opp',
  templateUrl: './filter-user-opp.component.html',
  styleUrls: ['./filter-user-opp.component.css'],
})
export class FilterUserOppComponent implements OnInit, OnChanges {
   
  CreateurSelectionees = [];
  AffectationsSelectionees = [];
  GroupesAffectesSelectionees = [];
  GroupesCreateurSelectionees = [];
  OrganismeSelectionees = [];
  ZonesSelectionees = [];
  ServiceSelectionnees = [];

  // Mat Chips Organisme
  visible = false;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  organismeFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredOrganisme: Observable<OrganismeListe[]>;
  ListOrganisme: string[] = [];
  allOrganisme: OrganismeListe[] = [];
  filtre: any;
  @ViewChild('OrganismeInput') OrganismeInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoOrganisme') matAutocompleteOrganisme: MatAutocomplete;

  // Mat Chips Createur
  CreateurFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredCreateur: Observable<UserAdminList[]>;
  ListCreateur: string[] = [];
  allCreateur: UserAdminList[] = [];

  @ViewChild('CreateurInput') CreateurInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoCreateur') matAutocompleteCreateur: MatAutocomplete;

  // Mat Chips Liste Affectation
  listeAffectationFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredListeAffectation: Observable<UserAdminList[]>;
  ListAffectation: string[] = [];
  allListeAffectation: UserAdminList[] = [];

  @ViewChild('listeAffectationInput') listeAffectationInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoListeAffectation') matAutocompleteListeAffectation: MatAutocomplete;

  // Mat Chips Groupe Affécté
  groupeAffecteeFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredGroupeAffectee: Observable<GroupUser[]>;
  ListGroupeAffectee: string[] = [];
  // allGroupeAffectee: GroupUser[] = [];

  @ViewChild('groupeAffecteeInput') groupeAffecteeInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoGroupeAffectee') matAutocompleteGroupeAffectee: MatAutocomplete;

  // Mat Chips Groupe createur
  groupeCreateurFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredGroupeCreateur: Observable<GroupUser[]>;
  ListGroupeCreateur: string[] = [];

  @ViewChild('groupeCreateurInput') groupeCreateurInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoGroupeCreateur') matAutocompleteGroupeCreateur: MatAutocomplete;

  // Mat Chips Zones
  zoneFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredZones: Observable<Zone[]>;
  ListZones: string[] = [];
  allZones: Zone[] = [];

  @ViewChild('ZonesInput') ZonesInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoZones') matAutocompleteZones: MatAutocomplete;

  // Mat Chips service
  serviceFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredService: Observable<Acces[]>;
  ListService: string[] = [];
  // allService: any[] = [];

  @ViewChild('serviceInput') serviceInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoService') matAutocompleteService: MatAutocomplete;
  getFilteredService: any[];

  filterUtilisateurOpp: UtilisateurOpp = new UtilisateurOpp();
  @Input() Reset: any;
  @Output() returnToparent = new EventEmitter<boolean>();
  @Output() onPushFilterUtilisateurOpp: EventEmitter<UtilisateurOpp> = new EventEmitter();

  constructor(
    private ApiCampagniesService: ApiCampagniesService,
    private readonly apiStatusService: ApiStatusService,
    private apiUsersService: ApiUsersService,
    private ApiLocationService: ApiLocationServices,
    private chRef: ChangeDetectorRef
  ) {}

  // Organisme -----------------------------------------------------------------------------------------------
  selectedOrganisme(event: MatAutocompleteSelectedEvent): void {
    if (!this.OrganismeSelectionees.includes(event.option.value)) {
      this.ListOrganisme.push(event.option.viewValue);
      this.OrganismeSelectionees.push(event.option.value);
    }
    this.OrganismeInput.nativeElement.value = '';
    this.organismeFromCtrl.setValue(null);
    this.EmitFilterUtilisateurOpp();
  }

  removeOrganisme(organisme: string): void {
    const index = this.ListOrganisme.indexOf(organisme);
    if (index >= 0) {
      this.ListOrganisme.splice(index, 1);
      this.OrganismeSelectionees.splice(index, 1);
    }
  }

  private _filterOrganisme(): OrganismeListe[] {
    let filterValue = this.organismeFromCtrl.value.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.allOrganisme.filter((organisme) =>
      organisme.nom != null ? organisme.nom.toLowerCase().includes(filterValue.toLowerCase()) : null
    );
  }

  // Createur -----------------------------------------------------------------------------------------------
  selectedCreateur(event: MatAutocompleteSelectedEvent): void {
    if (!this.CreateurSelectionees.includes(event.option.value)) {
      this.ListCreateur.push(event.option.viewValue);
      this.CreateurSelectionees.push(event.option.value);
    }
    this.CreateurInput.nativeElement.value = '';
    this.CreateurFromCtrl.setValue(null);
    this.EmitFilterUtilisateurOpp();
  }

  removeCreateur(Createur: string): void {
    const index = this.ListCreateur.indexOf(Createur);
    if (index >= 0) {
      this.ListCreateur.splice(index, 1);
      this.CreateurSelectionees.splice(index, 1);
    }
  }

  private _filterCreateur(): UserAdminList[] {
    let filterValue = this.CreateurFromCtrl.value.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.allCreateur.filter((Createur) =>
   
      (Createur.nom != null && Createur.nom.toLowerCase().includes(filterValue)) ||
    (Createur.prenom != null && Createur.prenom.toLowerCase().includes(filterValue))
    );
  }

  // Liste Affectation -----------------------------------------------------------------------------------------------
  selectedListeAffectation(event: MatAutocompleteSelectedEvent): void {
    if (!this.AffectationsSelectionees.includes(event.option.value)) {
      this.ListAffectation.push(event.option.viewValue);
      this.AffectationsSelectionees.push(event.option.value);
    }
    this.listeAffectationInput.nativeElement.value = '';
    this.listeAffectationFromCtrl.setValue(null);
    this.EmitFilterUtilisateurOpp();
  }

  removeListeAffectation(affectation: string): void {
    const index = this.ListAffectation.indexOf(affectation);
    if (index >= 0) {
      this.ListAffectation.splice(index, 1);
      this.AffectationsSelectionees.splice(index, 1);
    }
  }

  private _filterListeAffectation(): UserAdminList[] {
    let filterValue = this.listeAffectationFromCtrl.value.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.allListeAffectation.filter((affectation) =>
   
      (affectation.nom != null && affectation.nom.toLowerCase().includes(filterValue)) ||
    (affectation.prenom != null && affectation.prenom.toLowerCase().includes(filterValue))
    );
  }

  // Groupe Affectee -----------------------------------------------------------------------------------------------
  selectedGroupeAffectee(event: MatAutocompleteSelectedEvent): void {
    if (!this.GroupesAffectesSelectionees.includes(event.option.value)) {
      this.ListGroupeAffectee.push(event.option.viewValue);
      this.GroupesAffectesSelectionees.push(event.option.value);
    }
    this.groupeAffecteeInput.nativeElement.value = '';
    this.groupeAffecteeFromCtrl.setValue(null);
  }

  removeGroupeAffectee(groupeAffectee: string): void {
    const index = this.ListGroupeAffectee.indexOf(groupeAffectee);
    if (index >= 0) {
      this.ListGroupeAffectee.splice(index, 1);
      this.GroupesAffectesSelectionees.splice(index, 1);
    }
  }

  // private _filterGroupeAffectee(): GroupUser[] {
  //   let filterValue = this.groupeAffecteeFromCtrl.value.toString().toLowerCase();
  //   if (filterValue === null) filterValue = '';
  //   return this.allGroupeAffectee.filter((groupeAffectee) =>
  //     groupeAffectee.nom != null ? groupeAffectee.nom.toLowerCase().indexOf(filterValue.toLowerCase()) === 0 : null
  //   );
  // }

  // Groupe createur -----------------------------------------------------------------------------------------------
  selectedGroupeCreateur(event: MatAutocompleteSelectedEvent): void {
    this.ListGroupeCreateur.push(event.option.viewValue);
    this.GroupesCreateurSelectionees.push(event.option.value);
    this.groupeCreateurInput.nativeElement.value = '';
    this.groupeCreateurFromCtrl.setValue(null);
  }

  removeGroupeCreateur(groupeCreateur: string): void {
    const index = this.ListGroupeCreateur.indexOf(groupeCreateur);
    if (index >= 0) {
      this.ListGroupeCreateur.splice(index, 1);
      this.GroupesCreateurSelectionees.splice(index, 1);
    }
  }

  // private _filterGroupeCreateur(): GroupUser[] {
  //   let filterValue = this.groupeCreateurFromCtrl.value.toString().toLowerCase();
  //   if (filterValue === null) filterValue = '';
  //   return this.allGroupeAffectee.filter((groupeCreateur) =>
  //     groupeCreateur.nom != null ? groupeCreateur.nom.toLowerCase().indexOf(filterValue.toLowerCase()) === 0 : null
  //   );
  // }

  // Zones -----------------------------------------------------------------------------------------------

  selectedZones(event: MatAutocompleteSelectedEvent): void {
    if (!this.ZonesSelectionees.includes(event.option.value)) {
      this.ListZones.push(event.option.viewValue);
      this.ZonesSelectionees.push(event.option.value);
    }
    this.ZonesInput.nativeElement.value = '';
    this.zoneFromCtrl.setValue(null);
    this.EmitFilterUtilisateurOpp();
  }

  removeZones(Zones: string): void {
    const index = this.ListZones.indexOf(Zones);
    if (index >= 0) {
      this.ListZones.splice(index, 1);
      this.ZonesSelectionees.splice(index, 1);
    }
  }

  // private _filterZones(): Zone[] {
  //   let filterValue = this.zoneFromCtrl.value.toString().toLowerCase();
  //   if (filterValue === null) filterValue = '';
  //   return this.allZones.filter((Zones) =>
  //     Zones.nom != null ? Zones.nom.toLowerCase().indexOf(filterValue.toLowerCase()) === 0 : null
  //   );
  // }

  // Service -----------------------------------------------------------------------------------------------

  selectedService(event: MatAutocompleteSelectedEvent): void {
    if (!this.ServiceSelectionnees.includes(event.option.value)) {
      this.ListService.push(event.option.viewValue);
      this.ServiceSelectionnees.push(event.option.value);
    }
    this.serviceInput.nativeElement.value = '';
    this.serviceFromCtrl.setValue(null);
  }

  removeService(serv: string): void {
    const index = this.ListService.indexOf(serv);
    if (index >= 0) {
      this.ListService.splice(index, 1);
      this.ServiceSelectionnees.splice(index, 1);
    }
  }

  // private _filterService(): any[] {
  //   let filterValue = this.serviceFromCtrl.value.toString().toLowerCase();
  //   if (filterValue === null) filterValue = '';
  //   this.getFilteredService = this.allService.filter((element) => element.libele != null);
  //   return this.getFilteredService.filter((serv) =>
  //     serv.libele != null ? serv.libele.toLowerCase().indexOf(filterValue) === 0 : null
  //   );
  // }

  ngOnInit(): void {
    this.apiUsersService.getAllUsers().subscribe((Response: UserAdminList[]) => {
      this.allListeAffectation = Response;
      this.allCreateur = Response;
      if (this.filtre) {
        if (this.filterUtilisateurOpp?.list_createurs.length > 0) {
          this.ListCreateur = this.allCreateur
            .filter((createur) => this.filterUtilisateurOpp.list_createurs.includes(createur.id))
            .map((createurAff) => {
              this.CreateurSelectionees.push(createurAff.id);
              return createurAff.nom + ' ' + createurAff.prenom;
            });
        }
        if (this.filterUtilisateurOpp?.list_affectation.length > 0) {
          this.ListAffectation = this.allListeAffectation
            .filter((affecte) => this.filterUtilisateurOpp.list_affectation.includes(affecte.id))
            .map((affecteAff) => {
              this.AffectationsSelectionees.push(affecteAff.id);
              return affecteAff.nom + ' ' + affecteAff.prenom;
            });
        }
      }
    });

    // this.apiStatusService
    //   .getLisServicesGet()
    //   .pipe(
    //     tap(({ CategoryStatusOpp }) => {
    //       this.allService = CategoryStatusOpp;
    //     })
    //   )
    //   .subscribe();
    // this.apiUsersService.getGroupUsers().subscribe((Response: GroupUser[]) => {
    //   this.allGroupeAffectee = Response;
    // });
    //   this.ApiLocationService.getListeZone().subscribe((Response: Zone[]) => {
    //     this.allZones = Response;
    //     if (this.filtre) {
    //       if (this.filterUtilisateurOpp.zones.length > 0) {
    //         this.ListZones = this.allZones
    //           .filter(zone => this.filterUtilisateurOpp.zones.includes(zone.id))
    //           .map(zoneAff => {
    //             this.ZonesSelectionees.push(zoneAff.id);

    //             return zoneAff.nom
    //           });
    //       }
    // }
    //   });
    this.ApiCampagniesService.GetAllOrganisme().subscribe((Response: OrganismeListe[]) => {
      this.allOrganisme = Response;
      if (this.filtre) {
        if (this.filterUtilisateurOpp?.organismes.length > 0) {
          this.ListOrganisme = this.allOrganisme
            .filter((org) => this.filterUtilisateurOpp.organismes.includes(org.id))
            .map((orgAff) => {
              this.OrganismeSelectionees.push(orgAff.id);
              return orgAff.nom;
            });
        }
      }
    });

    this.filteredCreateur = this.CreateurFromCtrl.valueChanges.pipe(
      startWith(null),
      map((Createur: string | null) => (Createur ? this._filterCreateur() : this.allCreateur.slice()))
    );

    // this.filteredService = this.serviceFromCtrl.valueChanges.pipe(
    //   startWith(null),
    //   map((serv: string | null) => (serv ? this._filterService() : this.allService.slice()))
    // );

    // this.filteredGroupeCreateur = this.groupeCreateurFromCtrl.valueChanges.pipe(
    //   startWith(null),
    //   map((groupeCreateur: string | null) =>
    //     groupeCreateur ? this._filterGroupeCreateur() : this.allGroupeAffectee.slice()
    //   )
    // );

    // this.filteredGroupeAffectee = this.groupeAffecteeFromCtrl.valueChanges.pipe(
    //   startWith(null),
    //   map((groupeAffectee: string | null) =>
    //     groupeAffectee ? this._filterGroupeAffectee() : this.allGroupeAffectee.slice()
    //   )
    // );

    this.filteredListeAffectation = this.listeAffectationFromCtrl.valueChanges.pipe(
      startWith(null),
      map((Createur: string | null) => (Createur ? this._filterListeAffectation() : this.allCreateur.slice()))
    );

    this.filteredOrganisme = this.organismeFromCtrl.valueChanges.pipe(
      startWith(null),
      map((organisme: string | null) => (organisme ? this._filterOrganisme() : this.allOrganisme.slice()))
    );

    // this.filteredZones = this.zoneFromCtrl.valueChanges.pipe(
    //   startWith(null),
    //   map((Zones: string | null) => (Zones ? this._filterZones() : this.allZones.slice()))
    // );
    this.filtre = localStorage.getItem('filtreAffaire');
    this.filtre = JSON.parse(this.filtre);
    if (this.filtre) {
      this.filterUtilisateurOpp = this.filtre.opp?.utilisateur;
      const allApi: Observable<any>[] = [
        this.apiUsersService.getAllUsers(),
        this.ApiCampagniesService.GetAllOrganisme(),
      ];
      forkJoin(allApi)
        .pipe(
          catchError((error) => {
            console.error('Error:', error);
            return [];
          })
        )
        .subscribe((responses) => {
          this.EmitFilterUtilisateurOpp();
        });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.resetUtilisateurOppForm(changes.Reset.currentValue);
  }

  resetUtilisateurOppForm(Reset) {
    this.chRef.detectChanges();
    if (Reset === true) {
      this.ListOrganisme = [];
      this.filterUtilisateurOpp.organismes = this.OrganismeSelectionees = [];
      this.ListCreateur = [];
      this.filterUtilisateurOpp.list_createurs = this.CreateurSelectionees = [];
      this.ListAffectation = [];
      this.filterUtilisateurOpp.list_affectation = this.AffectationsSelectionees = [];
      this.ListGroupeAffectee = [];
      this.filterUtilisateurOpp.groupes_affecte = this.GroupesAffectesSelectionees = [];
      this.ListGroupeCreateur = [];
      this.filterUtilisateurOpp.groupes_createurs = this.GroupesCreateurSelectionees = [];
      this.ListZones = [];
      this.filterUtilisateurOpp.zones = this.ZonesSelectionees = [];
      this.ListService = [];
      this.filterUtilisateurOpp.services = this.ServiceSelectionnees = [];
      this.filterUtilisateurOpp.operateur_recherche_group = '';
      this.filterUtilisateurOpp.operateur_recherche_group_affet = '';
      Reset = false;
      this.returnToparent.emit(Reset);
      this.onPushFilterUtilisateurOpp.emit(this.filterUtilisateurOpp);
    }
  }

  EmitFilterUtilisateurOpp() {
    if(this.filterUtilisateurOpp){
          this.filterUtilisateurOpp.groupes_affecte = this.GroupesAffectesSelectionees;
    this.filterUtilisateurOpp.groupes_createurs = this.GroupesCreateurSelectionees;
    this.filterUtilisateurOpp.list_affectation = this.AffectationsSelectionees;
    this.filterUtilisateurOpp.list_createurs = this.CreateurSelectionees;
    this.filterUtilisateurOpp.services = this.ServiceSelectionnees;
    this.filterUtilisateurOpp.zones = this.ZonesSelectionees;
    this.filterUtilisateurOpp.organismes = this.OrganismeSelectionees;
    this.onPushFilterUtilisateurOpp.emit(this.filterUtilisateurOpp);
    }

  }
}
