import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';
import { environment } from '../../../environments/environment';
import { CustomHttpUrlEncodingCodec } from '../encoder';

@Injectable({
  providedIn: 'root'
})
export class DocumentationService {
  protected basePath = environment.api_url;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  selectedGroup: any = null; // Store the selected group
  selectedApi: any = null;
  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }  }

  callApi(apiName: string, data: any, endpoint: string, queryParams: { [key: string]: any } = {},observe: any = 'body',
    reportProgress: boolean = false): Observable<any> {
    // Build query parameters dynamically
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    for (const key in queryParams) {
      if (queryParams[key] !== null && queryParams[key] !== undefined) {
        queryParameters = queryParameters.set(key, queryParams[key]);
      }
    }
  
    // Default headers setup
    let headers = this.defaultHeaders;

    // Authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // To determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
  
    // Perform the HTTP request with query parameters
    return this.httpClient.request<any>('GET', `${this.basePath}${endpoint}`, {
      // body: data,
      params: queryParameters, // Attach query parameters here
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }
  
}
