import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Configuration } from '../configuration';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { BASE_PATH } from '../variables';
import * as Papa from 'papaparse';
@Injectable({
  providedIn: 'root'
})
export class EcheancierService {
  protected basePath = environment.api_url;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {

    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }   }



   public listEcheanciersGet(
    page: any,
    limit: any,
   all_filtres: any,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any> {
    // let sort: string;
 
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page === null || page === undefined) {
      throw new Error('Required parameter filtresEndCreation was null or undefined when calling listAffairesGet.');
    }else{
      queryParameters = queryParameters.set('page', <any>page);
    }

    if (limit === null || limit === undefined) {
      throw new Error('Required parameter limit was null or undefined when calling listAffairesGet.');
    }else{
      queryParameters = queryParameters.set('limit', <any>limit);
    }

  /*   if (all_filtres === null || all_filtres === undefined) {
      throw new Error('Required parameter filtresStartCreation was null or undefined when calling listAffairesGet.');
    } */

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    headers = headers.set('Content-Type', 'application/json');

   
    return this.httpClient.request<any>('post', `${this.basePath}/echeancier/list_echeanciers`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      observe: observe,
      headers: headers,
      reportProgress: reportProgress,
       body: all_filtres,
    });
  }



  public listeAllStatusEcheance( observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    const queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get',`${this.basePath}/echeancier/list_status_echeance`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }


  public updateStatusEcheancier(statusEcheancier: any) {
    let headers = this.defaultHeaders;

    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('put', `${this.basePath}/echeancier/update_echeanceier_status`, {
      body: statusEcheancier,
      headers: headers,
    });
  }


  public listeAllStatusEcheancier( observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    const queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get',`${this.basePath}/echeancier/list_status_echeancier`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }



  public listeAllMotif( observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    const queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get',`${this.basePath}/echeancier/list_motif`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

 /*  exportToCsv(data: any[], filename: string) {
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    URL.revokeObjectURL(link.href);
  } */



  exportToCsv(data: any[], filename: string): void {
    const csvData = this.convertToCsv(data);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();

    window.URL.revokeObjectURL(url);
  }

  private convertToCsv(data: any[]): string {
    const csvRows = [];
    const headers = [
      'id_echeancier',
      'id_affaire',
      'Client',
      'date_debut_periode_echeancier',
      'date_fin_periode_echeancier',
      'Echeancier_statut',
      'id_echeance',
      'date_debut_periode_echeance',
      'date_fin_periode_echeance',
      'date_prelevement_echeance',
      'id_status_echeance',
      'montant_echeance',
      'status_echeance'
    ];
    csvRows.push(headers.join(','));
  
    for (const item of data) {
      const echeancierId = item.id;
      const affaireId = item.affaire_id;
      const echeancierClient = item.name +''+ item.surname ;
      const echeancierDebutPeriode = this.formatDate(item.date_debut_periode);
      const echeancierFinPeriode = this.formatDate(item.date_fin_periode);
      const echeancierStatut = item.status;
      

      for (const echeance of item.echeances) {
        const echeanceData = [
          echeancierId,
          affaireId,
          echeancierClient,
          echeancierDebutPeriode,
          echeancierFinPeriode,
          echeancierStatut,
          echeance.id,
          echeance.date_debut_periode,
          echeance.date_fin_periode,
          echeance.date_prelevement,
          echeance.id_status,
          echeance.montant,
          echeance.status
        ];
        csvRows.push(echeanceData.join(','));
      }
    }
  
    return csvRows.join('\n');
  }

  private formatDate(dateString: string): string {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: 'UTC'
    };
  
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    
    // Replace the default separator with "_"
    return formattedDate.replace(/[,]/g, '_');
  }


  public addEcheancier(echeancier: any): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/echeancier/v1/insert`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
  
      body: echeancier,
    });
  }

}
