<div class="row operateurContainer">

    <div class="col-2 d-flex justify-content-center align-items-center">

      
        <button (click)="selectOptionStatut('=')" class="operateur mr-3" [ngClass]="{ 'active':filterEcheancier.operateur_status_echeanciers === '=' }">
          <i class="fa-solid fa-equals"></i>
      </button>
      <button (click)="selectOptionStatut('!=')"  class="operateur" [ngClass]="{ 'active': filterEcheancier.operateur_status_echeanciers=== '!=' }">
          <i class="fa-solid fa-not-equal"></i>
      </button>
        </div>

   
    <div class="col-10 example-full-width">
        <mat-form-field class="example-full-width w-100">
          <mat-label> {{'languages.listing.Statut' | translate}} </mat-label>
          <mat-chip-list #chipList>
              <mat-chip *ngFor="let status of ListStatus" [selectable]="selectablee" [removable]="removablee" (removed)="removeStatus(status)" (removed)="EmitFilterEcheancier()">
                  {{status}}
                  <i matChipRemove *ngIf="removablee" class="fa fa-times" aria-hidden="true"></i>
              </mat-chip>
              <input (click)="EmitFilterEcheancier()" placeholder="{{'languages.opportunite.label_status_select' | translate}}" #statusInput 
              [formControl]="StatusFromCtrl" [matAutocomplete]="autoo" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
          </mat-chip-list>
          <mat-autocomplete #autoo="matAutocomplete" (optionSelected)="selectedStatus($event)">
              <mat-option *ngFor="let status of filteredStatus | async" [value]="status.id">
                  {{status.libelle}}
              </mat-option>
          </mat-autocomplete>
      </mat-form-field>
    </div>
</div>







<div class="row operateurContainer">
    <div class="col-2 d-flex justify-content-center align-items-center">

      
        <button (click)="selectOptionMotif('=')" class="operateur mr-3" [ngClass]="{ 'active':filterEcheancier.operateur_motif === '=' }">
          <i class="fa-solid fa-equals"></i>
      </button>
      <button (click)="selectOptionMotif('!=')"  class="operateur" [ngClass]="{ 'active': filterEcheancier.operateur_motif=== '!=' }">
          <i class="fa-solid fa-not-equal"></i>
      </button>
        </div>
    
    <div class="col-10">
        <mat-form-field class="example-full-width w-100">
          <mat-label>{{'languages.affaire.Motif' | translate}}</mat-label>
          <mat-chip-list #chipsList>
              <mat-chip *ngFor="let motif of ListMotif" [selectable]="selectable" [removable]="removable"
               (removed)="removeMotif(motif)" (removed)="EmitFilterEcheancier()">
                  {{motif}}
                  <i matChipRemove *ngIf="removable" class="fa fa-times" aria-hidden="true"></i>

              </mat-chip>
              <input placeholder="{{'languages.opportunite.label_status_Motif' | translate}}" #MotifInput 
              [formControl]="MotifFromCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipsList"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
          </mat-chip-list>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedMotif($event)">
              <mat-option *ngFor="let motif of filteredMotif | async" [value]="motif.id">
                  {{motif.libelle}}
              </mat-option>
          </mat-autocomplete>
      </mat-form-field>
    </div>
    </div>
    <div class="col-12">
        <mat-form-field class="w-100 fontsDate" >
        <mat-date-range-input [rangePicker]="pickerDC">
          <input matStartDate (change)="EmitFilterEcheancier()" (click)="openDatePeriod()" readonly [(ngModel)]="date_debut_periode" placeholder="{{'languages.affaire.periodDate' | translate}}" />
          <input matEndDate (change)="EmitFilterEcheancier()" (click)="openDatePeriod()" readonly [(ngModel)]="date_fin_periode" placeholder="{{'languages.opportunite.label_a' | translate}}" />

      </mat-date-range-input>
      <mat-datepicker-toggle class="m-0 p-0" matPrefix [for]="pickerDC">    <mat-icon matDatepickerToggleIcon>
        <img src="assets/icons/calendar.svg" alt="calendar" > 
      </mat-icon></mat-datepicker-toggle>
      <img  class="remouveDate" matSuffix (keydown.enter)="clearDatePeriod()"  (click)="clearDatePeriod()" src="assets/icons/delete-left.svg" alt="delete" alt="delete">
      <mat-date-range-picker (closed)="EmitFilterEcheancier()" [calendarHeaderComponent]="rangeDatePeriod" #pickerDC></mat-date-range-picker>
        </mat-form-field>
      </div>





