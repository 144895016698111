/**
 * Documentation Geoprod
 * Description des web services de Remoteprod
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ModelObject } from '../model/modelObject';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {environment} from "../../../environments/environment";


@Injectable()
export class ApiAutoService {

  protected basePath = environment.api_url;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   * Afficher les informations sur une version vehicule par cle
   *
   * @param cle Cle de l&#x27;operation soit par min et cnit ou par marque modelle carburant puissance ou par id
   * @param q &lt;p&gt;Regroupe le min et cnit de version separer par &#x27;_&#x27; &lt;br&gt; Obligatoire seulement si l&#x27;operation est by min and cnit  &lt;/p&gt;
   * @param marque &lt;p&gt; Marque de Vehicule &lt;br&gt; Obligatoire seulement si l&#x27;operation est by marque modelle carburant puissance  &lt;/p&gt;
   * @param modelle &lt;p&gt; modele de Vehicule &lt;br&gt; Obligatoire seulement si l&#x27;operation est by marque modelle carburant puissance  &lt;/p&gt;
   * @param carburant &lt;p&gt; carburant de Vehicule &lt;br&gt; Obligatoire seulement si l&#x27;operation est by marque modelle carburant puissance  &lt;/p&gt;
   * @param nbC &lt;p&gt; Nombre des cheveaux de Vehicule &lt;br&gt; Obligatoire seulement si l&#x27;operation est by marque modelle carburant puissance  &lt;/p&gt;
   * @param id &lt;p&gt; Identifiant de la marque codé en md5 &lt;br&gt; Obligatoire seulement si l&#x27;operation est by id  &lt;/p&gt;
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listVersionGet(cle: string, q?: ModelObject, marque?: ModelObject, modelle?: ModelObject, carburant?: ModelObject, nbC?: ModelObject, id?: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public listVersionGet(cle: string, q?: ModelObject, marque?: ModelObject, modelle?: ModelObject, carburant?: ModelObject, nbC?: ModelObject, id?: ModelObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public listVersionGet(cle: string, q?: ModelObject, marque?: ModelObject, modelle?: ModelObject, carburant?: ModelObject, nbC?: ModelObject, id?: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public listVersionGet(cle: string, q?: ModelObject, marque?: ModelObject, modelle?: ModelObject, carburant?: ModelObject, nbC?: ModelObject, id?: ModelObject, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (cle === null || cle === undefined) {
      throw new Error('Required parameter cle was null or undefined when calling listVersionGet.');
    }







    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (cle !== undefined && cle !== null) {
      queryParameters = queryParameters.set('cle', <any>cle);
    }
    if (q !== undefined && q !== null) {
      queryParameters = queryParameters.set('q', <any>q);
    }
    if (marque !== undefined && marque !== null) {
      queryParameters = queryParameters.set('marque', <any>marque);
    }
    if (modelle !== undefined && modelle !== null) {
      queryParameters = queryParameters.set('modelle', <any>modelle);
    }
    if (carburant !== undefined && carburant !== null) {
      queryParameters = queryParameters.set('carburant', <any>carburant);
    }
    if (nbC !== undefined && nbC !== null) {
      queryParameters = queryParameters.set('nb_c', <any>nbC);
    }
    if (id !== undefined && id !== null) {
      queryParameters = queryParameters.set('id', <any>id);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get',`${this.basePath}/list_version`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Permet d&#x27;afficher l&#x27;identifiant et les modele de la marque vehicule suivant son identifiant de la une partie de la marque codé en md5 et une partie de sa modele ou afficher les marque similare au pramétre data[q] et son identifiant
   *
   * @param cle &lt;p&gt; Cle de l&#x27;opeartion , marque ou modéle &lt;br&gt; Si vous souhaiter afficher les marque similaire a data[q], seumlement data[q] est oblagatoire &lt;br&gt; Si vous souhaiter afficher les modele similaire a data[q] et l&#x27;identifiant de la marque sont obligatoire pour rafficner la recherche&lt;/p&gt;
   * @param dataQ Une partie de marque de vehicule
   * @param idMarque identifiant de la marque codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public marqueOrModeleVehiculeGet(cle: string, dataQ: ModelObject, idMarque?: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public marqueOrModeleVehiculeGet(cle: string, dataQ: ModelObject, idMarque?: ModelObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public marqueOrModeleVehiculeGet(cle: string, dataQ: ModelObject, idMarque?: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public marqueOrModeleVehiculeGet(cle: string, dataQ: ModelObject, idMarque?: ModelObject, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (cle === null || cle === undefined) {
      throw new Error('Required parameter cle was null or undefined when calling marqueOrModeleVehiculeGet.');
    }

    if (dataQ === null || dataQ === undefined) {
      throw new Error('Required parameter dataQ was null or undefined when calling marqueOrModeleVehiculeGet.');
    }


    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (cle !== undefined && cle !== null) {
      queryParameters = queryParameters.set('cle', <any>cle);
    }
    if (dataQ !== undefined && dataQ !== null) {
      queryParameters = queryParameters.set('data[q]', <any>dataQ);
    }
    if (idMarque !== undefined && idMarque !== null) {
      queryParameters = queryParameters.set('id_marque', <any>idMarque);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get',`${this.basePath}/marque_or_modele_vehicule`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Sert a afficher les informations sur l&#x27;auto version suivant une partie de son type_mines ou cnit
   *
   * @param dataQ Une partie de  nom ou prenom de add_prospect
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public versionGVehiculeGet(dataQ: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public versionGVehiculeGet(dataQ: ModelObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public versionGVehiculeGet(dataQ: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public versionGVehiculeGet(dataQ: ModelObject, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (dataQ === null || dataQ === undefined) {
      throw new Error('Required parameter dataQ was null or undefined when calling versionGVehiculeGet.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (dataQ !== undefined && dataQ !== null) {
      queryParameters = queryParameters.set('data[q]', <any>dataQ);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get',`${this.basePath}/version_g_vehicule`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
