<div class=" m-0">

    <div class=" add_panel m-1"  >
        <div class="row " >

          <button type="button" class="btn-dt-send w-100 pt-1 mb-2">
            <i class="fa-solid fa-circle-xmark float-left fa-lg ml-2 mt-2 " (keydown.enter)="inexpand()" (click)="inexpand()"></i>&nbsp;
            {{typeTache ==='' ?  ('languages.ticket.addTask' | translate) : ('languages.ticket.updateTask' | translate)}}
          </button>
        </div>


        <div class="row">
            <div class="col-md-12 mt-3">
                <mat-form-field class="input-width area w-100 " >
                    <mat-label> {{'languages.ticket.title' | translate}}</mat-label>
                    <input matInput  [(ngModel)]="taskDetail.titre" name="titre" titre="ngModel" required [disabled]="loaderAjout || taskDetail.statut === 1">
                        <mat-error><span>  {{'languages.ticket.checkTitle' | translate}}
                        </span></mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-12" *ngIf="typeTache!=='affecte'" >
                <h4 class="description"> {{'languages.opportunite.details.affecte' | translate}}</h4>
                
                <mat-spinner *ngIf="loader" [diameter]="20"></mat-spinner>
                <div class="row" *ngIf="typeTache!=='affecte'">
                    <mat-form-field class=" w-100" >
                        <input type="text" matInput required  [(formControl)]="myControl" [matAutocomplete]="auto" readonly [disabled]="loaderAjout || taskDetail.statut === 1">
                        <mat-error><span>{{'languages.ticket.SpecifyClient' | translate}}
                        </span></mat-error>
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                          <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                            {{option.full_name}}
                          </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

            </div>

            <div class="col-md-12">
                <mat-form-field class="input-width area w-100 " >
                    <mat-label> {{'languages.opportunite.details.link' | translate}}</mat-label>
                    <input matInput [disabled]="taskDetail?.id_entity !==null && taskDetail?.id_entity!=='' " [(ngModel)]="taskDetail.url" name="lien" #lien="ngModel" [disabled]="loaderAjout || taskDetail.statut === 1"
                     pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?" >
                    <mat-error *ngIf="lien.invalid && (lien.dirty || lien.touched)"
                    class="message-erreur">
                    <mat-error *ngIf="lien.errors.pattern">
<i class="fa-solid fa-triangle-exclamation"></i>
                        Format du l'url invalide! {{'languages.role.formLinkInvalid' | translate}}
                    </mat-error>
                </mat-error>
                </mat-form-field>
            </div>
          



                <div class="col-md-12 ">
                    <mat-form-field  class=" w-100">
                        <mat-label> {{'languages.ticket.Priorite' | translate}}</mat-label>
                        <mat-select  [(ngModel)]="taskDetail.priorite" name="priorite" [disabled]="loaderAjout || taskDetail.statut === 1">
                          <mat-option  *ngFor="let priorite of priorites" [value]="priorite.id">
                            {{priorite.libelle}}
                            <i class=" {{priorite.icon}} " ></i>
                          </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-12">
                    <mat-form-field  class="  w-100">
                        <mat-label>{{'languages.opportunite.label_mainduedate' | translate}} </mat-label>
                        <input  matInput [matDatepicker]="picker"  [(ngModel)]="dateEcheance" readonly (focus)="picker.open()" [min]="dateNow" [placeholder]="'languages.opportunite.label_mainduedate' | translate" [disabled]="loaderAjout || taskDetail.statut === 1">
                        <mat-hint>DD/MM/YYYY</mat-hint>
                        <mat-icon matDatepickerToggleIcon *ngIf="dateEcheance" title="Effacer" (keydown.enter)="clearDate($event)" (click)="clearDate($event)">clear</mat-icon>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error><span> {{'languages.ticket.verifyDate' | translate}}
                        </span></mat-error>
                    </mat-form-field>

                </div>


            <div class="col-md-12 mt-2">
                <mat-form-field  class=" w-100 area">
                    <mat-label> {{'languages.ticket.description' | translate}}</mat-label>
                    <textarea matInput [(ngModel)]="taskDetail.description" [placeholder]="'languages.ticket.description' | translate">
                </textarea>
                </mat-form-field>
            </div>
            <div *ngIf="loaderAjout" class="col-12 d-flex justify-content-center text-secondary ">
                <div class="spinner">
                    <mat-spinner [diameter]="20"></mat-spinner>
                </div>
            </div>
            <div class="col-md-12 d-flex justify-content-center" *ngIf="taskDetail.statut === 0">
                <button  type="button" class=" m-2 btn-dt-save-popup " (click)="enregistrer()" [disabled]="loaderAjout" >
                    <i class="fa-solid fa-circle-check fa-lg mr-1"></i>
                    {{'languages.buttons.enregistrer' | translate}}
                  </button>

                  <button type="button" (click)="inexpand()" class="m-2 btn-dt-cancel-popup" [disabled]="loaderAjout">
                    <i class="fa-solid fa-circle-xmark fa-icon-style fa-lg mr-1"></i>
                    {{'languages.buttons.annuler' | translate}}
                  </button>
            </div>
        </div>
    </div>
</div>
