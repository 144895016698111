<div>
  <div id="login" fxLayout="column">
    <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">
      <div id="login-form">
        <div class="logo">
          <img class="logo" [src]="imgLogoSiagf_2"  alt='logo' />
        </div>

        <div class="title"> {{ 'languages.user.enterNewPassword' | translate }} </div>
        <form name="changeForm" [formGroup]="changeForm" (ngSubmit)="changePassword()" novalidate>
          <mat-form-field appearance="outline">
            <mat-label> {{ 'languages.user.newPassword' | translate }} </mat-label>
            <input matInput formControlName="password" [type]="hidePassword ? 'password' : 'text'" />
            <button mat-icon-button matSuffix (click)="togglePasswordVisibility()" type="button" aria-label="Toggle password visibility">
              <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
            </button>
            <mat-error *ngIf="changeForm.get('password').hasError('required')">
              {{ 'languages.SigafConfig.passwordRequired' | translate }}
            </mat-error>
            <mat-error *ngIf="changeForm.get('password').hasError('pattern')">
              {{ 'languages.user.passwordComplexity' | translate }}
            </mat-error>
          </mat-form-field>
          
          <mat-form-field appearance="outline">
            <mat-label> {{ 'languages.user.confirmPassword' | translate }} </mat-label>
            <input matInput formControlName="confirmPassword" [type]="hidePassword ? 'password' : 'text'" />
            <button mat-icon-button matSuffix (click)="togglePasswordVisibility()" type="button" aria-label="Toggle password visibility">
              <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
            </button>
            <mat-error *ngIf="changeForm.get('confirmPassword').hasError('required')">
              {{ 'languages.SigafConfig.confirmPasswordRequired' | translate }}
            </mat-error>
            <mat-error *ngIf="changeForm.get('confirmPassword').hasError('mustMatch')">
              {{ 'languages.user.passwordMustMatch' | translate }}
            </mat-error>
          </mat-form-field>
          

          <button [color]="colors.primary" mat-raised-button color="accent" class="submit-button" aria-label="Change Password" [disabled]="changeForm.invalid">
            {{ 'languages.buttons.changer' | translate }}
          </button>

          <div class="text-center">
            <ng-container *ngIf="show_loading_add">
              <div class="col-12 loader-spinner">
                <div class="spinner-style">
                  <mat-spinner [diameter]="30"></mat-spinner>
                </div>
              </div>
            </ng-container>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
