import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';

import { ApiOpportunitService } from '../../../services/ApiOpportunite/api-opportunite.service';
import { Gamme } from '../../../entity/Generic/Gamme';
import * as $ from 'jquery';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-gestion-gammes',
  templateUrl: './gestion-gammes.component.html',
  styleUrls: ['./gestion-gammes.component.css'],
})
export class GestionGammesComponent implements OnInit, OnDestroy {
  Gammes: Gamme[] = [];
  dataTable: any;
  miniListing: any;
  currentLanguage: string;
  inputSubject = new Subject<string>();
  constructor(
    private readonly ApiOpportunitService: ApiOpportunitService,
    private chRef: ChangeDetectorRef,
    private readonly sharedMenuObserverService: SharedMenuObserverService,
    private routes: Router,
    private translate: TranslateService
  ) {
    this.inputSubject
      .pipe(
        debounceTime(1000) // Adjust debounce time as needed
      )
      .subscribe((inputValue: string) => {
        this.filter(inputValue);
      });
  }

  onInputChange(value: string): void {
    this.inputSubject.next(value);
  }

  ngOnDestroy(): void {
    const currentRoute = this.routes.url;
    if (!currentRoute.includes('gestion-gammes')) {
      localStorage.removeItem('search');
      localStorage.removeItem('page');
    } else {
      localStorage.setItem('search', this.search);
      localStorage.setItem('page', this.page.toString());
    }
  }

  ngOnInit(): void {
    this.translate.get('languages').subscribe((object: any) => {
      this.sharedMenuObserverService.updateMenu(object.navbar.gestionGammes);
      this.miniListing = object.miniListing;
    });
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.sharedMenuObserverService.updateMenu(object.navbar.gestionGammes);
        this.miniListing = object.miniListing;
      });
    });

    this.gestListGammes();
  }

  async gestListGammes() {
    this.showloader();

    if (localStorage.getItem('page')) this.page = parseInt(localStorage.getItem('page'));
    if (localStorage.getItem('search')) this.search = localStorage.getItem('search');
    this.ApiOpportunitService.getAllGammesV1(this.limit, this.page, this.search).subscribe((data) => {
      this.Gammes = data.Data;
      this.totalLength = data.totalCount;
      this.calculate_nb_pages(this.totalLength);
      this.hideloader();
      this.chRef.detectChanges();
    });
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  limit = 15;
  page = 1;
  totalPages = 0;
  totalLength: any;
  intervale = 1;
  search: string = '';

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    localStorage.removeItem('search');
    localStorage.removeItem('page');
  }

  calculate_nb_pages(iTotalDisplayRecords) {
    this.totalPages = Math.floor(iTotalDisplayRecords / this.limit);
    if (iTotalDisplayRecords % this.limit !== 0) {
      this.totalPages += 1;
    }
  }

  first() {
    this.setPage(1);
  }

  prev() {
    this.setPage(Math.max(1, this.page - 1));
  }

  next() {
    this.setPage(Math.min(this.totalPages, this.page + 1));
  }

  last() {
    this.setPage(this.totalPages);
  }

  setPage(val: number) {
    this.page = val;
    localStorage.setItem('page', this.page.toString());
    this.gestListGammes();
  }

  isFirst(): boolean {
    return this.page === 1;
  }

  isLast(): boolean {
    return this.page === this.totalPages;
  }

  changepage(number: number) {
    this.page = number;
    localStorage.setItem('page', this.page.toString());
    this.gestListGammes();
  }

  getchangeLimite() {
    if (this.intervale <= this.totalPages) {
      this.page = this.intervale;
      if (this.page <= this.totalPages) {
        this.gestListGammes();
      }
    }
  }

  updateListGamme() {
    this.page = 1;
    this.gestListGammes();
  }

  filter(event) {
    this.page = 1;

    localStorage.setItem('search', this.search);
    localStorage.setItem('page', this.page.toString());
    this.gestListGammes();
  }
}
