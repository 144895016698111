import {FormField} from './FormField';
import {CustomItems, CustomOptions} from './SharedElementsFormly';

export class MultiselectFormly extends FormField {

  constructor() {
    super();
    super.icon="fa fa-angle-double-down";
    super.name = 'Selection_multi_' + super.generateRandomId();
    super.type = 'array';
    super.typename = 'Multi Select';
    super.title ='Selection multiple';
    this.widget.formlyConfig.type = 'multischema';
    
    this.widget.formlyConfig.templateOptions.options = [
      new CustomOptions('option 1', '1'),
      new CustomOptions('option 2', '2'),
      new CustomOptions('option 3', '3'),
    ];
  }

  pushOption(option: CustomOptions) {
    this.widget.formlyConfig.templateOptions.options.push(option);

  }


// }
      // this.items = new CustomItems();
      // this.items.enum = ['option 1', 'option 2', 'option 3'];
      // this.items.type = 'string';
      // }

      // items: CustomItems = new CustomItems();
      // uniqueItems: boolean = true;
  }