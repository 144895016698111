<div class="modal-content">
    <div class="modal-header row">
        <div class="col-11">
            <h3 class="text-center title justify-content-center m-0 w-100">
                {{"languages.groupedactions.affectationGroupee" | translate}}
            </h3>
        </div>
        <div class="col-1">
            <button type="button" class="close" aria-label="Close"  (click)="activeModal.dismiss('')" [disabled]="isProcessingAction  || loader">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>


    <div class="" >

             <h3 class="text-center title justify-content-center  ">
                {{"languages.affaire.affecterAqui" | translate}}
            </h3>
        
  
        
        <!-- <div class="modal-body">
          <div class="container-modal-elements">
          
            <div class="d-flex justify-content-center">
              <div class="col-12">
                <div class="form-group text-center">
                  <mat-form-field class="w-50" appearance="standard">
                    <mat-label>{{ 'languages.opportunite.details.affecte' | translate }}</mat-label>
                    <input
                      type="text"
                      matInput
                      [formControl]="userAffect"
                      [matAutocomplete]="autoStatus"
                      placeholder="Chercher..."
                      name="status"
                    />
                    <mat-autocomplete
                      (optionSelected)="AffectUser($event.option.value)"
                      [displayWith]="displayFnUser"
                      autoActiveFirstOption
                      #autoStatus="matAutocomplete"
                    >
                      <mat-option *ngFor="let option of listUserFilter | async" [value]="option">
                        {{ option.nom + ' ' + option.prenom }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
              </div>
            </div>
  
           
            <div class="row justify-content-center mt-4">
              <button type="button" class="btn-dt-save-popup mr-4" (click)="assignUser()">
                <i *ngIf="!loader" class="fa-solid fa-circle-check fa-lg fa-icon-style"></i>
                <span *ngIf="!loader">Enregistrer</span>
                <div *ngIf="loader" class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
  
              <button type="button" class="btn-dt-cancel-popup" (click)="activeModal.dismiss('')">
                <i class="fa-solid fa-circle-xmark fa-icon-style"></i>
                Annuler
              </button>  
            </div>
  
            
            <div class="row justify-content-center mt-5">
              <div class="affecter-moi-container w-75" style="background-color: #ECF6FF">
                <button type="button" class="btn-dt-send" (click)="onAffecterUser()">
                  <i *ngIf="!loader" class="fa-duotone fa-solid fa-square-user mr-2"></i>
                  <span *ngIf="!loader">{{ 'languages.affaire.maffecter' | translate }}</span>
                  <div *ngIf="loader" class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div> -->
        <div class="modal-body">
            <div class="container-modal-elements">
              <!-- Checkboxes to choose between "Affecter à moi" and "Affecter à cadre" -->
              <div class="d-flex justify-content-center">
                <div class="col-8">
                  <div class="form-group d-flex justify-content-around text-center">
                    <mat-checkbox [(ngModel)]="affectToMe" (change)="onAffectToMeChange()">
                      {{ 'languages.affaire.maffecter' | translate }}
                    </mat-checkbox>
                    <mat-checkbox *ngIf="isDirecteur" [(ngModel)]="affectToCadre" (change)="onAffectToCadreChange()">
                      {{ 'languages.affaire.affecterCadre' | translate }}
                    </mat-checkbox>
                  </div>
                </div>
              </div>
      
              <!-- Form Fields for "Affecter à cadre" -->
              <div *ngIf="affectToCadre" class="d-flex justify-content-center">
                <div class="col-12">
                  <div class="form-group text-center">
                    <mat-form-field class="w-50" appearance="standard">
                      <mat-label>{{ 'languages.opportunite.details.affecte' | translate }}</mat-label>
                      <input
                        type="text"
                        matInput
                        [formControl]="userAffect"
                        [matAutocomplete]="autoStatus"
                        placeholder="{{'languages.affaire.Chercher' | translate }}"
                        name="status"
                      />
                      <mat-autocomplete
                        (optionSelected)="AffectUser($event.option.value)"
                        [displayWith]="displayFnUser"
                        autoActiveFirstOption
                        #autoStatus="matAutocomplete"
                      >
                        <mat-option *ngFor="let option of listUserFilter | async" [value]="option">
                          {{ option.nom + ' ' + option.prenom }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                </div>
              </div>
      
              <!-- Buttons "Enregistrer" and "Annuler" -->
              <div class="row justify-content-center mt-4">
                <button type="button" class="btn-dt-save-popup mr-4" 
                [ngClass]="{  'btn-no-click': isProcessingAction && !idAffectUser }" 
                [disabled]="isProcessingAction || !idAffectUser || loader"
                (click)="assignUser()">
                  <i *ngIf="!loader" class="fa-solid fa-circle-check fa-lg mr-2 fa-icon-style"></i>
                  <span *ngIf="!loader">{{ 'languages.buttons.enregistrer' | translate }}</span>
                  <div *ngIf="loader" class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </button>
      
                <button type="button" class="btn-dt-cancel-popup" (click)="activeModal.dismiss('')" [disabled]="isProcessingAction  || loader">
                  <i class="fa-solid fa-circle-xmark mr-2 fa-icon-style"></i>
                  {{ 'languages.buttons.annuler' | translate }}
                </button>
              </div>
            </div>
          </div>

      </div>


   