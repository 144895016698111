import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
  import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { ApiAuthentificationService } from 'src/app/services/ApiAuthentification/api-authentification.service';
import { NotificationsService } from 'src/app/shared/NotificationsService/notifications.service';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent implements OnInit {
  changeForm: FormGroup;
  show_loading_add: boolean = false;
  token: string;
  hidePassword: boolean = true; 
  submitted = false;
  alerts: any;
  buttons: any;
  colors: any = {
    accent: 'accent',
    warn: 'warn',
    primary: 'primary',
  };
  imgLogoSiagf_2: string;

  constructor(
    private formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly apiAuthentificationService: ApiAuthentificationService,
    private readonly notificationsService: NotificationsService,
    private readonly translate: TranslateService,
    private route: ActivatedRoute

  ) {}

  ngOnInit(): void {


    this.imgLogoSiagf_2=environment.imgLogoSiagf_2
    this.route.queryParams.subscribe((params) => {
      // eslint-disable-next-line dot-notation
      this.token = params['token'];
      console.log("tokeen",this.token)
      if (!this.token) {
       console.log("Token is missing.");
      }
    }); 
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
      });
    });
  
    this.translate.get('languages').subscribe((object: any) => {
      this.alerts = object.alerts;
      this.buttons = object.buttons;
    });
  
    this.changeForm = this.formBuilder.group({
      password: [
        '', 
        [
          Validators.required,
          Validators.minLength(12),
          Validators.maxLength(30),
          Validators.pattern(/^(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{12,30}$/)
        ]
      ],
      confirmPassword: ['', Validators.required]
    }, {
      validator: this.mustMatch('password', 'confirmPassword')
    });


    
  }

  togglePasswordVisibility(): void {
    this.hidePassword = !this.hidePassword;
  }
  
  get f() {
    return this.changeForm.controls;
  }

  changePassword() {
    this.submitted = true;
    this.show_loading_add = true;

    if (this.changeForm.invalid || !this.token) {
        this.show_loading_add = false;
        console.log("Form invalid or token missing.");
        return;
    }

    console.log("Attempting to change password with token:", this.token);
    console.log("Password:", this.f.password.value);

    this.apiAuthentificationService.changePassword(this.f.password.value, this.token)
        .subscribe(
            (data) => {
                this.show_loading_add = false;
                this.alertSuccess(data);
            },
            (error) => {
                this.show_loading_add = false;
                console.error("Error changing password:", error);
            }
        );
}


  alertSuccess(data) {
    swal
      .fire({
        title: this.alerts.Successoperation,
        text: this.alerts.passwordChanged,
        icon: 'success',
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonText: this.buttons.fermer,
        confirmButtonColor: '#68a45b',
      })
      .then((result) => {
       
      });
  }

  mustMatch(password: string, confirmPassword: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[password];
      const matchingControl = formGroup.controls[confirmPassword];
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
}
