import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
 
import { tap } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { ApiComminucationService } from '../../../services/ApiComminucation/api-comminucation.service';
import { MailevaElement } from '../../../entity/Maileva/MailevaElement';
import { MailevaHistorique } from '../../../entity/Maileva/MailEvaHistorique';
import Swal from 'sweetalert2';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import { parseDate } from 'src/app/shared/Utils/sharedFunctions';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'src/app/shared/NotificationsService/notifications.service';
import { NotificationMessage, NotificationType } from 'src/app/entity/NotificationMessage';
import { environment } from 'src/environments/environment';

export class bodyPreuveDocument {
  id: string;
}
@Component({
  selector: 'app-maileva-details',
  templateUrl: './maileva-details.component.html',
  styleUrls: ['./maileva-details.component.css'],
})

export class MailevaDetailsComponent implements OnInit {
   
  maileva_id: String = '';
  maileva: MailevaElement = new MailevaElement();
  datepalanifier: Date = new Date();
  updatedate: any;
  myDate = new Date();
  historique: MailevaHistorique[];

  displayedColumns: string[] = ['date', 'user', 'commentaire'];
  dataSource: MatTableDataSource<MailevaHistorique>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  currency: string = environment.currentCurrency;
  formatCurrency: string = environment.formatCurrency;
  showPlanifier: Boolean = false;
  alerts: any;
  buttons: any;
  navbarTxt:string="";
  loading: boolean;
  isListe: boolean =false;
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private readonly sharedMenuObserverService: SharedMenuObserverService,
    private apiComminucationService: ApiComminucationService,
    private readonly datePipe: DatePipe,
    private translate : TranslateService,
    private notificationsService: NotificationsService
  ) {}

  ngOnInit(): void {

    this.translate.get('languages').subscribe((object: any) => {
      this.alerts = object.alerts;
      this.buttons = object.buttons;
      this.navbarTxt=object.navbar.maileva +' ➜ '+ object.navbar.Détails
      this.sharedMenuObserverService.updateMenu(this.navbarTxt);
    });
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
        this.navbarTxt=object.navbar.maileva +' ➜ '+ object.navbar.Détails
        this.sharedMenuObserverService.updateMenu(this.navbarTxt);
      });
    });





    this.route.paramMap.subscribe((params) => {
      this.maileva_id = params.get('maileva_id');
    });
    this.getpost();
    this.getHistoriqueMaileva(this.maileva_id);
  }

  returnToListMaileva() {
    this.router.navigate(['/maileva']);
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  expandName(name: string, max: number): string {
    name = name?.trim();
    if (name?.length < max) {
      return name;
    } else {
      return name?.substring(0, max) + '...';
    }
  }

  scroll(el: string) {
    const element = document.getElementById(el);
    const offset = 120;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }

  NaviagateToDetails(id: string, type) {
    if (type === 'affaire') {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/affaires/details/' + id]));
      window.open(url, '_blank');
    } else {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/opportunities/details/' + id]));
      window.open(url, '_blank');
    }

    //  this.router.navigate(['/opportunities/details/' + id + '/prospect']);
  }

  ngOnDestroy(): void {
    if (!this.isListe) {
      localStorage.removeItem('filtreMaileva');
      localStorage.removeItem('pages');
      localStorage.removeItem('search');
    }
  }

  getpost() {
    this.showloader();
    this.apiComminucationService
      .GetDetailsPost(this.maileva_id)
      .pipe(
        tap(({ post_details }) => {
          this.maileva = post_details;

          const dateticket = parseDate(post_details.date);
          post_details.date = dateticket;
          this.datepalanifier = parseDate(this.maileva.date_envoie);
        })
      )
      .subscribe((data) => {
        this.hideloader();
      });
  }

  formatDate(inputDate: string): string {
    const dateParts = inputDate.split(/[/ :]/); // Split the string by '/', ':', or space
    const day = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]) - 1; // Months are zero-based in JavaScript Date object
    const year = parseInt(dateParts[2]);

    const formattedDate = new Date(year, month, day).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });

    return formattedDate;
  }

  getDocumentName(url: string): string {
    const parts = url.split('/');
    return parts[parts.length - 1];
  }

  docPreuve() {
    const bodyPreuveDoc: bodyPreuveDocument = new bodyPreuveDocument();
    bodyPreuveDoc.id = this.maileva.id_md5;
    this.loading = true;
    this.apiComminucationService.getPreuveDoc(bodyPreuveDoc).subscribe(
      (response) => {
        this.loading = false;
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.success;
        notificationMessage.title =this.alerts.Successoperation;
        notificationMessage.message = response.message;
        this.notificationsService.sendMessage(notificationMessage);
        this.getpost();
      },
      (error) => {
        this.loading = false;
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.error;
        notificationMessage.title = error.error.message
          ? error.error.message
          : this.alerts.contactSupport;
        this.notificationsService.sendMessage(notificationMessage);
      }
    );
  }

  updateDateplanifie() {
    const datemodifier = this.datePipe.transform(this.datepalanifier, 'yyyy-MM-dd');
    this.updatedate = {
      id_post: this.maileva.id,
      date_planifie: datemodifier,
    };
    this.apiComminucationService.updateDatePlanifier(this.updatedate).subscribe((data) => {
      location.reload();
    });
  }

  getTodayDate(): string {
    const today = new Date();
    return this.datePipe.transform(today, 'yyyy-MM-dd') || '';
  }

  resendMaileva() {
    this.apiComminucationService.ResendMail(this.maileva_id).subscribe((data) => {
      location.reload();
    });
  }

  alertConfirm() {
    Swal.fire({
      title: this.alerts.confirmAnnulationCourrier,
      icon: 'info',
      showConfirmButton: true,
      showCloseButton: true,
      showDenyButton: true,
      confirmButtonText: this.buttons.confirmer,
      confirmButtonColor: '#5ea1e7',
      denyButtonText: this.buttons.annuler,
      denyButtonColor: '#978d8c',
    }).then((result) => {
      if (result.isConfirmed) {
        this.apiComminucationService.CancleMaileva(this.maileva_id).subscribe((data) => {
          location.reload();
        });
      }
    });
  }

  returnToMaileva() {
    this.isListe = true;
    const savedFilter = localStorage.getItem('savedFilter');
    const savedCollection = localStorage.getItem('savedCollection');
    const queryParams = {
      filter: savedFilter,
      collection: savedCollection,
    };
    console.log(queryParams);
    this.router.navigate(['/maileva'], { queryParams: queryParams });
  }

  approve() {
    this.apiComminucationService.Approuved(this.maileva_id).subscribe((data) => {
      location.reload();
    });
  }

  onclickPlanifier() {
    this.showPlanifier = !this.showPlanifier;
  }

  showReceptionRow() {
    if (
      (this.maileva.avis_reception !== null && this.maileva.avis_reception.length !== 0) ||
      (this.maileva.ar_date !== null && this.maileva.ar_date?.length !== 0)
    ) {
      return true;
    } else {
      return false;
    }
  }

  showPreuveRow() {
    if (
      (this.maileva.preuve_attachement !== null && this.maileva?.preuve_attachement.length !== 0) ||
      (this.maileva.preuve_date !== null && this.maileva.preuve_date.length !== 0) ||
      (this.maileva.preuve_code !== null && this.maileva.preuve_code.length !== 0)
    ) {
      return true;
    } else {
      return false;
    }
  }

  /*
   *
   *permet d'avoir historique de courier
   */
  getHistoriqueMaileva(idcomm) {
    this.apiComminucationService.getHistoriqueMaileva(idcomm).subscribe(
      (data) => {
        this.historique = data.actions.forEach((element) => {
          const dateticket = parseDate(element.date_action);
          element.date_action = dateticket;
        });
        this.historique = data.actions.reverse();
        this.dataSource = new MatTableDataSource(this.historique);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
