<!-- <div class="row operateurContainer">
    <div class="col-3 d-flex justify-content-center align-items-center ">
        <button (click)="selectOptionCmp('=')" class="operateur mr-3" [ngClass]="{ 'active': filterAffaire && filterAffaire.operateur_cmp === '=' }">
            <i class="fa-solid fa-equals"></i>
        </button>
<button (click)="selectOptionCmp('!=')"  class="operateur" [ngClass]="{ 'active': filterAffaire && filterAffaire.operateur_cmp === '!=' }">
    <i class="fa-solid fa-not-equal"></i>
</button>

    </div>
    <div class="col-9 mt-2">
        <ng-container >
            <mat-form-field class="w-100">
                <mat-label>{{'languages.listing.Compagnie' | translate}}</mat-label>
                <ng-container class="p-3" *ngIf="allCompagnie?.length === 0">
                    <div class="row">
                        <div class="text-center m-auto">
                            <mat-spinner [diameter]="15"></mat-spinner>
                        </div>
                    </div>
                </ng-container>
                <mat-chip-list #Compagnie>
                    <mat-chip *ngFor="let compagnie of ListCompagnies" [selectable]="selectable" [removable]="removable"
                        (removed)="removeCompagnie(compagnie)" (removed)=" EmitFilterAffaire()">
                        {{compagnie}}
                        <i class="fa-solid fa-circle-xmark" matChipRemove *ngIf="removable" style="color: gray;"></i>

                    </mat-chip>
                    <input  placeholder="{{'languages.opportunite.label_company_select' | translate}}" #compagnieInput
                        [formControl]="compagnieFromCtrl" [matAutocomplete]="autoCompagnies"
                        [matChipInputFor]="Compagnie" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                </mat-chip-list>
                <mat-autocomplete #autoCompagnies="matAutocomplete" (optionSelected)="selectedCompagnie($event)">
                    <mat-option *ngFor="let compagnie of filteredCompagnie | async" [value]="compagnie.siren">
                        {{compagnie.denomination}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </ng-container>
    </div>

</div>
<div class="row operateurContainer">
    <div class="col-3 d-flex justify-content-center align-items-center">
        <button (click)="selectOptionGamme('=')" class="operateur mr-3" [ngClass]="{ 'active': filterAffaire && filterAffaire.operateur_gamme === '=' }">
            <i class="fa-solid fa-equals"></i>
        </button>
<button (click)="selectOptionGamme('!=')"  class="operateur" [ngClass]="{ 'active': filterAffaire &&  filterAffaire.operateur_gamme === '!=' }">
    <i class="fa-solid fa-not-equal"></i>
</button>

    </div>
    <div class="col-9 mt-2">
        <ng-container >
            <mat-form-field class="w-100">
                <mat-label> {{'languages.affaire.Gammes' | translate}}</mat-label>
                <ng-container class="p-3" *ngIf="allGammes?.length === 0">
                    <div class="row">
                        <div class="text-center m-auto">
                            <mat-spinner [diameter]="15"></mat-spinner>
                        </div>
                    </div>
                </ng-container>
                <mat-chip-list #Gammes>
                    <mat-chip *ngFor="let gammes of ListGammes" [selectable]="selectable" [removable]="removable"
                        (removed)="removeGammes(gammes)" (removed)=" EmitFilterAffaire()">
                        {{gammes}}
                        <i class="fa-solid fa-circle-xmark" matChipRemove *ngIf="removable" style="color: gray;"></i>
                       
                    </mat-chip>
                    <input  placeholder="{{'languages.affaire.selectGamme' | translate}}" #gammeInput
                        [formControl]="gammeFromCtrl" [matAutocomplete]="autoGamme" [matChipInputFor]="Gammes"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                </mat-chip-list>
                <mat-autocomplete #autoGamme="matAutocomplete" (optionSelected)="selectedGammes($event)">
                    <mat-option *ngFor="let gammes of filteredGammes | async" [value]="gammes.id">
                        {{gammes.nom}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </ng-container>
    </div>

</div>
<div class="row operateurContainer">
    <div class="col-3 d-flex justify-content-center align-items-center">
        <button (click)="selectOptionProduit('=')" class="operateur mr-3" [ngClass]="{ 'active': filterAffaire && filterAffaire.operateur_produit === '=' }">
            <i class="fa-solid fa-equals"></i>
        </button>
<button (click)="selectOptionProduit('!=')"  class="operateur" [ngClass]="{ 'active': filterAffaire && filterAffaire.operateur_produit === '!=' }">
    <i class="fa-solid fa-not-equal"></i>
</button>

    </div>
    <div class="col-9 mt-2">
        <ng-container >
            <mat-form-field class="w-100">
                <mat-label> {{'languages.opportunite.label_product' | translate}}</mat-label>
                <ng-container class="p-3" *ngIf="allProductBackup?.length === 0">
                    <div class="row">
                        <div class="text-center m-auto">
                            <mat-spinner [diameter]="15"></mat-spinner>
                        </div>
                    </div>
                </ng-container>
                <mat-chip-list #chipList>
                    <mat-chip *ngFor="let product of ListProducts" [selectable]="selectable" [removable]="removable"
                        (removed)="removeProducts(product)" (removed)=" EmitFilterAffaire()">
                        {{product}}
                        <i class="fa-solid fa-circle-xmark" matChipRemove *ngIf="removable" style="color: gray;"></i>
                    </mat-chip>
                    <input  placeholder="{{'languages.opportunite.label_produit_select' | translate}}" #ProductsInput
                        [formControl]="ProductsFromCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedProducts($event)">
                    <mat-option *ngFor="let product of filteredProducts | async" [value]="product">
                        {{product.libelle}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </ng-container>
    </div>

    </div> -->
    <!-- <div class="row">
    <div class="col-6">
        <mat-form-field class="w-100">
            <input matInput [(ngModel)]="filterAffaire.recheche_num_contrat" (change)=" EmitFilterAffaire()"
                placeholder="{{'languages.affaire.numContrat' | translate}}"   name="num"
                #num="ngModel">
        </mat-form-field>
        <div *ngIf="num.invalid && (num.dirty || num.touched)" class="message-erreur">
            <div *ngIf="num.errors.pattern">
<i class="fa-solid fa-triangle-exclamation"></i>{{'languages.opportunite.check_onlyNumber' | translate}}
            </div>
        </div>
    </div>
    <div class="col-6">
        <ng-container>
            <mat-form-field class="w-100">
                <mat-label>{{'languages.opportunite.classe' | translate}}</mat-label>
                <ng-container class="p-3" *ngIf="allClasse?.length === 0">
                    <div class="row">
                        <div class="text-center m-auto">
                            <mat-spinner [diameter]="15"></mat-spinner>
                        </div>
                    </div>
                </ng-container>
                <mat-chip-list #Classe>
                    <mat-chip *ngFor="let Classe of ListClasse" [selectable]="selectable" [removable]="removable"
                        (removed)="removeClasse(Classe)" (removed)=" EmitFilterAffaire()">
                        {{Classe}}
                        <i class="fa-solid fa-circle-xmark" matChipRemove *ngIf="removable" style="color: gray;"></i>
                    </mat-chip>
                    <input (change)=" EmitFilterAffaire()" placeholder="{{'languages.opportunite.label_class_select' | translate}}" #classeInput
                        [formControl]="classeFromCtrl" [matAutocomplete]="autoClasse" [matChipInputFor]="Classe"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                </mat-chip-list>
                <mat-autocomplete #autoClasse="matAutocomplete" (optionSelected)="selectedClasse($event)">
                    <mat-option *ngFor="let Classe of filteredClasse | async" [value]="Classe.id">
                        {{Classe.libele}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </ng-container>
    </div>
</div> -->
<!-- <div class="row">
    <div class="col-6">
        <mat-form-field class="w-100">
            <input (change)=" EmitFilterAffaire()" [(ngModel)]="filterAffaire.pan_ttc_min" matInput
                placeholder="{{'languages.affaire.panTtcMin' | translate}}" type="number" pattern="[-+]?[0-9]*[.,]?[0-9]+" name="min" #min="ngModel">
        </mat-form-field>
        <div *ngIf="min.invalid && (min.dirty || min.touched)" class="message-erreur">
            <div *ngIf="min.errors.pattern">
<i class="fa-solid fa-triangle-exclamation"></i>{{'languages.opportunite.check_onlyNumber' | translate}}
            </div>
        </div>
    </div>
    <div class="col-6">
        <mat-form-field class="w-100">
            <input (change)=" EmitFilterAffaire()" [(ngModel)]="filterAffaire.pan_ttc_max" matInput
                placeholder="{{'languages.affaire.panTtcMax' | translate}}" type="number" pattern="[-+]?[0-9]*[.,]?[0-9]+" name="max" #max="ngModel">
        </mat-form-field>
        <div *ngIf="max.invalid && (max.dirty || max.touched)" class="message-erreur">
            <div *ngIf="max.errors.pattern">
<i class="fa-solid fa-triangle-exclamation"></i>{{'languages.opportunite.check_onlyNumber' | translate}}
            </div>
        </div>
    </div>
</div> -->
<div class="row">
    <div class="col-6">
        <div class="form-group">
            <mat-form-field appearance="fill" class="w-100">
                <p>{{'languages.affaire.creationDate' | translate}} </p>
                <mat-date-range-input [rangePicker]="creation">
                    <input [(ngModel)]="pose_du_creation" matStartDate readonly placeholder="{{'languages.opportunite.label_du' | translate}}"  (click)="openDateCreation()"/>
                    <input [(ngModel)]="pose_au_creation" matEndDate  readonly placeholder="{{'languages.opportunite.label_a' | translate}}" (click)="openDateCreation()" />

                </mat-date-range-input>
                <mat-datepicker-toggle class="m-0 p-0" matPrefix [for]="creation">    <mat-icon matDatepickerToggleIcon>
                    <img src="assets/icons/calendar.svg" alt="calendar" alt="calendarIcon"> 
                  </mat-icon></mat-datepicker-toggle>
                  <img  class="remouveDate" matSuffix  (keydown.enter)="clearDateCreation()" (click)="clearDateCreation()" alt="deleteIcon" src="assets/icons/delete-left.svg" alt="delete" alt="delete">
                  <mat-date-range-picker (closed)="EmitFilterAffaire()" [calendarHeaderComponent]="rangeDateCreation" #creation></mat-date-range-picker>
                  
                <!-- <mat-datepicker-toggle matSuffix [for]="creation"></mat-datepicker-toggle>
                <mat-date-range-picker (closed)=" EmitFilterAffaire()" #creation></mat-date-range-picker> -->
            </mat-form-field>
            
        </div>
    </div>

        <div class="col-6 w-100">
            <div class="form-group">
                <mat-form-field appearance="fill" class="w-100">
                    <p>{{'languages.affaire.lastUpdateDate' | translate}} </p>
                    <mat-date-range-input [rangePicker]="LastUpdate">
                        <input [(ngModel)]="lastUpdatedu" matStartDate readonly placeholder="{{'languages.opportunite.label_du' | translate}}"  (click)="openDateLastUpdate()"/>
                        <input [(ngModel)]="lastUpdateau" matEndDate  readonly placeholder="{{'languages.opportunite.label_a' | translate}}" (click)="openDateLastUpdate()" />
    
                    </mat-date-range-input>
                    <mat-datepicker-toggle class="m-0 p-0" matPrefix [for]="LastUpdate">    <mat-icon matDatepickerToggleIcon>
                        <img src="assets/icons/calendar.svg" alt="calendar" alt="calendarIcon"> 
                      </mat-icon></mat-datepicker-toggle>
                      <img  class="remouveDate" matSuffix (keydown.enter)="clearDateLastUpdate()" (click)="clearDateLastUpdate()" alt="deleteIcon" src="assets/icons/delete-left.svg" alt="delete" alt="delete">
                      <mat-date-range-picker (closed)="EmitFilterAffaire()" [calendarHeaderComponent]="rangeDateLastUpdate" #LastUpdate></mat-date-range-picker>
                      
                    
                </mat-form-field>
                
            </div>
        </div>

   
    <!-- <div class="col-6">
        <div class="form-group">
            <mat-form-field appearance="fill" class="w-100">
                <mat-date-range-input [rangePicker]="Souscription">
                    <input [(ngModel)]="pose_du_souscription" readonly matStartDate placeholder="{{'languages.affaire.subscriptionDate' | translate}}" (click)="openDateSouscription()" />
                    <input [(ngModel)]="pose_au_souscription" readonly  matEndDate placeholder="{{'languages.opportunite.label_a' | translate}}"  (click)="openDateSouscription()"/>

                </mat-date-range-input>
                <mat-datepicker-toggle class="m-0 p-0" matPrefix [for]="Souscription">    <mat-icon matDatepickerToggleIcon>
                    <img src="assets/icons/calendar.svg" alt="calendar" alt="calendarIcon"> 
                  </mat-icon></mat-datepicker-toggle>
                  <img  class="remouveDate" matSuffix  (click)="clearDateSouscription()" alt="deleteIcon" src="assets/icons/delete-left.svg" alt="delete" alt="delete">
                  <mat-date-range-picker (closed)="EmitFilterAffaire()"  [calendarHeaderComponent]="rangeDateSubscription"  #Souscription></mat-date-range-picker>

            </mat-form-field>
        </div>
    </div> -->
</div>
<!-- <div class="row">
    <div class="col-6">
        <div class="form-group">
            <mat-form-field appearance="fill" class="w-100">
                <mat-date-range-input [rangePicker]="Effet">
                    <input [(ngModel)]="date_effet_dossier_du" readonly matStartDate placeholder="{{'languages.affaire.datedeffetDu' | translate}}"  (click)="openDateeffet()"/>
                    <input [(ngModel)]="date_effet_dossier_au" readonly matEndDate placeholder="{{'languages.opportunite.label_a' | translate}}"  (click)="openDateeffet()"/>

                </mat-date-range-input>
                <mat-datepicker-toggle class="m-0 p-0" matPrefix [for]="Effet">    <mat-icon matDatepickerToggleIcon>
                    <img src="assets/icons/calendar.svg" alt="calendar" alt="calendarIcon"> 
                  </mat-icon></mat-datepicker-toggle>
                  <img  class="remouveDate" matSuffix  (click)="clearDateeffet()" alt="deleteIcon" src="assets/icons/delete-left.svg" alt="delete" alt="delete">
                  <mat-date-range-picker (closed)="EmitFilterAffaire()"  [calendarHeaderComponent]="rangeDateEffet"  #Effet></mat-date-range-picker>

            </mat-form-field>
        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <mat-form-field appearance="fill" class="w-100">
                <mat-date-range-input [rangePicker]="envoi">
                    <input [(ngModel)]="pose_du_date_envoi_res" readonly  matStartDate placeholder="{{'languages.affaire.dateDebutResiliatdu' | translate}}" (click)="openDateenvoi()" />
                    <input [(ngModel)]="pose_au_date_envoi_res" readonly matEndDate placeholder="{{'languages.opportunite.label_a' | translate}}" (click)="openDateenvoi()"/>

                </mat-date-range-input>

                <mat-datepicker-toggle class="m-0 p-0" matPrefix [for]="envoi">    <mat-icon matDatepickerToggleIcon>
                    <img src="assets/icons/calendar.svg" alt="calendar" alt="calendarIcon"> 
                  </mat-icon></mat-datepicker-toggle>
                  <img  class="remouveDate" matSuffix  (click)="clearDateenvoi()" alt="deleteIcon" src="assets/icons/delete-left.svg" alt="delete" alt="delete">
                  <mat-date-range-picker (closed)="EmitFilterAffaire()"  [calendarHeaderComponent]="rangeDateEnvoi" #envoi></mat-date-range-picker>


                
            </mat-form-field>
        </div>
    </div>
</div> -->
<div class="row operateurContainer">
    <div class="col-3 d-flex justify-content-center align-items-center">
        <button (click)="selectOptionContrat('=')" class="operateur mr-3" [ngClass]="{ 'active': filterAffaire && filterAffaire.operateur_contrat === '=' }">
            <i class="fa-solid fa-equals"></i>
        </button>
<button (click)="selectOptionContrat('!=')"  class="operateur" [ngClass]="{ 'active': filterAffaire && filterAffaire.operateur_contrat === '!=' }">
    <i class="fa-solid fa-not-equal"></i>
</button>

    </div>
    <div class="col-9 mt-2">
        <ng-container >
            <mat-form-field class="w-100">
                <mat-label> {{'languages.opportunite.label_lignedeproduit' | translate}}</mat-label>
                  <ng-container class="p-3" *ngIf="!typeDemandeReady">
                    <div class="row">
                        <div class="text-center m-auto">
                            <mat-spinner [diameter]="15"></mat-spinner>
                        </div>
                    </div>
                </ng-container>
                <mat-chip-list #Contrat>
                    <mat-chip *ngFor="let contrat of ListContrat" [selectable]="selectable" [removable]="removable"
                        (removed)="removeContrat(contrat)" (removed)=" EmitFilterAffaire()">
                        {{contrat}}
                        <i class="fa-solid fa-circle-xmark" matChipRemove *ngIf="removable" style="color: gray;"></i>
                    </mat-chip>
                    <input  placeholder="{{'languages.affaire.selectligneProduit' | translate}}" #contratInput
                        [formControl]="contratFromCtrl" [matAutocomplete]="autoContrat" [matChipInputFor]="Contrat"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                </mat-chip-list>
                <mat-autocomplete #autoContrat="matAutocomplete" (optionSelected)="selectedContrat($event)">
                    <mat-option *ngFor="let contrat of filteredContrat | async" [value]="contrat.id">
                        {{contrat.type}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </ng-container>
    </div>


</div>
<!-- <div class="row operateurContainer">
    <div class="col-3 d-flex justify-content-center align-items-center">
        <button (click)="selectOptionContrat('=')" class="operateur mr-3" [ngClass]="{ 'active': filterAffaire && filterAffaire.operateur_contrat === '=' }">
            <i class="fa-solid fa-equals"></i>
        </button>
<button (click)="selectOptionContrat('!=')"  class="operateur" [ngClass]="{ 'active': filterAffaire && filterAffaire.operateur_contrat === '!=' }">
    <i class="fa-solid fa-not-equal"></i>
</button>

    </div>
    <div class="col-9 mt-2">
        <ng-container >
            <mat-form-field class="w-100">
                <mat-label> {{'languages.affaire.natureDemande' | translate}}</mat-label>
                <ng-container class="p-3" *ngIf="allProducts?.length === 0">
                    <div class="row">
                        <div class="text-center m-auto">
                            <mat-spinner [diameter]="15"></mat-spinner>
                        </div>
                    </div>
                </ng-container>
                <mat-chip-list #Contrat>
                    <mat-chip *ngFor="let contrat of ListContrat" [selectable]="selectable" [removable]="removable"
                        (removed)="removeContrat(contrat)" (removed)=" EmitFilterAffaire()">
                        {{contrat}}
                        <i class="fa-solid fa-circle-xmark" matChipRemove *ngIf="removable" style="color: gray;"></i>
                    </mat-chip>
                    <input  placeholder="{{'languages.affaire.selectnatureDemande' | translate}}" #contratInput
                        [formControl]="contratFromCtrl" [matAutocomplete]="autoContrat" [matChipInputFor]="Contrat"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                </mat-chip-list>
                <mat-autocomplete #autoContrat="matAutocomplete" (optionSelected)="selectedContrat($event)">
                    <mat-option *ngFor="let contrat of filteredContrat | async" [value]="contrat.id">
                        {{contrat.type}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </ng-container>
    </div>


</div> -->
<!-- <div class="row operateurContainer">
    <div class="col-3 d-flex justify-content-center align-items-center">
        <button (click)="selectOptionTag('=')" class="operateur mr-3" [ngClass]="{ 'active': filterAffaire && filterAffaire.operateur_recherche_tag === '=' }">
            <i class="fa-solid fa-equals"></i>
        </button>
<button (click)="selectOptionTag('!=')"  class="operateur" [ngClass]="{ 'active': filterAffaire && filterAffaire.operateur_recherche_tag === '!=' }">
    <i class="fa-solid fa-not-equal"></i>
</button>

    </div>
    <div class="col-9 mt-2">
        <div class="form-group mb-0">
            <ng-container >
                <mat-form-field class="w-100">
                    <mat-label>{{'languages.opportunite.Mot_cle' | translate}}</mat-label>
                    <ng-container class="p-3" *ngIf="allTags?.length === 0">
                        <div class="row">
                            <div class="text-center m-auto">
                                <mat-spinner [diameter]="15"></mat-spinner>
                            </div>
                        </div>
                    </ng-container>
                    <mat-chip-list #Tags>
                        <mat-chip *ngFor="let Tags of ListTags" [selectable]="selectable" [removable]="removable"
                            (removed)="removeTags(Tags)" (removed)=" EmitFilterAffaire()">
                            {{Tags}}
                            <i class="fa-solid fa-circle-xmark" matChipRemove *ngIf="removable" style="color: gray;"></i>

                        </mat-chip>
                        <input  placeholder="{{'languages.opportunite.label_tags_select' | translate}}" #tagsInput
                            [formControl]="tagsFromCtrl" [matAutocomplete]="autoTags" [matChipInputFor]="Tags"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #autoTags="matAutocomplete" (optionSelected)="selectedTags($event)">
                        <mat-option *ngFor="let Tags of filteredTags | async" [value]="Tags.id">
                            {{Tags.libelle}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
    </div>
</div> -->
<!-- <div class="row">
    <div class="col-6">
        <div class="form-group mb-0">
            <ng-container >
                <mat-form-field class="w-100">
                    <mat-label>{{'opportunities.label.tagsnotcontain' | translate}}</mat-label>
                    <ng-container class="p-3" *ngIf="allTags?.length === 0">
                        <div class="row">
                            <div class="text-center m-auto">
                                <mat-spinner [diameter]="15"></mat-spinner>
                            </div>
                        </div>
                    </ng-container>
                    <mat-chip-list #TagsNeContientPas>
                        <mat-chip *ngFor="let Tags of ListTagsNeContientPas" [selectable]="selectable"
                            [removable]="removable" (removed)="removeTagsNeContientPas(Tags)"
                            (removed)=" EmitFilterAffaire()">
                            {{Tags}}
                            <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']">
                            </fa-icon>
                        </mat-chip>
                        <input (click)=" EmitFilterAffaire()" placeholder="{{'languages.opportunite.label_tags_select' | translate}}"  #tagsNeContientPasInput
                            [formControl]="tagsNeContientPasFromCtrl" [matAutocomplete]="autoTagsNeContientPas"
                            [matChipInputFor]="TagsNeContientPas" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #autoTagsNeContientPas="matAutocomplete"
                        (optionSelected)="selectedTagsNeContientPas($event)">
                        <mat-option *ngFor="let Tags of filteredTagsNeContientPas | async" [value]="Tags.id">
                            {{Tags.libelle}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
    </div>
    <div class="col-6">
        <mat-form-field appearance="fill" class="operateur">
            <mat-select (selectionChange)=" EmitFilterAffaire()" [(ngModel)]="filterAffaire.operateur_recheche_not_tag"
                placeholder="Opérateur">
                <mat-option value="OU">
                    Ou
                </mat-option>
                <mat-option value="ET">
                    Et
                </mat-option>

            </mat-select>
        </mat-form-field>
    </div>


</div> -->

