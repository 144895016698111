import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, HostListener, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import { EcheancierService } from 'src/app/services/ApiEcheancier/echeancier.service';
import { environment } from 'src/environments/environment';
import { FilterEcheances } from 'src/app/entity/FilterEcheances';
import { TranslateService } from '@ngx-translate/core';
import { isEqual, differenceWith } from 'lodash';
import { DatePipe } from '@angular/common';
import { parseDate } from 'src/app/shared/Utils/sharedFunctions';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { RangeDateComponent } from 'src/app/components/rangeDate/range-date/range-date.component';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export interface PeriodicElement {
  motif: any;
  date_debut_periode: any;
  date_fin_periode: any;
  status: any;
  affaire_id: any;
}
export class FilterEcheancier {
  date_debut_periode: any = '';
  date_fin_periode: any = '';
  user: any = '';
  montant_total: any = '';
  date_prelevement: any = [];
  motif: any = '';
  status_echeances: any = [];
  status_echeanciers: any = '';
  sortCol: 0;
  sort: 'desc';
  name: string = '';
  surname: string = '';
  sSearch: string = '';
}
@Component({
  selector: 'app-echeances',
  templateUrl: './echeances.component.html',
  styleUrls: ['./echeances.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class EcheancesComponent implements OnInit, OnDestroy {
  columnsToDisplay = [
    'position',
    'nameClient',
    'motif',
    'date_debut_periode',
    'date_fin_periode',
    'status',
    'affaire_id',
  ];

  expandedElement: PeriodicElement | null;
  date_prelevementDebut: any;
  date_prelevementFin: any;

  limit = 50;
  page = 1;
  all_filtres: FilterEcheances = new FilterEcheances();
  all_filtresInit: FilterEcheances = new FilterEcheances();
  listEcheancier = [];
  indexEcheancier: number = 0;
  dataSource: any;
  columnsToDisplayWithExpand: any;
  intervale = 1;
  totalPages = 0;
  currency: string = environment.currentCurrency;
  formatCurrency: string = environment.formatCurrency;
  total_IMPAYE_month_ago: any;
  total_PAYE: any;
  total_PAYE_month_ago: any;
  total_PLANIFIE_next_month: any;
  search: string;
  listeDate: any;
  loader: boolean;
  apearFilter: boolean = false;
  resetAllFilter: boolean = false;
  totalLength: any;
  date_fin: any;
  date_debut: any;
  myDate = new Date();
  date_finInit: Date;
  date_debutInit: Date;
  inputSubject = new Subject<string>();
  @ViewChild('picker') picker: MatDateRangePicker<any>;
  readonly rangeDateCreation = RangeDateComponent;
  constructor(
    private readonly sharedMenuObserverService: SharedMenuObserverService,
    private apiecheancierService: EcheancierService,
    private readonly datePipe: DatePipe,
    private readonly router: Router,
    private renderer: Renderer2,
    private translate : TranslateService

  ) {
    this.inputSubject.pipe(
      debounceTime(1000) 
    ).subscribe((inputValue: string) => {
      this.filter(inputValue);
    });
  }

  onInputChange(value: string): void {
    this.inputSubject.next(value);
  }
  

  ngOnDestroy(): void {
    localStorage.removeItem('filterEcheance');
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event: Event) {
    localStorage.removeItem('filterEcheance');
  }

  collapseFromParent : boolean ;
  @ViewChild('myDiv') myDiv: ElementRef;
  ngAfterViewInit() {
    this.renderer.listen(this.myDiv.nativeElement, 'click', () => {
      this.collapseFromParent = true;
      // Ajoutez votre logique ici
    });
  }

  getCollapseValue(event){
    this.collapseFromParent = event;
  }

  ngOnInit(): void {
    this.date_debut = new Date(new Date(this.myDate.getFullYear(), this.myDate.getMonth() - 1, this.myDate.getDate()));
    this.date_fin = this.myDate;
    this.date_debutInit = new Date(
      new Date(this.myDate.getFullYear(), this.myDate.getMonth() - 1, this.myDate.getDate())
    );
    this.date_finInit = this.myDate;
    this.translate.get('languages').subscribe((object: any) => {
      this.sharedMenuObserverService.updateMenu(object.navbar.echeanciers);
    });
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.sharedMenuObserverService.updateMenu(object.navbar.echeanciers);
      });});

    this.listeEcheancier('init');
    this.columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  }

  isObjectEqual() {
    const differences = differenceWith([this.all_filtres, this.all_filtresInit]);
    return isEqual(this.all_filtres, this.all_filtresInit);
  }

  clearDateCreation() {
    this.date_debut = null;
    this.date_fin = null;
  }

  openDatePicker() {
    if (this.picker) {
      this.picker.open();
    }
  }

  listeEcheancier(event) {
    this.showloader();
    this.loader = false;
    if (localStorage.getItem('filterEcheance')) {
      this.all_filtres = JSON.parse(localStorage.getItem('filterEcheance'));
    }
    if (event === 'init') {
      this.date_prelevementDebut = this.datePipe.transform(this.date_debutInit, 'yyyy-MM-dd');
      this.date_prelevementFin = this.datePipe.transform(this.date_finInit, 'yyyy-MM-dd');
    } else if (event === 'submit') {
      this.all_filtres.sSearch = '';
      this.search = '';
      this.page = 1;
      if (this.isObjectEqual()) {
        this.date_prelevementDebut = this.datePipe.transform(this.date_debutInit, 'yyyy-MM-dd');
        this.date_prelevementFin = this.datePipe.transform(this.date_finInit, 'yyyy-MM-dd');
      } else if (this.all_filtres.date_prelevement[0] && this.all_filtres.date_prelevement[1]) {
        this.date_prelevementDebut = this.datePipe.transform(this.all_filtres.date_prelevement[0], 'yyyy-MM-dd');
        this.date_prelevementFin = this.datePipe.transform(this.all_filtres.date_prelevement[1], 'yyyy-MM-dd');
      } else {
        this.date_prelevementDebut = null;
        this.date_prelevementFin = null;
      }
    } else if (event === 'echeance') {
      this.all_filtres.sSearch = '';
      this.search = '';
      this.page = 1;
      if (this.isObjectEqual()) {
        this.date_prelevementDebut = this.datePipe.transform(this.date_debutInit, 'yyyy-MM-dd');
        this.date_prelevementFin = this.datePipe.transform(this.date_finInit, 'yyyy-MM-dd');
      } else {
        this.date_prelevementDebut = this.datePipe.transform(this.all_filtres.date_prelevement[0], 'yyyy-MM-dd');
        this.date_prelevementFin = this.datePipe.transform(this.all_filtres.date_prelevement[1], 'yyyy-MM-dd');
      }
    } else if (event === 'filtrer') {
      this.page = 1;
      this.search = '';
      this.date_prelevementDebut = this.datePipe.transform(this.date_debut, 'yyyy-MM-dd');
      this.date_prelevementFin = this.datePipe.transform(this.date_fin, 'yyyy-MM-dd');
    } else if (event === 'search') {
      this.router.navigate([], {
        queryParams: {
          filter: null,
          collection: null,
        },
        queryParamsHandling: 'merge',
      });
      this.all_filtres.sSearch = this.search;
      this.date_prelevementDebut = null;
      this.date_prelevementFin = null;
    } else if (event === 'reset') {
      this.all_filtres.sSearch = '';
      this.search = '';
      this.page = 1;
      this.all_filtres = new FilterEcheances();
      this.date_prelevementDebut = this.datePipe.transform(this.date_debutInit, 'yyyy-MM-dd');
      this.date_prelevementFin = this.datePipe.transform(this.date_finInit, 'yyyy-MM-dd');
    } else {
      this.all_filtres.sSearch = this.search;
    }
    if (this.date_prelevementDebut && this.date_prelevementFin) {
      this.all_filtres.date_prelevement = [
        this.datePipe.transform(this.date_prelevementDebut, 'yyyy-MM-dd'),
        this.datePipe.transform(this.date_prelevementFin, 'yyyy-MM-dd'),
      ];
    } else {
      this.all_filtres.date_prelevement = [];
    }
    this.date_debut = this.all_filtres.date_prelevement[0];
    this.date_fin = this.all_filtres.date_prelevement[1];
   
    if(this.all_filtres.sSearch===undefined){
      this.all_filtres.sSearch="";
    }
    this.apiecheancierService.listEcheanciersGet(this.page, this.limit, this.all_filtres).subscribe((data: any) => {
      this.listEcheancier = data.data.list_echeanciers;
      this.apearFilter = true;
      this.date_debut = parseDate(data.data.min_date_prelevement);
      this.date_fin = parseDate(data.data.max_date_prelevement);

      this.loader = true;
      for (const i in this.listEcheancier) {
        this.listEcheancier[i].position = Number(i) + 1;
      }
      this.total_IMPAYE_month_ago = data.data.total_IMPAYE_month_ago;
      this.total_PAYE = data.data.total_PAYE;
      this.total_PAYE_month_ago = data.data.total_PAYE_month_ago;
      this.total_PLANIFIE_next_month = data.data.total_PLANIFIE_next_month;
      this.dataSource = this.listEcheancier;
      this.totalLength = data.data.nb_total;
      this.calculate_nb_pages(data.data.nb_total);
      this.hideloader();
    });
  }

  getIndex(index: number) {
    this.indexEcheancier = index;
  }

  format_date(date) {
    const words = date.split('T');
    return words[0];
  }

  format_date_echeance(date) {
    const words = date.split('/');
    const datef = words[2] + '-' + words[1] + '-' + words[0];
    return datef;
  }

  getchangeLimite() {
    if (this.intervale <= this.totalPages) {
      this.page = this.intervale;
      if (this.page <= this.totalPages) {
        this.listeEcheancier('');
      }
    }
  }



  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }


  calculate_nb_pages(iTotalDisplayRecords) {
    this.totalPages = Math.floor(iTotalDisplayRecords / this.limit);
    if (iTotalDisplayRecords % this.limit !== 0) {
      this.totalPages += 1;
    }
  }

  first() {
    this.setPage(1);
  }

  prev() {
    this.setPage(Math.max(1, this.page - 1));
  }

  next() {
    this.setPage(Math.min(this.totalPages, this.page + 1));
  }

  last() {
    this.setPage(this.totalPages);
  }

  setPage(val: number) {
    this.page = val;
    this.listeEcheancier('');
  }

  isFirst(): boolean {
    return this.page === 1;
  }

  isLast(): boolean {
    return this.page === this.totalPages;
  }

  changepage(number: number) {
    this.page = number;

    this.listeEcheancier('');
  }

  updateListEcheancier() {
    this.page = 1;
    this.listeEcheancier('');
  }

  filterWithDate() {
    this.page = 1;
    this.listeEcheancier('filtrer');
  }

  filter(event) {
    this.search = event
    if (event.length > 0) {
      this.page = 1;
      this.all_filtres = new FilterEcheances();
      this.all_filtres.sSearch = this.search;
      this.resetAllFilter = true;
      this.loader = false;
      this.listeEcheancier('search');
    } else if (event.length === 0) {
      this.page = 1;
      this.all_filtres.sSearch = this.search;
      this.listeEcheancier('init');
    }
  }

  setStatusFilter(status_filter: string) {
    this.resetAllFilter = true;
    this.page = 1;
    this.all_filtres = new FilterEcheances();
    this.search = '';
    this.all_filtres.sSearch = '';
    this.listEcheancier = [];
    this.dataSource = this.listEcheancier;
    localStorage.setItem('filterEcheance', JSON.stringify(this.all_filtres));
    // this.all_filtres.date_prelevement = [];
    if (this.all_filtres.status_echeances.indexOf(status_filter) !== -1) {
      this.hideloader();
      return;
    } else {
      if (status_filter !== '') {
        if (status_filter === 'IMPAYE') {
          const dateDebut = new Date();
          const dateFin = new Date();
          dateFin.setDate(dateFin.getDate() - 30);
          this.all_filtres.date_prelevement = [
            this.datePipe.transform(dateFin, 'yyyy-MM-dd'),
            this.datePipe.transform(dateDebut, 'yyyy-MM-dd'),
          ];
          this.all_filtres.status_echeances = [];
          this.all_filtres.status_echeances.push(
            environment.idStatusInfoBancaires,
            environment.idStatusImpaye,
            environment.idStatusDefaut
          );
        } else if (status_filter === 'PAYE') {
          const dateDebut = new Date();
          const dateFin = new Date();
          dateFin.setDate(dateFin.getDate() - 30);
          this.all_filtres.date_prelevement = [
            this.datePipe.transform(dateFin, 'yyyy-MM-dd'),
            this.datePipe.transform(dateDebut, 'yyyy-MM-dd'),
          ];
          this.all_filtres.status_echeances = [];
          this.all_filtres.status_echeances.push(environment.idStatusPaye);
        } else if (status_filter === 'PLANIFIE') {
          const dateDebut = new Date();
          const dateFin = new Date();
          dateFin.setDate(dateFin.getDate() + 30);
          this.all_filtres.date_prelevement = [
            this.datePipe.transform(dateDebut, 'yyyy-MM-dd'),
            this.datePipe.transform(dateFin, 'yyyy-MM-dd'),
          ];
          this.all_filtres.status_echeances = [];
          this.all_filtres.status_echeances.push(environment.idStatusPlanifie, environment.idStatusEncours);
        }
      } else {
        this.all_filtres.status_echeances = [];
      }
    }
    this.listEcheancier = [];
    this.loader = false;
    this.date_debut = this.all_filtres.date_prelevement[0];
    this.date_fin = this.all_filtres.date_prelevement[1];
   
    this.apiecheancierService.listEcheanciersGet(this.page, this.limit, this.all_filtres).subscribe((data: any) => {
      this.listEcheancier = data.data.list_echeanciers;
      this.loader = true;
      this.apearFilter = true;
      for (const i in this.listEcheancier) {
        this.listEcheancier[i].position = Number(i) + 1;
      }
      this.total_IMPAYE_month_ago = data.data.total_IMPAYE_month_ago;
      this.total_PAYE = data.data.total_PAYE;
      this.total_PAYE_month_ago = data.data.total_PAYE_month_ago;
      this.total_PLANIFIE_next_month = data.data.total_PLANIFIE_next_month;
      this.dataSource = this.listEcheancier;
      this.calculate_nb_pages(data.data.nb_total);
    });
  }

  allFilter(event) {
    this.all_filtres = event;
  }

  submitFilter(event) {
    this.listeEcheancier(event);
  }

  resetformFilter(event) {
    this.resetAllFilter = event;
    localStorage.setItem('filterEcheance', JSON.stringify(this.all_filtres));
  }

  exportDataToCsv() {
    this.apiecheancierService.exportToCsv(this.listEcheancier, 'echeancier.csv');
  }
}
