export class Distributeur {
  loyout: string;
  mode: string;
  couleur_theme: string;
  couleur1: string;
  couleur2: string;
  list_offres: Listetype = new Listetype();
  list_contrats: Listetype = new Listetype();


}
export class Listetype {
  included: listPerso[];
  not_included: listPerso[]
}
export class listPerso {
  key: string[]= [];
  order: any;
  libelle: string;
  
}
