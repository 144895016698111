<!-- <div class="grid-container" *ngIf="!appearDash">
 <div class="card">
    <div class="card-title">
      <h1 style="font-weight: 500; font-size: 50px">{{"languages.navbar.welcome" | translate}}</h1>
    </div>
    <div class="bloc-img-geoprod">
      <img
        class="img-geoprod"
        src="https://staging.cdn.sigaf.cloud.geoprod.com/static/uploads/files/logo_sigafMiniVersionwhite.png"
        alt="Avatar"
        style="width: 100%"
      />
    </div>
    <div class="card-title">
      <h2 style="font-weight: 500; font-size: 30px"></h2>
    </div>
  </div>
</div> -->
<div class="containerwelcome" *ngIf="!appearDash">
  <img src="https://staging.cdn.sigaf.cloud.geoprod.com/static/uploads/files/welcome_picture.png" alt="welcomeImg" style="    margin-right: 86px;">
</div>
<iframe *ngIf="appearDash" width="100%" height="900" title="dashboard" [src]="urlSafe" allowFullScreen="true"></iframe>

