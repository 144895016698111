<div class="row">
<div class="card-head row d-flex justify-content-around mx-3">
    <!-- Select Fournisseur -->
     <div class="col-4 ">
      <mat-form-field class="w-100" appearance="standard">
        <mat-label> {{ 'languages.alerts.selectFournisseur' | translate }}</mat-label>
        <mat-select [(value)]="selectedGroup" (selectionChange)="onGroupSelect($event.value)">
          <mat-option *ngFor="let group of apiGroups" [value]="group">
            {{ group.group }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    
     </div>
   
    <!-- Select API -->
    <div class="col-4 ">
    <mat-form-field class="w-100" appearance="standard" >
      <mat-label> {{ 'languages.alerts.selectApi' | translate }}</mat-label>
      <mat-select [disabled]="!selectedGroup" [(value)]="selectedApi" (selectionChange)="onApiSelect($event.value)">
        <mat-option *ngFor="let api of selectedGroup?.apis" [value]="api">
          {{ api.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    </div>
  </div>
  
  <!-- API Details -->
   <div class="card-head row mx-3 d-flex align-items-center justify-content-center" *ngIf="selectedApi">
    <div class=" col-8 d-flex flex-column  w-100 align-items-center justify-content-center ">
      <div *ngIf="selectedApi" class="d-flex flex-column align-items-center justify-content-center  w-100 p-2">
     
          <p class="text-center title">{{ selectedApi.title }}</p>
          <p class="text-center ">{{ selectedApi.description }}</p>
       
          <div class="apiInfo w-100 row d-flex flex-column ">
            <div class="d-flex  align-items-center mb-2 title" style="font-size:14px">
              <i class=" fa-solid fa-circle-exclamation mr-2"></i>
              <p class=" m-0" >{{ 'languages.alerts.ExamplePattern' | translate }}</p>
            </div>
          <div *ngFor="let field of selectedApi.fields" class="row w-100">
            <div class="col-12">
              <p><strong>{{ field.label }}</strong> : {{ field.explication }}  </p>
              <!-- <p *ngIf="field.examplePattern"><strong> {{ 'languages.alerts.ExamplePattern' | translate }}:</strong> {{ field.examplePattern }}</p> -->
            </div>
           
          </div>
        </div>
      </div>
      
      <!-- API Form -->
      <form (ngSubmit)="onSubmit()" *ngIf="selectedApi" class="w-100   row d-flex w-100 justify-content-center">
        <div class="row d-flex w-100 justify-content-center">
          <div *ngFor="let field of selectedApi.fields" class="col-6">
            <mat-form-field appearance="standard" class="w-100">
              <mat-label>{{ field.label }}</mat-label>
              <input
                matInput
                [(ngModel)]="field.value"
                name="{{ field.name }}"
                [type]="field.type || 'text'"
                [required]="field.required"
                [minlength]="field.min"
                [maxlength]="field.max"
                [pattern]="field.pattern"
                [placeholder]="field.example"
                (ngModelChange)="onFieldChange(field, $event)"
              />
              <mat-error *ngIf="formErrors[field.name]">{{ formErrors[field.name] }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        
          <div class="row d-flex w-100 justify-content-center" *ngIf="saveLoader">
              <div class="col-12  d-flex w-100 justify-content-center">
                  
                      <mat-spinner [diameter]="20"></mat-spinner>
                  
              </div>
          </div>
     
        <button type="submit" [disabled]="saveLoader" class="mt-3 btn-dt-save-popup" >
          <i class="fa-light fa-paper-plane-top fa-lg mr-1"></i>
           {{ 'languages.buttons.envoyer' | translate }}</button>
      </form>
    
    </div>

   

   </div>
 

 
   <!-- <div class="my-3 row scrollbar card-head d-flex justify-content-center mb-5" *ngIf="showTable" [ngClass]="{'table-scroll': tableData.length > 10 }">
    <div class="d-flex flex-column col-8 justify-content-between w-100">
   
    <p class="text-center title">{{ 'languages.alerts.requestResult' | translate }} </p>
    <table class="table align-items-center" id="dataTable1">
        <thead class="head-table">
            <tr>
                <th>  {{ 'languages.alerts.Champ' | translate }}</th>
                <th>  {{ 'languages.garantie.Valeur' | translate }}</th>

            </tr>
        </thead>
        <tbody class="body-table">
            <tr *ngFor="let row of tableData">
                <td> {{row.key}}</td>
                <td>{{row.value}}</td>

            </tr>
        </tbody>
    </table>
   </div>
</div> -->
 
            
<div class="my-3 row scrollbar card-head d-flex justify-content-center mb-5" *ngIf="showTable" >
  <div class="d-flex flex-column col-8 justify-content-between w-100">
 
  <p class="text-center title">{{ 'languages.alerts.requestResult' | translate }} </p>
  <pre *ngIf="data.length !=0">{{ data | json }}</pre>
  <div *ngIf="data.length == 0" class="row">
    <div class="col-12  d-flex justify-content-center">
        <img src="assets/icons/nodataIcon.svg" alt="noData">

    </div>
    <div class="col-12  d-flex justify-content-center">
        <p class="noData"> {{'languages.affaire.noDataAvailable' | translate}}</p>
    </div>
</div>
 </div>
</div>

      </div>