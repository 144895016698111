import {FormField} from './FormField';
import {CustomOptions, CustomWidget} from './SharedElementsFormly';

export class RadioFormly extends FormField {

  constructor() {
    super();
    super.icon="fa fa-list-ul";
    super.name = 'radio_' + super.generateRandomId();
    this.type = 'string';
    super.title="Radio"
    this.widget.formlyConfig.type = 'radio';
    super.typename = 'Radio';
    this.widget.formlyConfig.templateOptions.options = [
      new CustomOptions('option 1', 'opt1'),
      new CustomOptions('option 2', 'opt2'),
      new CustomOptions('option 3', 'opt3'),
    ];
  }

  pushOption(option: CustomOptions) {
    this.widget.formlyConfig.templateOptions.options.push(option);

  }

 

}
