/**
 * Documentation Geoprod
 * Description des web services de Remoteprod
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ModelObject } from '../model/modelObject';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {environment} from "../../../environments/environment";


@Injectable()
export class ApiDossierService {

  protected basePath = environment.api_url;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   * Sert a afficher le lien vers le document de dossier suivant lidentifiant de dossier et l&#x27;identifiant de document codé en md5
   *
   * @param idDossier Identifiant de dossier codé en md5
   * @param idDocument Identifiant de document codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getFormDocDossGet(idDossier: ModelObject, idDocument: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public getFormDocDossGet(idDossier: ModelObject, idDocument: ModelObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public getFormDocDossGet(idDossier: ModelObject, idDocument: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public getFormDocDossGet(idDossier: ModelObject, idDocument: ModelObject, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (idDossier === null || idDossier === undefined) {
      throw new Error('Required parameter idDossier was null or undefined when calling getFormDocDossGet.');
    }

    if (idDocument === null || idDocument === undefined) {
      throw new Error('Required parameter idDocument was null or undefined when calling getFormDocDossGet.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (idDossier !== undefined && idDossier !== null) {
      queryParameters = queryParameters.set('id_dossier', <any>idDossier);
    }
    if (idDocument !== undefined && idDocument !== null) {
      queryParameters = queryParameters.set('id_document', <any>idDocument);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get',`${this.basePath}/get_form_doc_doss`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Afficher des information sur  le dossier suivant l&#x27;etat dossier
   *
   * @param idEtatDoss &lt;p&gt;Identifiant de l&#x27;etat dossier &lt;br&gt; c4ca4238a0b923820dcc509a6f75849b Traitement &lt;br&gt; c81e728d9d4c2f636f067f89cc14862c Annulation &lt;br&gt;eccbc87e4b5ce2fe28308fd9f2a7baf3 Perte &lt;br&gt;a87ff679a2f3e71d9181a67b7542122c Gain &lt;br&gt; e4da3b7fbbce2345d7772b0674a318d5 Evaluation&lt;/p&gt;
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listactionDossGet(idEtatDoss: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public listactionDossGet(idEtatDoss: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public listactionDossGet(idEtatDoss: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public listactionDossGet(idEtatDoss: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (idEtatDoss === null || idEtatDoss === undefined) {
      throw new Error('Required parameter idEtatDoss was null or undefined when calling listactionDossGet.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (idEtatDoss !== undefined && idEtatDoss !== null) {
      queryParameters = queryParameters.set('id_etat_doss', <any>idEtatDoss);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get',`${this.basePath}/listaction_doss`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
