import { FilterBanque } from './../../entity/filter-banque';
import { ApiBanqueService } from './../../services/ApiBanque/api-banque.service';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
 
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import { Subject, Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-gestion-banque',
  templateUrl: './gestion-banque.component.html',
  styleUrls: ['./gestion-banque.component.css'],
})
export class GestionBanqueComponent implements OnInit {
  subscribegetListBanque: Subscription;
  listBanque = {};
  filterListBanque: FilterBanque = new FilterBanque();
  limit = 15;
  pages = 1;
  totalPages = 15;
  intervale = 1;
  inputSubject = new Subject<string>();
  totalLength : number = 0;
  constructor(
    private readonly sharedMenuObserverService: SharedMenuObserverService,
    private ApiBanqueService: ApiBanqueService,
    private chRef: ChangeDetectorRef,
    private routes: Router,
    private translate: TranslateService
  ) {
    this.inputSubject.pipe(
      debounceTime(1000) 
    ).subscribe((inputValue: string) => {
      this.getDataValue(inputValue);
    });
  }

  onInputChange(value: string): void {
    this.inputSubject.next(value);
  }
  
  ngOnInit(): void {
    this.translate.onLangChange.subscribe(() => {

    this.translate.get('languages').subscribe((object: any) => {
      this.sharedMenuObserverService.updateMenu(object.navbar.gestionBanque);
    });
  });
  this.translate.get('languages').subscribe((object: any) => {
    this.sharedMenuObserverService.updateMenu(object.navbar.gestionBanque);
  });
    this.showloader();
    this.getListBanque();
  }

  getListBanque() {
    if(localStorage.getItem("search")) this.filterListBanque.sSearch = localStorage.getItem("search");
    this.subscribegetListBanque = this.ApiBanqueService.getListBanque(
      this.filterListBanque,
      this.pages,
      this.limit
    ).subscribe((response: any) => {
      this.listBanque = response.list_banque;
      this.chRef.detectChanges();
      this.totalLength = response.iTotalDisplayRecords
      this.calculate_nb_pages(response.iTotalDisplayRecords);
      this.hideloader();
    });
  }
  
  calculate_nb_pages(iTotalDisplayRecords) {
    this.totalPages = Math.floor(iTotalDisplayRecords / this.limit);
    if (iTotalDisplayRecords % this.limit !== 0) {
      this.totalPages += 1;
    }
  }

  getchangeLimite() {
    if (this.intervale <= this.totalPages) {
      this.pages = this.intervale;
      if (this.pages <= this.totalPages) {
        this.getListBanque();
      }
    }
  }

  first() {
    this.setPage(1);
  }

  prev() {
    this.setPage(Math.max(1, this.pages - 1));
  }

  next() {
    this.setPage(Math.min(this.totalPages, this.pages + 1));
  }

  last() {
    this.setPage(this.totalPages);
  }

  setPage(val: number) {
    this.pages = val;
    this.getListBanque();
  }

  isFirst(): boolean {
    return this.pages === 1;
  }

  isLast(): boolean {
    return this.pages === this.totalPages;
  }

  updateListBanque() {
    this.pages = 1;
    this.getListBanque();
  }

  changepage(number: number) {
    this.pages = number;
    localStorage.setItem('pages', number.toString());
    this.getListBanque();
  }

  getDataValue(event) {
  
      this.filterListBanque.sSearch = event
      localStorage.setItem('search',this.filterListBanque.sSearch);
      this.getListBanque();
  
  }

  ngOnDestroy(): void {
    const currentRoute = this.routes.url;
    if (!currentRoute.includes('gestion-banque')) {
      localStorage.removeItem('search');
      localStorage.removeItem('page');
    }
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }
}
