import swal from 'sweetalert2';
import { Component, OnInit, Optional } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Configuration } from 'src/app/services/configuration';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import { WebmailService } from 'src/app/services/ApiWebmail/webmail.service';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-webmail',
  templateUrl: './webmail.component.html',
  styleUrls: ['./webmail.component.css'],
})
export class WebmailComponent implements OnInit {
  session: string;
  public configuration = new Configuration();

  url: string = environment.webmail_url;
  // url: string = 'http://localhost:4200/apps/mailbox/inbox/1';
  urlSafe: SafeResourceUrl;
  alerts: any;
  buttons: any;
  constructor(
    @Optional() configuration: Configuration,
    private readonly sharedMenuObserverService: SharedMenuObserverService,
    private readonly router: Router,
    public sanitizer: DomSanitizer,
    private apiWebmail: WebmailService,
    private translate: TranslateService
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
  }

  ngOnInit(): void {
    this.translate.get('languages').subscribe((object: any) => {
      this.alerts = object.alerts;
      this.buttons = object.buttons;
      this.sharedMenuObserverService.updateMenu(object.navbar.webmail);
    });

    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
        this.sharedMenuObserverService.updateMenu(object.navbar.webmail);
      });
    });

    this.showloader();
    this.apiWebmail
      .isConfigured()
      .pipe(
        tap((result) => {
          const configured = result.is_configured;
          if (configured) {
            this.session = this.configuration.apiKeys.idSession;
            this.url = this.url + '?idSession=' + this.session + '&apiUrl=' + environment.api_url;
            this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
            this.hideloader();
          } else {
            this.hideloader();
            this.alertError(this.alerts.configureEmail);
          }
        })
      )
      .subscribe();
  }

  alertError(data) {
    swal
      .fire({
        title: this.alerts.noConfigurationWebmail,
        text: data,
        icon: 'error',
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonColor: '#68a45b',
        focusConfirm: true,
        showCancelButton: true,
        cancelButtonText: this.buttons.fermer,
        confirmButtonText: this.buttons.configurer,
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.apiWebmail.tab = 3;
          this.router.navigate(['/profil']);
        }
      });
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }
}
