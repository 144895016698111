import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../../shared/shared.module';
import { FiltreAffaireComponent } from './filtre-affaire/filtre-affaire.component';
import { FiltreInfoContratActuelComponent } from './filtre-info-contrat-actuel/filtre-info-contrat-actuel.component';
import { FiltreActionUserComponent } from './filtre-action-user/filtre-action-user.component';
import { FiltreProspectComponent } from './filtre-prospect/filtre-prospect.component';
import { FiltreStatutActuelComponent } from './filtre-statut-actuel/filtre-statut-actuel.component';
import { FiltreUserComponent } from './filtre-user/filtre-user.component';

import { FilterOpportunityAffaireComponent } from './filter-opportunity-affaire/filter-opportunity-affaire.component';
import { FilterActionUserOppComponent } from './filter-action-user-opp/filter-action-user-opp.component';
import { FilterUserOppComponent } from './filter-user-opp/filter-user-opp.component';
import { FilterStatutActuelOppComponent } from './filter-statut-actuel-opp/filter-statut-actuel-opp.component';
import { FilterTelephonieOppComponent } from './filter-telephonie-opp/filter-telephonie-opp.component';
import { BlocQualiteComponent } from './bloc-qualite/bloc-qualite.component';


@NgModule({
  declarations: [
    FiltreAffaireComponent,
    FiltreInfoContratActuelComponent,
    FiltreActionUserComponent,
    FiltreProspectComponent,
    FiltreStatutActuelComponent,
    FiltreUserComponent,
    FilterOpportunityAffaireComponent,
    FilterActionUserOppComponent,
    FilterUserOppComponent,
    FilterStatutActuelOppComponent,
    FilterTelephonieOppComponent,
    BlocQualiteComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    FiltreAffaireComponent,
    FiltreInfoContratActuelComponent,
    FiltreActionUserComponent,
    FiltreProspectComponent,
    FiltreStatutActuelComponent,
    FiltreUserComponent,
    FilterOpportunityAffaireComponent,
    FilterActionUserOppComponent,
    FilterUserOppComponent,
    FilterStatutActuelOppComponent,
    FilterTelephonieOppComponent,
    BlocQualiteComponent
  ]
})
export class FiltresAffaireModule { }
