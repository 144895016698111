import { NotificationsService } from './../../shared/NotificationsService/notifications.service';
import { ApiTagsService } from './../../services/api-tags.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Tags } from './../../entity/Parametres/Tags/Tags';
import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
 
import { debounceTime, startWith, switchMap, map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { NotificationMessage } from 'src/app/entity/NotificationMessage';
import { ApiAffairesService } from 'src/app/services/ApiAffaires/api-affaires.service';
import { ApiOpportunitService } from 'src/app/services/ApiOpportunite/api-opportunite.service';
import { TranslateService } from '@ngx-translate/core';

import { HistoryService } from 'src/app/pages/affaires/affaire-details/history.service';
@Component({
  selector: 'shared-tags',
  templateUrl: './shared-tags.component.html',
  styleUrls: ['./shared-tags.component.css'],
})
export class SharedTagsComponent implements OnInit, OnChanges {
  @Input() affaire_id = '';
  @Input() tickets_id = '';
  @Input() opportunity_id = '';
  @Input() prospect_id?= '';
  @Input() isReadOnly: boolean;
   
  listTags: Tags[] = [];
  list: Tags[] = [];
  activeTags: Tags[] = [];
  formTag: FormGroup;
  public tagAutoComplete$: Observable<Tags[]> = null;
  loaderListTags: boolean;
  notFoundTags: boolean = false;
  loaderActiveTags: boolean = false;
  @Input() actionGroupe: string;
  @Input() liste: any;
  @Input() type: any;
  @Output() newItemEvent = new EventEmitter<any>();
  @Output() listProspectTag = new EventEmitter<any>();
  listTagg: any = [];
  appear: boolean = false;
  listTagProspect: any = [];
  listTagProcpectA: Tags[] = [];
  buttons: any;
  alerts: any;
  constructor(private apiTagsServices: ApiTagsService,
    private readonly notificationsService: NotificationsService,
    private apiAffaireService: ApiAffairesService,
    private readonly apiOpportunitService: ApiOpportunitService,
    private translate : TranslateService,
    private readonly HistoryService: HistoryService,
) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isReadOnly === false) this.formTag?.disable();
  }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
      });
  });
  this.translate.get('languages').subscribe((object: any) => {
    this.alerts = object.alerts;
    this.buttons = object.buttons;
  });
    this.loaderActiveTags = true;
    if (this.affaire_id !== '') {
      this.apiTagsServices.GetListTags(this.affaire_id, 'A').subscribe(
        (data) => {
          this.activeTags = data;
          this.loaderActiveTags = false;
          this.appear = true;
        },
        () => {
          this.loaderActiveTags = false;
        }
      );
    } else if (this.tickets_id !== '') {
      this.apiTagsServices.GetListTags(this.tickets_id, 'T').subscribe(
        (data) => {
          this.activeTags = data;
          this.loaderActiveTags = false;
          this.appear = true;

        },
        () => {
          this.loaderActiveTags = false;
        }
      );
    } else if (this.opportunity_id !== '') {
      this.apiTagsServices.GetListTags(this.opportunity_id, 'O').subscribe(
        (data) => {
          this.activeTags = data;
          this.loaderActiveTags = false;
          this.appear = true;

        },
        () => {
          this.loaderActiveTags = false;
        }
      );
    } else if (this.prospect_id !== '') {
      this.apiTagsServices.GetListTags(this.prospect_id, 'P').subscribe(
        (data) => {
          this.activeTags = data;
          this.loaderActiveTags = false;
          this.appear = true;

        },
        () => {
          this.loaderActiveTags = false;
        }
      );
    }
    /* else if( this.actionGroupe =='opp') {
      this.apiOpportunitService.multipleActionOpportunite(this.opportunity_id, 'O').subscribe(
        (data) => {
          this.activeTags = data;
          this.loaderActiveTags = false;
        },
        () => {
          this.loaderActiveTags = false;
        }
      );
      this.apiOpportunitService.multipleActionOpportunite
    }else if (this.actionGroupe =='affaire'){
      this.apiAffaireService.multipleActionAffaire(this.opportunity_id, 'O').subscribe(
        (data) => {
          this.activeTags = data;
          this.loaderActiveTags = false;
        },
        () => {
          this.loaderActiveTags = false;
        }
      );
    } */


    this.formTag = new FormGroup({
      tagText: new FormControl(),
      tagTextId: new FormControl(),
    });

    this.tagAutoComplete$ = this.formTag.controls.tagText.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      switchMap((value) => {
        this.loaderListTags = true;

        if (value && !this.checkIsTagExist(value)) {
          return this.getListTags();
        } else {
          this.loaderListTags = false;
          // if no value is present, return null
          return of([]);
        }
      })
    );
    if (this.isReadOnly === false) this.formTag.disable();
  }

  /**
   * Permets de verifier l'existance d'une tag dans la liste des tags
   * @param value
   * @returns
   */
  checkIsTagExist(value): boolean {
    let retournedValue = false;
    this.activeTags.forEach((element) => {
      if (element.libelle === value) retournedValue = true;
    });
    return retournedValue;
  }

  /**
   * @returns permets de retourner la liste des tags
   */
  getListTags(): Observable<Tags[]> {
    return this.apiTagsServices.SearchTagByTypeEntity(this.formTag.value.tagText?.toLowerCase()).pipe(
      map((res: any) => {
        setTimeout(() => {
          this.loaderListTags = false;
        }, 700);
        this.notFoundTags = false;
        this.listTags.forEach(function (item) {
          const index: number = res.tags.findIndex((i) => i.libelle === item.libelle);
          if (index !== -1) {
            res.tags.splice(index, 1);
          }
        });
        return res.tags;
      }),
      catchError((_) => {
        this.notFoundTags = true;
        return of([]);
      })
    );
  }

  /**
   * Permets d'enlever une tag
   * @param index
   */
  removeTag(index: number) {
    this.activeTags.splice(index, 1);
    this.updateTags();
  }

  /**
   * Permets d'afficher les notifications du tag
   * @param type
   * @param title
   * @param message
   */
  notificationTag(type: number, title: string, message: string): void {
    const notificationMessage = new NotificationMessage();
    notificationMessage.title = title;
    notificationMessage.type = type;
    notificationMessage.message = message;
    this.notificationsService.sendMessage(notificationMessage);
  }

  /**
   * Permets de mis à jour les tags
   */
  updateTags(): void {
    this.loaderActiveTags = true;
    if (this.affaire_id !== '') {
      this.apiTagsServices.UpdatetagsByEntity(this.affaire_id, 'A', this.activeTags).subscribe(
        (response) => {
          if (response.error) {
            this.notificationTag(
              1,
              this.alerts.modificationKeywords,
              this.alerts.errorUpdating +" :" + response.message
            );
            this.loaderActiveTags = false;
            this.ngOnInit();
          } else {
            this.notificationTag(0, this.alerts.modificationKeywords, this.alerts.modifiedSuccess);
            this.loaderActiveTags = false;
            this.HistoryService.sendFetchDataSignal(true)
            this.ngOnInit();
          }
        },
        () => {
          this.loaderActiveTags = false;
          this.ngOnInit();
          this.notificationTag(1, this.alerts.modificationKeywords, this.alerts.errorUpdating);
        }
      );
    } else if (this.tickets_id !== '') {
      this.apiTagsServices.UpdatetagsByEntity(this.tickets_id, 'T', this.activeTags).subscribe(
        (response) => {
          if (response.error) {
            this.notificationTag(
              1,
              this.alerts.modificationKeywords,
              this.alerts.errorUpdating+" :" + response.message
            );
            this.loaderActiveTags = false;
            this.ngOnInit();
          } else {
            this.notificationTag(0, this.alerts.modificationKeywords, this.alerts.modifiedSuccess);
            this.loaderActiveTags = false;
            this.ngOnInit();
          }
        },
        () => {
          this.loaderActiveTags = false;
          this.ngOnInit();
          this.notificationTag(1, this.alerts.modificationKeywords,  this.alerts.errorUpdating);
        }
      );
    } else if (this.opportunity_id !== '') {
      this.apiTagsServices.UpdatetagsByEntity(this.opportunity_id, 'O', this.activeTags).subscribe(
        (response) => {
          if (response.error) {
            this.notificationTag(
              1,
              this.alerts.modificationKeywords,
              this.alerts.errorUpdating +" :"+ response.message
            );
            this.loaderActiveTags = false;
            this.ngOnInit();
          } else {
            this.notificationTag(0, this.alerts.modificationKeywords,this.alerts.modifiedSuccess );
            this.loaderActiveTags = false;
            this.ngOnInit();
          }
        },
        () => {
          this.loaderActiveTags = false;
          this.ngOnInit();
          this.notificationTag(1, this.alerts.modificationKeywords,  this.alerts.errorUpdating);
        }
      );
    } else if (this.prospect_id !== '') {
      this.apiTagsServices.UpdatetagsByEntity(this.prospect_id, 'P', this.activeTags).subscribe(
        (response) => {
          if (response.error) {
            this.notificationTag(
              1,
              this.alerts.modificationKeywords,
              this.alerts.errorUpdating+" : " + response.message
            );
            this.loaderActiveTags = false;
            this.ngOnInit();
          } else {
            this.notificationTag(0, this.alerts.modificationKeywords, this.alerts.modifiedSuccess);
            this.loaderActiveTags = false;
            this.ngOnInit();
          }
        },
        () => {
          this.loaderActiveTags = false;
          this.ngOnInit();
          this.notificationTag(1, this.alerts.modificationKeywords,  this.alerts.errorUpdating);
        }
      );
    }
  }

  /**
   * Permets de selecter une tag
   * @param item
   */
  onSelectTag(item?: Tags): void {
    if (this.appear) {
      if (!this.checkIsTagExist(item.libelle)) {
        this.activeTags.push(item);
        this.updateTags();
      }
    } else {
      if (!this.checkIsTagExistlisteGroupe(item.libelle)) {
        this.list.push(item);
        if(this.type === 'opportunite'){
          this.listTagg.push(item.id);
          this.newItemEvent.emit(this.listTagg);
        }
        if(this.type === 'prospect'){
          this.listTagProspect.push(item.id);
          this.listProspectTag.emit(this.listTagProspect);
        }


      }
    }

    this.formTag.reset();
  }

  checkIsTagExistlisteGroupe(value): boolean {
    let retournedValue = false;
    this.list.forEach((element) => {
      if (element.libelle === value) retournedValue = true;
    });
    return retournedValue;
  }


  deleteTags(index) {
    this.list.splice(index, 1);
    this.listTagg.splice(index, 1);
  }
}
