import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.css']
})
export class ErrorPageComponent {
  imgLogoSiagf_2: any;

  constructor() {
    this.imgLogoSiagf_2=environment.imgLogoSiagf_2
   }

   

}
