<form (keyup.enter)="submitFilterEnter($event)">
  <div class="filter-bubble">
    <div class="row">
      <div class="col">
        <div class="filter-bubble-icon" (keydown.enter)="toggleFilterCorp()" (click)="toggleFilterCorp()">
          <i class="fa-light fa-filter fa-lg mt-2" *ngIf="collapseFilterBox"></i>
          <i class="fa-light fa-xmark fa-lg mt-2" *ngIf="!collapseFilterBox"></i>
      </div>
    </div>
  </div>
  </div>
  <div class="filter-corp" [ngClass]="{'collapse_filter_corp':collapseFilterBox}">
    <div class="row m-0">

      <button type="button" class="filtrerBtnHeader mb-2 w-100">
        <!-- <img (click)="toggleFilterCorp()" class="fa fa-lg float-left ml-2"
          src="assets/icons/filter-circle-xmark-solid.svg" />&nbsp; -->
        {{'languages.buttons.filterSearch' | translate}} 
      </button>
    </div>
   
    <div class="ml-1">

      <div class="row">
        <div class="col-6 align-self-center">
          <div class="row">
            <button type="button" class="btn-load" type="button" (click)="resetForm()">
               <i class="fa-solid fa-rotate-right fa-lg "></i>

            </button>
             <app-list-filter-saved [entity]="'M'" (filterOpportunite)="getFilterFromCollection($event)" (reloadParentEvent)="closefilter($event)"></app-list-filter-saved>
            
          </div>
        </div>
        <div class="col-6">
          <div class="row align-self-center float-right">


            <app-save-filtre [filterOpportunite]="filtre_maileva" [entity]="'M'"></app-save-filtre>
            <button type="submit" class="ml-2 btn-dt-save-Filtre" (click)="submitFilter()">
              <i class="fa-regular fa-magnifying-glass fa-lg"></i>
              {{'languages.buttons.startseach' | translate}}
            </button>
          </div>
        </div>
      </div>
      <hr>

      <div class="scrolling"  id="style-1">
        <div class="row mb-2" >
          <div class="col-12">
            <mat-accordion>
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="row w-100 d-flex justify-content-center" >
                      <div class="row filtre-spn" >
<i class="fa-solid fa-user-tag fa-lg mr-2 mt-3"></i>
                          <span> {{'languages.communication.destinataire' | translate}}</span>
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row mt-3" >
                  <app-filters-distinataire class="w-100"
                    (onPushfilterDestinataire)="EmitFilterDestinataire($event)"
                    (returnToparent)="getResetDestinataire($event)" [Reset]="onResetDestinataire">
                  </app-filters-distinataire>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>



        <div class="row mb-2" >
          <div class="col-12">
            <mat-accordion>
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="row w-100 d-flex justify-content-center" >
                      <div class="row filtre-spn" >
<i class="fa-solid fa-user-pen fa-lg mr-2 custem-margin"></i>

                            <span> {{'languages.communication.exp' | translate}}</span>

                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row mt-3" >
                  <app-filters-expiditeur class="w-100" (returnToparent)="getResetExpediteur($event)"
                    [Reset]="onResetExpediteur" (onPushfilterExpiditeur)="EmitFilterExpediteur($event)">
                  </app-filters-expiditeur>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>


        <div class="row mb-2" >
          <div class="col-12">
            <mat-accordion>
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                     <div class="row w-100 d-flex justify-content-center" >
                      <div class="row filtre-spn" >
<i class="fa-solid fa-user-gear fa-lg mr-2 custem-margin"></i>

                            <span> {{'languages.communication.creator' | translate}} </span>

                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row mt-3" >
                  <app-filters-createurs class="w-100" (onPushfilterCreateur)="EmitFilterCreateur($event)"
                    (returnToparent)="getResetCreateur($event)" [Reset]="onResetCreateur">
                  </app-filters-createurs>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>



        <div class="row mb-2" >
          <div class="col-12">
            <mat-accordion>
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="row w-100 d-flex justify-content-center" >
                      <div class="row filtre-spn" >
<i class="fa-solid fa-paper-plane fa-lg mr-2 custem-margin"></i>

                            <span>{{'languages.navbar.Courrier' | translate}}</span>

                        </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row mt-3">
                  <app-filters-courrier class="w-100" (onPushfilterCourrier)="EmitFilterCourrier($event)"
                    (returnToparent)="getResetCourrier($event)" [Reset]="onResetCourrier">
                  </app-filters-courrier>
                </div>
              </mat-expansion-panel>

            </mat-accordion>
          </div>
        </div>

        <div class="row mb-2" >
          <div class="col-12">
            <mat-accordion>
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="row w-100 d-flex justify-content-center" >
                      <div class="row filtre-spn" >
<i class="fa-solid fa-lightbulb fa-lg mr-2 custem-margin"></i>

                            <span> {{'languages.opportunite.label_status' | translate}}</span>

                        </div>
                    </div>

                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row mt-3" >
                  <app-filters-status class="w-100" (onPushfilterStatut)="EmitFilterStatus($event)"
                    (returnToparent)="getResetStatut($event)" [Reset]="onResetStatut">
                  </app-filters-status>
                </div>
              </mat-expansion-panel>

            </mat-accordion>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
