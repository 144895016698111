import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Configuration } from '../configuration';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { BASE_PATH } from '../variables';
import { TarificateurVelo } from 'src/app/entity/tarificateurBerilish/tarificateurVelo';
@Injectable({
  providedIn: 'root',
})
export class TarificateurVeloService {
  protected basePath = environment.tarificateurBerilish;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }  }

  /**
   * get  tarification Bike   berlish
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRate(
    tarifie: TarificateurVelo,
    apikey: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    let headers = this.defaultHeaders;
    // headers = headers.set('apikey', apikey);

    // authentication (Acess_Token) required
    // if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
    // headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    // TODO Hardcoded
    headers = headers.set('apikey', apikey);
    // }

    /*   // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    } */
    return this.httpClient.request<any>('post', environment.tarificateurBerilish + `/getRate?simulate=true`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      body: tarifie,
      reportProgress: reportProgress,
    });
  }

    /**
   * get  tarification Bike   berlish
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
     public check_etching_number(
      etchingNumber: string,
      apikey: string,
      observe: any = 'body',
      reportProgress: boolean = false
    ): Observable<any> {
      let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
      if (etchingNumber !== undefined && etchingNumber !== null) {
        queryParameters = queryParameters.set('etchingNumber', <any>etchingNumber);
      }
      let headers = this.defaultHeaders;
     
      headers = headers.set('apikey', apikey);
    
      return this.httpClient.request<any>('get', environment.tarificateurBerilish + `/check_etching_number`, {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      });
    }
}
