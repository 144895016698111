<div class="card-custom-radius bg-white justify-content-around py-4 mt-1 w-100 h-100">
    <div class="row" [ngClass]="{'cover': !allowed_to_update}">
        <div class="col-8">
            <p class="card-doc-title row ml-3">
<i class="fa-solid fa-user-tie mr-2 fa-lg mt-2"></i>
         {{"languages.opportunite.details.coordonneAdherent" | translate}}
            </p>
        </div>
        <div class="col-4 text-right">
            <button type="button" class="btn-dt-link"  (click)="NaviagateToFicheProspect(idOpportunite,id_prospect)">
<i class="fa-solid fa-link"></i>
        {{"languages.opportunite.details.ficheAdherent" | translate}}
     </button>
        </div>
    </div>

    <div class="px-4">
        <ng-container *ngIf="(entreprise$ | async) ">
            <app-full-prospect-details  [person]="isperson" [typeEntity]="'o'" [allowed_to_update]="allowed_to_update" [entreprise]="entreprise$ | async" [typeProspect]="'Prospect'" (onUpdateEntreprise)="onUpdateEntreprise($event)">
            </app-full-prospect-details>
        </ng-container>
        <ng-container *ngIf="(entreprise$ | async)  == null">
            <div class="d-flex justify-content-center my-3">
                <mat-spinner [diameter]="20"></mat-spinner>
            </div>
        </ng-container>
    </div>
</div>
