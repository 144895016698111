import { HistoryServiceTicket } from 'src/app/pages/tickets-and-support/history.service';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import 'datatables.net';
import 'datatables.net-bs4';

import { HistoriquesTickets } from '../../../../entity/TicketsAndSupport/HistoriquesTickets';
import { ApiTicketsService } from '../../../../services/ApiTickets/api-tickets.service';
import { ActivatedRoute } from '@angular/router';
import { UniformiserDateToLocal } from 'src/app/shared/Utils/sharedFunctions';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-ticket-historique',
  templateUrl: './historique.component.html',
  styleUrls: ['./historique.component.css'],
})
export class HistoriqueComponent implements OnInit, OnDestroy {
  historiquesTickets: HistoriquesTickets[] = [];
  id: string = '';
  loader: Boolean = false;
  dataTable: any;
  fetchDataSignal: boolean = false;
  private fetchDataSubscription: Subscription;
  displayedColumns: string[] = ['date', 'user', 'commentaire'];
  UnsubscribeGetHistoriquesTickets: Subscription;
  dataSource: MatTableDataSource<HistoriquesTickets>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  alerts: any;
  constructor(
    private apiTicketService: ApiTicketsService,
    private chRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private HistoryServiceTicket: HistoryServiceTicket,
    private translateService: TranslateService
  ) {}

  ngOnDestroy(): void {
    this.UnsubscribeGetHistoriquesTickets?.unsubscribe();
  }

  ngOnInit(): void {
    this.translateService.get('languages').subscribe((object: any) => {
      this.alerts = object.alerts;
    });
    this.translateService.onLangChange.subscribe(() => {
      this.translateService.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
      });
    });

    this.route.paramMap.subscribe((params) => {
      this.id = params.get('tickets_id');
      this.getHistoriquesTickets();
    });
    this.fetchDataSubscription = this.HistoryServiceTicket.fetchData$.subscribe((signal) => {
      this.fetchDataSignal = signal;

      if (this.fetchDataSignal) {
        this.getHistoriquesTickets();
      }
    });
  }

  ngAfterViewInit(): void {
    this.paginator._intl.itemsPerPageLabel = 'Lignes par page';
    this.paginator._intl.changes.next(); // Notify Angular Material about the change
    this.paginator._intl.nextPageLabel = 'Page suivante';
    this.paginator._intl.previousPageLabel = 'Page précédente';

    // Override the default getRangeLabel function:
    this.paginator._intl.getRangeLabel = this.frenchRangeLabel;

    // Trigger an update so the labels are refreshed immediately
    this.paginator._intl.changes.next();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getHistoriquesTickets() {
    this.UnsubscribeGetHistoriquesTickets = this.apiTicketService.GetHistoriquesTickets(this.id).subscribe((data) => {
      this.historiquesTickets = data.result.reverse();
      this.historiquesTickets.forEach((element, index) => {
        this.historiquesTickets[index].date_action = UniformiserDateToLocal(element.date_action);
      });
      this.dataSource = new MatTableDataSource(data.result);
      this.dataSource.paginator = this.paginator;
      this.dataSource.paginator._intl.itemsPerPageLabel = this.alerts.elementsPerPage;
      this.dataSource.sort = this.sort;
      this.chRef.detectChanges();
    });
  }

  frenchRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0 || pageSize === 0) {
      return `0 sur ${length}`;
    }
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} – ${endIndex} sur ${length}`;
  }
}
