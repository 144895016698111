import { environment } from 'src/environments/environment';

import { DatePipe , Location } from '@angular/common';
import { Component, OnInit, ViewChild ,Input, ChangeDetectorRef } from '@angular/core';
import { ListVille } from '../../../entity/ListVille';
import { UserProfile } from '../../../entity/UserProfile';
import { ApiProspectsService } from '../../../services/ApiProspects/api-prospects.service';
import { ApiUsersService } from '../../../services/ApiUsers/api-users.service';
import { SharedMenuObserverService } from '../../../services/SharedMenuObserver/shared-menu-observer.service';
import { Router } from '@angular/router';
import { User } from '../../../entity/User/GroupUser';
 
import swal from 'sweetalert2';
import { UserAdmin2 } from 'src/app/entity/User/UserAdmin2';
import { AdressEmitter } from 'src/app/components/auto-complete-adresses/auto-complete-adresses.component';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { WebmailService } from 'src/app/services/ApiWebmail/webmail.service';
import { ToastrService } from 'ngx-toastr';

import { ImageCroppedEvent } from 'ngx-image-cropper';
import { FormControl, NgModel, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { ApiAdminBpmService } from 'src/app/services/ApiAdminBpm/api-admin-bpm.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
  @ViewChild('closeModal') closeModal; // permet de fermer le modal
  userInformation: User = new User();
  User: UserProfile = new UserProfile();
  utilisateur: UserAdmin2 = new UserAdmin2();
  initial_utilisateur: UserAdmin2 = new UserAdmin2();
  Villes: ListVille[] = [];
  step: number = 0;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  url;
  testimage: boolean = false;
  nationaliteFormControl = new FormControl();
  nationalities: Observable<any[]>;
  governorateFormControl = new FormControl();
  delegationFormControl = new FormControl();

  show_loading_add: boolean = false;
  picture: any = null;
  geomtry: boolean = false;
  password: boolean = false; 
  pictureuser: any = null;
  @Input() allowed_to_update: boolean;
  todo: any[] = [];
  inbox: any[] = [];
  sent: any[] = [];
  draft: any[] = [];
  spam: any[] = [];
  trash: any[] = [];
  configue: boolean = false;
  picIsUploaded: boolean = false;
  croppeimage: boolean = true;
  tab = new FormControl(0);
  /* ---- Passowrd initialisation */
  ancienPassword: string = '';
  newpassword: string = '';
  confirmPassword: string = '';
  validatePassword: boolean = false;
  telCountry = environment.country;
  alerts: any;
  buttons: any;
  majusPassword: boolean =false;
  numberPassword: boolean =false;
  touchedPassword: boolean =false;
  filteredGovernorates: Observable<any[]>;  
  filteredDelegations: Observable<any[]>;
  cin = new FormControl('', [
    Validators.required,
    Validators.pattern(/^\d{8}$/)  
  ]);
CINouPassport: string="cin"
  passport: FormControl = new FormControl('', [Validators.required]);
  @ViewChild('prenomCtrl') prenomCtrl: NgModel;
  @ViewChild('mailCtrl') mailCtrl: NgModel;
  @ViewChild('nomCtrl') nomCtrl: NgModel;

  
  constructor(
    private apiUserServices: ApiUsersService,
    private route: Router,
    private readonly sharedMenuObserverService: SharedMenuObserverService,
    public datepipe: DatePipe,
    private readonly apiProspectsService: ApiProspectsService,
    private location: Location,
    private apiWebmail: WebmailService,
    private toastr: ToastrService,
    private readonly translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private AdminBpmService : ApiAdminBpmService


  ) {}

  ngOnInit(): void {

   


    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
        this.sharedMenuObserverService.updateMenu(object.navbar.profil);
      });

      });
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
        this.sharedMenuObserverService.updateMenu(object.navbar.profil);
      });
    this.sharedMenuObserverService.updateIconMenu('faUserCircle');
    this.getInformationUser();
    this.utilisateur.user.password = '';
    if (this.apiWebmail.tab === 3) this.tab.setValue(3);
    if (this.allowed_to_update === undefined) {
      this.allowed_to_update = true;
    }

    this.filteredGovernorates = this.governorateFormControl.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(value => this.fetchGovernorates(value || ''))
    );



    this.nationalities = this.nationaliteFormControl.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(value => this.fetchNationalities(value || ''))
    );
    this.filteredDelegations = this.delegationFormControl.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(value => this.fetchDelegations(value || ''))
    );
  }

  fetchNationalities(query: any): Observable<any[]> {
    const queryString = typeof query === 'string' ? query.toLowerCase() : '';
    return this.AdminBpmService.getAllNationalite().pipe(
      map(response => response.data),   
      map(nationalities => 
        nationalities.filter(option => 
          (option.name || '').toLowerCase().includes(queryString)
        )
      )
    );
  }
  

  displayDelegationFn(delegation: any): string {
    return delegation ? delegation.name : '';
  }  


  selectedDelegation(event: MatAutocompleteSelectedEvent): void {
    const selectedDelegation = event.option.value; 
    this.delegationFormControl.setValue(selectedDelegation);  
  }


  displayGovernorateFn(gov: any): string {
    return gov ? gov.name : '';  
  }

  fetchDelegations(query: any): Observable<any[]> {
    const queryString = typeof query === 'string' ? query.toLowerCase() : '';
    return this.AdminBpmService.getAllListDelegation().pipe(
      map(response => response.data),
      map(delegations =>
        delegations.filter(option =>
          (option.name || '').toLowerCase().includes(queryString)
        )
      )
    );
  }
  


  selectedGovernorate(event: MatAutocompleteSelectedEvent): void {
    const selectedGov = event.option.value; 
    this.governorateFormControl.setValue(selectedGov);  
  }

  fetchGovernorates(query: any): Observable<any[]> {
    const queryString = typeof query === 'string' ? query.toLowerCase() : '';
    return this.AdminBpmService.getAllListGouvernorats().pipe(
      map(response => response.data),
      map(gouvernorats =>
        gouvernorats.filter(option =>
          (option.name || '').toLowerCase().includes(queryString)
        )
      )
    );
  }
  

  checkConfirmPassword(event) {
    this.touchedPassword = true
    if (this.utilisateur.user.password !== this.confirmPassword && this.confirmPassword !== '') {
      this.validatePassword = true;
    } else {
      this.validatePassword = false;
    }
 

  }

  getInformationUser() {
    this.showloader();
    this.apiUserServices
      .getUserWithIdGet()
      .pipe(
        tap((data: UserAdmin2) => {
          this.utilisateur = data;
          this.croppedImage = data.user.photo;
       
          this.delegationFormControl.setValue({
            name: this.utilisateur.user.delegation,   
            value: this.utilisateur.user.delegation  
          });
          this.governorateFormControl.setValue({
            name: this.utilisateur.user.goverment,  
            value: this.utilisateur.user.goverment
          });
  
           this.nationaliteFormControl.setValue({
            name: this.utilisateur.user.nationality,
            value: this.utilisateur.user.nationality
          });
          
          if (this.utilisateur.user.date_naiss_user != null) {
            this.utilisateur.user.date_naiss_user = this.datepipe.transform(
              this.utilisateur.user.date_naiss_user,
              'yyyy-MM-dd'
            );
          }
          if (data.user.cin){
            this.CINouPassport="cin"
           }else if (data.user.passport){
            this.CINouPassport="passport"
           }
          Object.assign(this.initial_utilisateur, data);
          this.hideloader();
        })
      )
      .subscribe();
    //this.getFolders();
  }

  getListeVille(CodePostal) {
    this.apiProspectsService.getListVille(CodePostal).subscribe((Response: ListVille[]) => {
      this.Villes = Response;
    });
  }

  retourToList() {
    this.location.back();
  }

/*   getFolders() {
    this.apiWebmail.getListFolfder().subscribe(
      (data) => {
        this.todo = data.list_name_folders;
        this.configue = data.is_configured;
        if (this.configue) {
          if (data.configured_folders.inbox) this.inbox = data.configured_folders.inbox;
          if (data.configured_folders.sent) this.sent = data.configured_folders.sent;
          if (data.configured_folders.drafts) this.draft = data.configured_folders.drafts;
          if (data.configured_folders.spam) this.spam = data.configured_folders.spam;
          if (data.configured_folders.trash) this.trash = data.configured_folders.trash;
          this.configue = data.is_configured;
        }
      },
      (err) => {
        console.error(err);
      }
    );
  } */

  clearForm() {
    Object.assign(this.utilisateur, this.initial_utilisateur);
  }

  cancel() {
    this.croppeimage = false;
    this.croppedImage = null;
    this.croppedImage = this.utilisateur.user.photo;
    this.testimage = false;
    this.imageChangedEvent = '';
  }

  alertSuccess(data) {
    swal
      .fire({
        title: this.alerts.Successoperation,
        text: data,
        icon: 'success',
        showConfirmButton: true,
        confirmButtonColor: '#68a45b',
        confirmButtonText: this.buttons.fermer,
      })
      .then((result) => {
        if (result.isConfirmed) {
          location.reload();
        }
      });
  }

  alertWarning(data) {
    swal.fire({
      title: this.alerts.badHappened,
      icon: 'warning',
      text: data,
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: this.buttons.fermer,
      confirmButtonColor: '#f9a65d',
    });
  }

  alertError(data, title) {
    swal.fire({
      title:  data,
      text: title,
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: this.buttons.fermer,
      confirmButtonColor: '#d53a3a',
    });
  }

  onChangeAdresseSaisieAuto(adress: AdressEmitter) {
    this.utilisateur.ville.nom_comm = adress.locality;
    this.utilisateur.adresse.CP = adress.postal_code;
    this.utilisateur.adresse.streetName = adress.route;
    this.utilisateur.adresse.streetNumber = adress.street_number;
    this.utilisateur.adresse.latitude = adress.latitude;
    this.utilisateur.adresse.longitude = adress.longitude;
    this.utilisateur.adresse.geom = adress.geometry;
    this.utilisateur.ville.id_pays = adress.pays;
    this.checkGeomtry();
  }

  checkPassoword(value) {
    this.password = true;
  }

  checkGeomtry() {
    this.geomtry = true;
  }



  alertWarningInvalid() {
    swal.fire({
      title: this.alerts.error,
      icon: 'warning',
      text: this.alerts.Veuillezremplir,
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: this.buttons.fermer,
      confirmButtonColor: '#e75e5e',
    });
  }

  alertWarningCinInvalide() {
    swal.fire({
      title: this.alerts.error,
      icon: 'warning',
      text: this.alerts.cinInvalid,
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: this.buttons.fermer,
      confirmButtonColor: '#e75e5e',
    });
  }



  updateInformationsUser() {
    


  
     if (!this.cin.value && this.CINouPassport==="cin") {
      this.cin.markAsTouched();
      this.alertWarningInvalid()
    }
    else if (this.cin.invalid  && this.CINouPassport==="cin") {
      this.cin.markAsTouched();
      this.alertWarningCinInvalide()
    }
    else if (!this.passport.value  && this.CINouPassport==="passport"){
      this.passport.markAsTouched();
      this.alertWarningInvalid()
    }
 
    else if (this.prenomCtrl.invalid)  {
      this.alertWarningInvalid()
    }  
    else if (this.mailCtrl.invalid)  {
      this.alertWarningInvalid()
    }  
    else if (this.nomCtrl.invalid)  {
      this.alertWarningInvalid()
    }  
    else{
      if (this. CINouPassport==='cin'){
        this.utilisateur.user.passport = "";
      }else if (this.CINouPassport==='passport'){
        this.utilisateur.user.cin = "";
      }
    this.show_loading_add = true;
     this.utilisateur.user.delegation = this.delegationFormControl.value
    ? this.delegationFormControl.value.value
    : null; 

    this.utilisateur.user.goverment = this.governorateFormControl.value?.value || null;
    this.utilisateur.user.nationality = this.nationaliteFormControl.value?.value || null;
  
    if (!this.geomtry) {
      this.utilisateur.adresse.geom = '';
    }

    if (this.picture === null) {
      this.updateProfile();
    } else this.UploadPicture();
  }}

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }
  // getPicture(event) {
  //   this.picture = event.target.files[0]
  // }

  UploadPicture() {
    const formData = new FormData();
    formData.append('image', this.picture);
    this.apiUserServices.uploadPicture(this.utilisateur.user.id, formData).subscribe(
      (Response) => {
        this.utilisateur.user.photo = Response.path;
        this.picIsUploaded = true;
        this.updateProfile();
        this.testimage = true;
        this.getInformationUser();
      },
      () => {
        this.picIsUploaded = false;
        this.updateProfile();
        this.getInformationUser();
      }
    );
  }

  checkPassword() {
    this.touchedPassword = true
    if (this.utilisateur.user.password.length >= 12 && this.utilisateur.user.password.length <=30 ){
      if (!(/[A-Z]/.test(this.utilisateur.user.password))) {
        this.majusPassword = true;
        this.cdr.detectChanges();
      }
      else if (!(/\d/.test(this.utilisateur.user.password))) {
        this.numberPassword = true;
        this.cdr.detectChanges();
      }else {
        this.majusPassword = false;
        this.numberPassword = false;
        this.cdr.detectChanges();
      }
    }



  }


  selectedNationality(event: MatAutocompleteSelectedEvent): void {
    const selectedNat = event.option.value; 
    this.nationaliteFormControl.setValue(selectedNat);  
  }


  displayNationaliteFn(nat: any): string {
    return nat ? nat.name : '';  
  }

 
  updateProfile(){
    if (this.touchedPassword){
      if (this.majusPassword || this.numberPassword || !(this.utilisateur.user.password.length >= 12 && this.utilisateur.user.password.length <=30)){
        this.alertWarning(this.alerts.minSixCaractere);
        this.show_loading_add = false;
      }
      else  if (!this.confirmPassword){
        this.alertWarning(this.alerts.confirmPass);
        this.show_loading_add = false;
      }
    
      else if (this.validatePassword) {
        this.alertWarning(this.alerts.verifyConfirmPassword);
        this.show_loading_add = false;
      } else {
        this.updateUser()
    }}else {
       this.updateUser()
    }
  }

  updateUser() {
    
    
      this.utilisateur.user.user_mail_data = this.utilisateur.user.user_mail_data?.trim();
      this.utilisateur.user.server_in_mail_data = this.utilisateur.user.server_in_mail_data?.trim();
      this.utilisateur.user.server_out_mail_data = this.utilisateur.user.server_out_mail_data?.trim();
      this.utilisateur.user.port_in_mail_data = this.utilisateur.user.port_in_mail_data?.trim();
      this.utilisateur.user.port_out_mail_data = this.utilisateur.user.port_out_mail_data?.trim();
      this.apiUserServices.UpdateUser(this.utilisateur.user.id, this.utilisateur).subscribe(
        (Response) => {
          if (
            (this.picIsUploaded && Response.message === 'Aucune modification faite  ') ||
            Response.message === 'Utilisateur modifé avec succées'
          )
            this.alertSuccess(this.alerts.modificationSucess);
          else {
            this.alertSuccess(Response.message);
          }
          this.show_loading_add = false;
        },
        (err) => {
          this.show_loading_add = false;
          if (err.status===403){
            this.alertWarning(this.alerts.invalidOldPassword);
          }
          else{
            this.alertError(this.alerts.error, err.error.message);
          }
        }
      
      );
  }

  movementCount: number = 0;
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
  }

  correspandance() {
    this.show_loading_add = true;
    const Dossiers = { Inbox: this.inbox, Sent: this.sent, Drafts: this.draft, Spam: this.spam, Trash: this.trash };
    const emptyDossiers = Object.keys(Dossiers).reduce((result, key) => {
      if (Dossiers[key].length === 0) {
        result.push(key);
      }
      return result;
    }, []);
    if (emptyDossiers.length !== 0) {
      this.alertWarning(this.alerts.chooseOneCorrespondanceForFile);
      this.show_loading_add = false;
    } else {
      this.apiWebmail.correspandanceDossiers(Dossiers).subscribe(
        (data) => {
          this.toastr.success(this.alerts.webmailConfigured);
          this.show_loading_add = false;
          location.reload();
          this.closeModal?.nativeElement?.click();
        },
        () => {
          this.show_loading_add = false;
          this.closeModal?.nativeElement?.click();
        }
      );
    }
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    // Preview
    this.croppedImage = event.base64;
    this.picture = this.base64ToFile(event.base64, this.utilisateur.user.prenom + '_' + new Date().getTime());
    this.utilisateur.user.photo = this.picture;
    this.testimage = true;
  }

  base64ToFile(data, filename) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  updateInformationsUserTest() {
    this.todo = [];
    if (this.picture === null) {
      this.utilisateur.user.photo = '';
    } else this.UploadPicture();
    if (!this.geomtry) {
      this.utilisateur.adresse.geom = '';
    }

    this.utilisateur.user.user_mail_data = this.utilisateur.user.user_mail_data.trim();
    this.utilisateur.user.server_in_mail_data = this.utilisateur.user.server_in_mail_data.trim();
    this.utilisateur.user.server_out_mail_data = this.utilisateur.user.server_out_mail_data.trim();
    this.utilisateur.user.port_in_mail_data = this.utilisateur.user.port_in_mail_data.trim();
    this.utilisateur.user.port_out_mail_data = this.utilisateur.user.port_out_mail_data.trim();
    this.apiUserServices
      .UpdateUser(this.utilisateur.user.id, this.utilisateur)
      .pipe(
        tap(() => {
         // this.getFolders();
          // ('#exampleModalCenter' as any)?.modal('show');
          this.show_loading_add = false;
        })
      )
      .subscribe(
        () => {
          this.show_loading_add = false;
          // this.alertError('Erreur', error.message);
        },
        (error) => {
          this.alertError(this.alerts.error, error.message);
          this.show_loading_add = false;
        }
      );
  }
}
