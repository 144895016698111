<!-- <div class="row p-3">
  <div class="col">
    <div class="card card-affaire bg-white text-center border-0 ">
     <p style="font-size: larger;">Informations complémentaires </p>
    </div>
  </div>

</div> -->

<div class="row">
  <div class="col-3 p-1 mt-2  ">
    <div class="sidebar-affaire ">
      <div class="card card-affaire bg-white text-center border-0 p-2">
        <div class="mt-3">
          <div class="col py-1 focus-style ">
            <button (click)="scroll('infoSpecif')" class="focus-style d-block border-0 full-width text-left">
              <p class="align-details-icon">
                <i class="fa-solid fa-user-tie  opportunities-details-navbar-logo fa-lg"></i>
              </p>
              <span class="sidebar-text">{{'languages.settings.InfoComPhysique' | translate}} </span>
            </button>
          </div>

        </div>
        <div class="mt-3">
          <div class="col py-1">
            <button (click)="scroll('infoSpecifEn')" class="focus-style d-block border-0 full-width text-left">
              <p class="align-details-icon">
                <i class="fa-solid fa-warehouse opportunities-details-navbar-logo fa-lg"></i>
              </p>
              <span class="sidebar-text">{{"languages.settings.infocCompMorale" | translate}} </span>
            </button>
          </div>

        </div>
        <div class="mt-3">
          <div class="col py-1">
            <button (click)="scroll('organisme')" class="focus-style d-block border-0 full-width text-left">
              <p class="align-details-icon">
                <i class="fa-solid fa-landmark opportunities-details-navbar-logo fa-lg"></i>
              </p>
              <span class="sidebar-text"> {{"languages.settings.infoCompOrganism" | translate}}</span>
            </button>
          </div>

        </div>
        <div class="mt-3">
          <div class="col py-1">
            <button (click)="scroll('compagnie')" class="focus-style d-block border-0 full-width text-left">
              <p class="align-details-icon">
                <i class="fa-solid fa-landmark opportunities-details-navbar-logo fa-lg"></i>
              </p>
              <span class="sidebar-text">{{'languages.settings.infoCompCompagnie' | translate}} </span>
            </button>
          </div>

        </div>
        <div class="mt-3">
          <div class="col py-1 focus-style ">
            <button (click)="scroll('gamme')" class="focus-style d-block border-0 full-width text-left">
              <p class="align-details-icon">
                
                <i class="fa-solid fa-landmark opportunities-details-navbar-logo fa-lg"></i>
              </p>
              <span class="sidebar-text">{{'languages.settings.infoGamme' | translate}} </span>
            </button>
          </div>

        </div>
        <div class="mt-3">
          <div class="col py-1 focus-style ">
            <button (click)="scroll('instances')" class="focus-style d-block border-0 full-width text-left">
              <p class="align-details-icon">
                <i class="fa-solid fa-layer-group opportunities-details-navbar-logo fa-lg"></i>
              </p>
              <span class="sidebar-text"> {{"languages.settings.Instance" | translate}}</span>
            </button>
          </div>

        </div>
        <div class="mt-3">
          <div class="col py-1 focus-style ">
            <button (click)="scroll('quality')" class="focus-style d-block border-0 full-width text-left">
              <p class="align-details-icon">
                <i class="fa-solid fa-medal opportunities-details-navbar-logo fa-lg"></i>
              </p>
              <span class="sidebar-text">{{'languages.settings.infoBlocQualite' | translate}}</span>
            </button>
          </div>

        </div>
    
      </div>

    </div>
  </div>


  <div class="col p-1 overflow-auto ">
    <div class="row">
      <div class="col p-0">
        <div class="p-2 mb-2" id="infoSpecif" *ngIf="infoSpecif">
          <div class="card card-affaire bg-white  border-0 p-3 ml-2">
            <div class="card-hearder  row  d-flex justify-content-center align-items-center mt-2 mb-4 card-doc-title" >
              <div class="col-md-6">
<i class="fa-solid fa-user-tie  opportunities-details-navbar-logo fa-lg mr-2"></i>
                {{'languages.settings.InfoComPhysique' | translate}}</div>
             
              <div class="col-md-6 d-flex justify-content-end">
                <ng-container *ngIf="showLoading"  >
                  <div class="col-3 d-flex justify-content-center align-items-center">
                    <mat-spinner [diameter]="35"></mat-spinner>
                  </div>
              </ng-container> 
                <button type="button" class="btn-dt-save"  (click)="addConfig('physique')" [disabled]="formErrorphysique" [style.opacity]="formErrorphysique ? '0.5' : '1'">
                  <i class="fa-solid fa-circle-check fa-lg mr-1"></i>
  
                  {{"languages.buttons.enregistrer" | translate}}
                </button>
              </div>
              
            </div>
            <shared-globalProduit-form *ngIf="formulaireTrigger" [formBuilderData]="formBuilderDataPhysique" (emitFormBuilder)="getDataPhysiqueForm($event)" (itemerror)="onGetItemErrorPHysique($event)"></shared-globalProduit-form>
          </div>
        </div>
        <div class="p-2 mb-2" id="infoSpecifEn" *ngIf="infoSpecifEn">
          <div class="card card-affaire bg-white  border-0 p-3 ml-2">
            <div class="card-hearder row  d-flex justify-content-center align-items-center card-doc-title mt-2 mb-4" >
              <div class="col-md-6"> 
<i class="fa-solid fa-warehouse opportunities-details-navbar-logo fa-lg mr-2" ></i>
                {{"languages.settings.infocCompMorale" | translate}}
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                <ng-container  *ngIf="showLoading" >
                  <div class="col-3 d-flex justify-content-center align-items-center">
                    <mat-spinner [diameter]="35"></mat-spinner>
                  </div>
              </ng-container> 
              <button type="button" class="btn-dt-save" style="float: right;" (click)="addConfig('morale')"   [disabled]="formErrormorale" [style.opacity]="formErrormorale ? '0.5' : '1'">
<i class="fa-solid fa-circle-check fa-lg mr-1"></i>
                {{"languages.buttons.enregistrer" | translate}}
              </button>
              </div>
            </div>
            <shared-globalProduit-form *ngIf="formulaireTrigger" [formBuilderData]="formBuilderDataMorale" (emitFormBuilder)="getDataMoraleForm($event)"  (itemerror)="onGetItemErrorMorale($event)"></shared-globalProduit-form>
          </div>
        </div>
        <div class="p-2 mb-2" id="organisme" *ngIf="organisme">
          <div class="card card-affaire bg-white  border-0 p-3 ml-2">
            <div class="card-hearder row  d-flex justify-content-center align-items-center card-doc-title mt-2 mb-4" >
              <div class="col-md-6"> 
<i class="fa-solid fa-landmark opportunities-details-navbar-logo fa-lg mr-2"></i>
                 {{"languages.settings.infoCompOrganism" | translate}}
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                <ng-container *ngIf="showLoading"  >
                  <div class="col-3 d-flex justify-content-center align-items-center">
                    <mat-spinner [diameter]="35"></mat-spinner>
                  </div>
              </ng-container> 
              <button type="button" class="btn-dt-save" style="float: right;" (click)="addConfig('organisme')" [disabled]="formErrororganisme" [style.opacity]="formErrororganisme ? '0.5' : '1'">
<i class="fa-solid fa-circle-check fa-lg mr-1"></i>
                {{"languages.buttons.enregistrer" | translate}}
              </button>
              </div>
            </div>
            <shared-globalProduit-form *ngIf="formulaireTrigger" [formBuilderData]="formBuilderDataOrganisme" (emitFormBuilder)="getDataOrganismeForm($event)" (itemerror)="onGetItemErrorOrganisme($event)"></shared-globalProduit-form>
          </div>
        </div>
        <div class="p-2 mb-2" id="compagnie" *ngIf="compagnie">
          <div class="card card-affaire bg-white  border-0 p-3 ml-2">
            <div class="card-hearder row  d-flex justify-content-center align-items-center card-doc-title mt-2 mb-4" >
              <div class="col-md-6"> 
<i class="fa-solid fa-landmark opportunities-details-navbar-logo fa-lg mr-2"></i>
                 {{"languages.settings.infoCompCompagnie" | translate}}
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                <ng-container *ngIf="showLoading"  >
                  <div class="col-3 d-flex justify-content-center align-items-center">
                    <mat-spinner [diameter]="35"></mat-spinner>
                  </div>
              </ng-container> 
              <button type="button" class="btn-dt-save" style="float: right;" (click)="addConfig('compagnie')" [disabled]="formErrorCompagnie" [style.opacity]="formErrorCompagnie ? '0.5' : '1'">
<i class="fa-solid fa-circle-check fa-lg mr-1"></i>
                {{"languages.buttons.enregistrer" | translate}}
              </button>
              </div>
            </div>
            <shared-globalProduit-form *ngIf="formulaireTrigger" [formBuilderData]="formBuilderDataCompagnie" (emitFormBuilder)="getDataCompagnieForm($event)" (itemerror)="onGetItemErrorCompagnie($event)"></shared-globalProduit-form>
          </div>
        </div>
        <div class="p-2 mb-2" id="organisme" *ngIf="instance">
          <div class="card card-affaire bg-white  border-0 p-3 ml-2">
            <div class="card-hearder row  d-flex justify-content-center align-items-center card-doc-title mt-2 mb-4" >
              <div class="col-md-6"> 
<i class="fa-solid fa-layer-group opportunities-details-navbar-logo fa-lg mr-2"></i>
                 {{"languages.settings.Instance" | translate}}
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                <ng-container *ngIf="showLoading"  >
                  <div class="col-3 d-flex justify-content-center align-items-center">
                    <mat-spinner [diameter]="35"></mat-spinner>
                  </div>
              </ng-container> 
              <button type="submit" class="btn-dt-save" style="float: right;" (click)="addConfig('instance')">
<i class="fa-solid fa-circle-check fa-lg mr-1"></i>
                {{"languages.buttons.enregistrer" | translate}}
              </button>
              </div>
            </div>
            <div class="row justify-content-center ">
              <div class="col-12 ">
                <div class="row">
                  <div class="col-3 mt-4">
                    {{"languages.settings.reposrtServer" | translate}} :
                  </div>
                  <div class="col-6">
                    <mat-form-field class="example-full-width w-100">
                      <input matInput type="url" [(ngModel)]="bodyInstance.report_server" #report_server="ngModel"
                        pattern="https?://.+">
                      <mat-error *ngIf="report_server.invalid && (report_server.dirty || report_server.touched)"
                        class="message-erreur">
                        <mat-error *ngIf="report_server.errors.pattern">
<i class="fa-solid fa-triangle-exclamation"></i>
                           {{"languages.role.formLinkInvalid" | translate}}
                        </mat-error>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-3 mt-4">
                    {{"languages.settings.lienSouscription" | translate}} :
                  </div>
                  <div class="col-6">
                    <mat-form-field class="example-full-width w-100">
                      <input type="url" matInput [(ngModel)]="bodyInstance.subscription_url" #souscription_url="ngModel"
                        pattern="https?://.+">
                      <mat-error
                        *ngIf="souscription_url.invalid && (souscription_url.dirty || souscription_url.touched)"
                        class="message-erreur">
                        <mat-error *ngIf="souscription_url.errors.pattern">
<i class="fa-solid fa-triangle-exclamation"></i>
                          {{"languages.role.formLinkInvalid" | translate}}
                        </mat-error>
                      </mat-error>
                    </mat-form-field>

                  </div>
                </div>
                <div class="row">
                  <div class="col-3 mt-4">
                    {{"languages.settings.lienBackoffice" | translate}} :
                  </div>
                  <div class="col-6">
                    <mat-form-field class="example-full-width w-100">
                      <input type="url" matInput [(ngModel)]="bodyInstance.api_url" #api_url="ngModel"
                        pattern="https?://.+">
                      <mat-error
                        *ngIf="api_url.invalid && (api_url.dirty || api_url.touched)"
                        class="message-erreur">
                        <mat-error *ngIf="api_url.errors.pattern">
<i class="fa-solid fa-triangle-exclamation"></i>
                          {{"languages.role.formLinkInvalid" | translate}}
                        </mat-error>
                      </mat-error>
                    </mat-form-field>

                  </div>
                </div>
                <div class="row">
                  <div class="col-3 mt-4">
                    {{"languages.settings.deviseActuelle" | translate}} :
                  </div>
                  <div class="col-6">
                    <mat-form-field class="example-full-width w-100">
                      <input matInput [(ngModel)]="bodyInstance.currentCurrency">
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-3 mt-4">
                 {{"languages.settings.formatDevise" | translate}} :
                  </div>
                  <div class="col-6">
                    <mat-form-field class="example-full-width w-100">
                      <input matInput [(ngModel)]="bodyInstance.formatCurrency">
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-3 mt-4">
                     {{"languages.prospect.pays" | translate}} :
                  </div>
                  <div class="col-6">
                    <mat-form-field class="example-full-width w-100">
                      <input matInput [(ngModel)]="bodyInstance.country">
                    </mat-form-field>
                  </div>
                </div>


              </div>
            </div>

          </div>
        </div>
        <div class="p-2 mb-2" id="quality" *ngIf="quality">
          <div class="card card-affaire bg-white  border-0 p-3 ml-2">
            <div class="card-hearder row  d-flex justify-content-center align-items-center card-doc-title mt-2 mb-4" >
              <div class="col-md-6"> 
<i class="fa-solid fa-medal opportunities-details-navbar-logo fa-lg"></i>
                 {{'languages.settings.infoBlocQualite' | translate}}
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                <ng-container *ngIf="showLoading"  >
                  <div class="col-3 d-flex justify-content-center align-items-center">
                    <mat-spinner [diameter]="35"></mat-spinner>
                  </div>
              </ng-container> 
              <button type="button" class="btn-dt-save" style="float: right;" (click)="addConfig('qualite')" [disabled]="formErrorQuality" [style.opacity]="formErrorQuality ? '0.5' : '1'">
<i class="fa-solid fa-circle-check fa-lg mr-1"></i>
                {{"languages.buttons.enregistrer" | translate}}
              </button>
              </div>
            </div>
            <shared-globalProduit-form *ngIf="formulaireTrigger" [formBuilderData]="formBuilderDataQuality" (emitFormBuilder)="getDataQualityForm($event)" (itemerror)="onGetItemErrorQuality($event)"></shared-globalProduit-form>
          </div>
        </div>

        <div class="p-2 mb-2" id="gamme" *ngIf="gamme">
          <div class="card card-affaire bg-white  border-0 p-3 ml-2">
            <div class="card-hearder row  d-flex justify-content-center align-items-center card-doc-title mt-2 mb-4" >
              <div class="col-md-6"> 
<i class="fa-solid fa-landmark opportunities-details-navbar-logo fa-lg mr-2"></i>
                 {{'languages.settings.infoGamme' | translate}} 
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                <ng-container *ngIf="showLoading"  >
                  <div class="col-3 d-flex justify-content-center align-items-center">
                    <mat-spinner [diameter]="35"></mat-spinner>
                  </div>
              </ng-container> 
              <button type="button" class="btn-dt-save" style="float: right;" (click)="addConfig('gamme')" [disabled]="formErrorGamme" [style.opacity]="formErrorGamme ? '0.5' : '1'">
<i class="fa-solid fa-circle-check fa-lg mr-1"></i>
                {{"languages.buttons.enregistrer" | translate}}
              </button>
              </div>
            </div>
            <shared-globalProduit-form *ngIf="formulaireTrigger" [formBuilderData]="formBuilderDataGamme" (emitFormBuilder)="getDataGammeForm($event)" (itemerror)="onGetItemErrorGamme($event)"></shared-globalProduit-form>
          </div>
        </div>
     </div>

    </div>
    </div>
  </div>

